import { IColumnFilter, IFilter, TableHeader } from '@/types';
import { COMPARE_OPERATORS, filterClass, InputFilter } from "@/services/filters/filterClass";
import { getCurrentDate, getPreviousDate } from "@/utils/formatDates";
import { getActiveUserSearch, getAffiliateSearch } from "@/api/user";
import { getAdvertisersSearch } from "@/api/revenue";
import { getEmployeesOffersV2 } from "@/api/offers";
import { filterByGeoOrOS } from "@/api/stats";
import { translateCampaignStatus } from "@/utils/translate";
import { CreatedFilters } from "@/services/filters/filters";
import { IUser } from "@/api/types/user";
import { REPORT_ALLOWED_DATE_GEO, REPORT_ALLOWED_DATE_SUBS } from "@/configs/global";
import { eventBus } from "@/eventbus";
import router from "@/router";
import store from "@/store";

export class ExtendedReportFilters extends CreatedFilters {
    constructor() {
        super();
    }

    getFilters(): IFilter[] {
        const isReportAdvertiser = router.currentRoute.path === '/statistics/extended-report/advertiser';
        const prepareGeoItems = (key: string) => {
            const data = router.currentRoute.query[key] as string | string[] | undefined;

            if (data !== undefined) {
                return Array.isArray(data) ? data.map(el => ({ id: el })) : [{ id: data }];
            }

            return [];
        };

        return [
            new filterClass.Datepicker({
                colName: 'Дата',
                id: 'date',
                label: 'Дата',
                allowedDates: this.getAllowedDate,
                pickerType: 'date',
                select: [getCurrentDate('yyyy-MM-dd')],
                defaultValue: [getCurrentDate('yyyy-MM-dd')],
                range: true,
                isOnlyPlaceholder: false,
            }),
            new filterClass.Select({
                colName: 'Дата',
                id: 'group',
                label: 'Группировка',
                itemValue: 'id',
                itemText: 'value',
                items: [
                    { id: 'without_lead_date', value: 'Без группировки' },
                    { id: 'dm_lead_date_hour', value: 'По часам' },
                    { id: 'dm_lead_date', value: 'По дням' },
                    { id: 'dm_lead_date_week', value: 'По неделям' },
                    { id: 'dm_lead_date_month', value: 'По месяцам' },
                    { id: 'dm_lead_date_year', value: 'По годам' },
                ],
                select: 'without_lead_date',
                isNoChips: true,
                defaultValue: 'without_lead_date',
                callback: this.changeGroup,
                disabled: false,
            }),
            new filterClass.Multiselect({
                col: 1,
                tab: REPORT_FILTERS_TABS.MAIN,
                label: 'Валюта',
                id: 'currency',
                itemValue: 'code',
                itemText: 'code',
                isCurrencyCatalogue: true,
                noDataText: 'Нет валют',
                type: 'string',
                isExcluded: false,
            }),
            new filterClass.Combobox({
                col: 1,
                tab: REPORT_FILTERS_TABS.MAIN,
                id: 'country',
                label: 'Страна оффера',
                itemValue: 'id',
                itemText: 'name',
                type: 'number',
                template: this.getTemplate,
                noDataText: 'Нет списка стран',
                isExcluded: false,
            }),
            new filterClass.Autocomplete({
                col: 1,
                tab: REPORT_FILTERS_TABS.MAIN,
                id: 'advertiser_manager_id',
                apiMethod: getActiveUserSearch,
                label: 'Менеджер рекламодателя',
                itemValue: 'id',
                itemText: 'name',
                multiple: true,
                isExcluded: false,
            }),
            new filterClass.Autocomplete({
                col: 1,
                tab: REPORT_FILTERS_TABS.MAIN,
                id: 'advertiser_id',
                label: 'Рекламодатель',
                apiMethod: getAdvertisersSearch,
                itemValue: 'id',
                itemText: 'name',
                multiple: true,
                isShowUnlimited: true,
                isExcluded: false,
            }),
            new filterClass.Autocomplete({
                col: 1,
                tab: REPORT_FILTERS_TABS.MAIN,
                id: 'offer_id',
                apiMethod: getEmployeesOffersV2,
                key: 'search',
                label: 'Оффер',
                itemValue: 'legacy_id',
                itemText: 'name',
                multiple: true,
                multiPaste: true,
                minSearchLength: 2,
                isShowUnlimited: true,
                isShowFullName: true,
                isMarker: (i) => i.status !== 'active',
                isExcluded: false,
            }),
            new filterClass.Multiselect({
                col: 2,
                tab: REPORT_FILTERS_TABS.MAIN,
                label: 'Направление',
                id: 'department',
                type: 'number',
                itemValue: 'id',
                itemText: 'name',
                template: this.getTemplate,
                noDataText: 'Нет направлений',
                isExcluded: false,
            }),
            new filterClass.Multiselect({
                col: 2,
                tab: REPORT_FILTERS_TABS.MAIN,
                label: 'Тип трафика',
                id: 'traffic',
                type: 'number',
                itemValue: 'id',
                itemText: 'name',
                template: this.getTemplate,
                noDataText: 'Нет типов трафика',
                isExcluded: false,
            }),
            new filterClass.Multiselect({
                col: 2,
                tab: REPORT_FILTERS_TABS.MAIN,
                label: 'Вертикаль оффера',
                id: 'vertical',
                type: 'number',
                itemValue: 'id',
                itemText: 'name',
                template: this.getTemplate,
                noDataText: 'Нет вертикалей',
                isExcluded: false,
            }),
            new filterClass.Multiselect({
                col: 2,
                tab: REPORT_FILTERS_TABS.MAIN,
                label: 'Тип продукта',
                id: 'product',
                type: 'number',
                itemValue: 'id',
                itemText: 'name',
                template: this.getTemplate,
                noDataText: 'Нет типов продукта',
                isExcluded: false,
            }),
            new filterClass.Multiselect({
                col: 2,
                tab: REPORT_FILTERS_TABS.MAIN,
                label: 'Целевое действие',
                id: 'target_action',
                type: 'number',
                itemValue: 'id',
                itemText: 'name',
                template: this.getTemplate,
                noDataText: 'Нет целевых действий',
                isExcluded: false,
            }),
            new filterClass.Multiselect({
                col: 3,
                tab: REPORT_FILTERS_TABS.MAIN,
                label: 'Статистика',
                id: 'stats',
                type: 'number',
                itemValue: 'id',
                itemText: 'name',
                template: this.getTemplate,
                noDataText: 'Нет типов статистики',
                isExcluded: false,
            }),
            new filterClass.Multiselect({
                col: 3,
                tab: REPORT_FILTERS_TABS.MAIN,
                label: 'Выплата',
                id: 'payout',
                type: 'number',
                itemValue: 'id',
                itemText: 'name',
                template: this.getTemplate,
                noDataText: 'Нет выплат',
                isExcluded: false,
            }),
            new filterClass.Multiselect({
                col: 3,
                tab: REPORT_FILTERS_TABS.MAIN,
                label: 'Конкурс',
                id: 'contest',
                type: 'number',
                itemValue: 'id',
                itemText: 'name',
                template: this.getTemplate,
                noDataText: 'Нет конкурсов',
                isExcluded: false,
            }),
            new filterClass.Multiselect({
                col: 3,
                tab: REPORT_FILTERS_TABS.MAIN,
                label: 'Акции',
                id: 'campaign',
                type: 'number',
                itemValue: 'id',
                itemText: this.getCampaignTemplate,
                template: this.getCampaignTemplate,
                noDataText: 'Нет акций',
                isExcluded: false,
            }),
            new filterClass.Multiselect({
                col: 3,
                tab: REPORT_FILTERS_TABS.MAIN,
                label: 'Теги офферов',
                id: 'tag',
                type: 'number',
                itemValue: 'id',
                itemText: 'name',
                template: this.getTemplate,
                noDataText: 'Нет тегов',
                isExcluded: false,
            }),
            new filterClass.Autocomplete({
                col: 4,
                tab: REPORT_FILTERS_TABS.MAIN,
                id: 'affiliate_manager_id',
                apiMethod: getActiveUserSearch,
                label: 'Менеджер партнёра',
                itemValue: 'id',
                itemText: 'name',
                multiple: true,
                isExcluded: false,
            }),
            new filterClass.Select({
                col: 4,
                tab: REPORT_FILTERS_TABS.MAIN,
                label: 'Буфер',
                id: 'buffer_account_used',
                select: '',
                items: ['true', 'false'],
                clearable: true,
                isShowCheckbox: true,
                noDataText: 'Нет данных',
                remove: true,
                type: 'string',
                isSubmitEmptySelect: false,
                translate: (item: string): string => item === 'false' ? 'Нет' : 'Да',
                isHidden: isReportAdvertiser,
            }),
            new filterClass.Autocomplete({
                col: 4,
                tab: REPORT_FILTERS_TABS.MAIN,
                id: 'buffer_account_id',
                label: `${isReportAdvertiser ? 'Партнер' : 'ID буфера'}`,
                apiMethod: getAffiliateSearch,
                itemValue: 'id',
                itemText: `${isReportAdvertiser ? 'name' : 'id'}`,
                template: (item: IUser): string => isReportAdvertiser ? `${item.id} ${item.first_name} ${item.last_name} <b>${item.email}</b>` : `${item.id!}`,
                noDataText: 'Нет данных',
                multiPaste: true,
                isExcluded: false,
            }),
            new filterClass.Autocomplete({
                col: 4,
                tab: REPORT_FILTERS_TABS.MAIN,
                id: 'master_account',
                label: 'Мастер-аккаунт',
                apiMethod: getAffiliateSearch,
                itemValue: 'id',
                itemText: 'text',
                template: (item: IUser): string => `${item.id!}`,
                noDataText: 'Нет мастер-аккаунтов',
                multiple: true,
                multiPaste: true,
                isShowUnlimited: true,
                isExcluded: false,
                isHidden: isReportAdvertiser,
            }),
            new filterClass.Autocomplete({
                col: 4,
                tab: REPORT_FILTERS_TABS.MAIN,
                id: 'affiliate_id',
                apiMethod: getAffiliateSearch,
                label: 'Партнер',
                itemValue: 'id',
                itemText: 'name',
                template: (item: IUser): string => `${item.id} ${item.first_name} ${item.last_name} <b>${item.email}</b>`,
                multiple: true,
                multiPaste: true,
                isShowUnlimited: true,
                isShowFullName: true,
                isExcluded: false,
                isHidden: isReportAdvertiser,
            }),
            new filterClass.Multiselect({
                col: 5,
                tab: REPORT_FILTERS_TABS.MAIN,
                label: 'Источник трафика',
                id: 'affiliate_traffic_source_id',
                type: 'number',
                itemValue: 'id',
                itemText: 'name',
                template: this.getTemplate,
                noDataText: 'Нет источника трафика',
                isExcluded: false,
                isHidden: isReportAdvertiser,
            }),
            new filterClass.Multiselect({
                col: 5,
                tab: REPORT_FILTERS_TABS.MAIN,
                id: 'affiliate_payment_method_id',
                type: 'string',
                label: 'Способ выплаты',
                noDataText: 'Нет способов выплат',
                itemValue: 'id',
                itemText: 'name.ru',
                template: this.getTemplate,
                isExcluded: false,
                isHidden: isReportAdvertiser,
            }),
            new filterClass.Multiselect({
                col: 5,
                tab: REPORT_FILTERS_TABS.MAIN,
                label: 'Теги партнёров',
                id: 'affiliate_tag',
                type: 'number',
                itemValue: 'id',
                itemText: 'name',
                template: this.getTemplate,
                noDataText: 'Нет тегов',
                isExcluded: false,
            }),
            new filterClass.Autocomplete({
                col: 7,
                tab: REPORT_FILTERS_TABS.MAIN,
                id: 'geo_country',
                label: 'ГЕО: Страна',
                apiMethod: filterByGeoOrOS,
                itemValue: 'id',
                itemText: 'id',
                multiple: true,
                isExcluded: false,
                isNeedSetIdForItems: true,
                type: 'string',
                select: prepareGeoItems('geo_country'),
                customParams: (_, value) => ({ type: 'country_ru', search: value }),
            }),
            new filterClass.Autocomplete({
                col: 7,
                tab: REPORT_FILTERS_TABS.MAIN,
                id: 'geo_city',
                label: 'ГЕО: Город',
                apiMethod: filterByGeoOrOS,
                itemValue: 'id',
                itemText: 'id',
                multiple: true,
                isExcluded: false,
                isNeedSetIdForItems: true,
                type: 'string',
                select: prepareGeoItems('geo_city'),
                customParams: (_, value) => ({ type: 'city_ru', search: value }),
            }),
            new filterClass.Autocomplete({
                col: 7,
                tab: REPORT_FILTERS_TABS.MAIN,
                id: 'ua_device',
                label: 'Устройство: Тип',
                apiMethod: filterByGeoOrOS,
                itemValue: 'id',
                itemText: 'id',
                multiple: true,
                isExcluded: false,
                isNeedSetIdForItems: true,
                customParams: (_, value) => ({ type: 'device', search: value }),
            }),
            new filterClass.Autocomplete({
                col: 7,
                tab: REPORT_FILTERS_TABS.MAIN,
                id: 'ua_os_name',
                label: 'Устройство: ОС',
                apiMethod: filterByGeoOrOS,
                itemValue: 'id',
                itemText: 'id',
                multiple: true,
                isExcluded: false,
                isNeedSetIdForItems: true,
                customParams: (_, value) => ({ type: 'os', search: value }),
            }),
            new filterClass.Autocomplete({
                col: 7,
                tab: REPORT_FILTERS_TABS.MAIN,
                id: 'ua_browser_name',
                label: 'Устройство: Браузер',
                apiMethod: filterByGeoOrOS,
                itemValue: 'id',
                itemText: 'id',
                multiple: true,
                isExcluded: false,
                isNeedSetIdForItems: true,
                customParams: (_, value) => ({ type: 'browser', search: value }),
            }),
            new filterClass.Compare({
                col: 1,
                colName: 'Клики',
                tab: REPORT_FILTERS_TABS.METRICS,
                id: 'mt_clicks_total',
                label: 'Всего',
            }),
            new filterClass.Compare({
                col: 1,
                colName: 'Клики',
                tab: REPORT_FILTERS_TABS.METRICS,
                id: 'mt_clicks_unique',
                label: 'Уникальные',
            }),
            new filterClass.Compare({
                col: 2,
                colName: 'Лиды',
                tab: REPORT_FILTERS_TABS.METRICS,
                id: 'mt_leads_total',
                label: 'Всего',
            }),
            new filterClass.Compare({
                col: 2,
                colName: 'Лиды',
                tab: REPORT_FILTERS_TABS.METRICS,
                id: 'mt_leads_approved',
                label: 'Принято',
            }),
            new filterClass.Compare({
                col: 2,
                colName: 'Лиды',
                tab: REPORT_FILTERS_TABS.METRICS,
                id: 'mt_leads_pending',
                label: 'В ожидании',
            }),
            new filterClass.Compare({
                col: 2,
                colName: 'Лиды',
                tab: REPORT_FILTERS_TABS.METRICS,
                id: 'mt_leads_rejected',
                label: 'Отклонено',
            }),
            new filterClass.Compare({
                col: 3,
                colName: 'Показатели',
                tab: REPORT_FILTERS_TABS.METRICS,
                id: 'mt_cr_u',
                label: 'CRu',
            }),
            new filterClass.Compare({
                col: 3,
                colName: 'Показатели',
                tab: REPORT_FILTERS_TABS.METRICS,
                id: 'mt_cr_t',
                label: 'CRt',
            }),
            new filterClass.Compare({
                col: 3,
                colName: 'Показатели',
                tab: REPORT_FILTERS_TABS.METRICS,
                id: 'mt_ar',
                label: 'AR',
            }),
            new filterClass.Compare({
                col: 3,
                colName: 'Показатели',
                tab: REPORT_FILTERS_TABS.METRICS,
                id: 'mt_epc_u',
                label: 'EPCu',
            }),
            new filterClass.Compare({
                col: 3,
                colName: 'Показатели',
                tab: REPORT_FILTERS_TABS.METRICS,
                id: 'mt_epc_t',
                label: 'EPCt',
            }),
            new filterClass.Compare({
                col: 3,
                colName: 'Показатели',
                tab: REPORT_FILTERS_TABS.METRICS,
                id: 'mt_epl',
                label: 'EPL',
            }),
            new filterClass.Compare({
                col: 3,
                colName: 'Показатели',
                tab: REPORT_FILTERS_TABS.METRICS,
                id: 'mt_ppl',
                label: 'PPL',
            }),
            new filterClass.Compare({
                col: 3,
                colName: 'Показатели',
                tab: REPORT_FILTERS_TABS.METRICS,
                id: 'mt_rpl',
                label: 'RPL',
            }),
            new filterClass.Compare({
                col: 3,
                colName: 'Показатели',
                tab: REPORT_FILTERS_TABS.METRICS,
                id: 'mt_epc_adv',
                label: 'EPC Adv.',
            }),
            new filterClass.Compare({
                col: 4,
                colName: 'Выплата',
                tab: REPORT_FILTERS_TABS.METRICS,
                id: 'mt_payout_total',
                label: 'Всего',
            }),
            new filterClass.Compare({
                col: 4,
                colName: 'Выплата',
                tab: REPORT_FILTERS_TABS.METRICS,
                id: 'mt_payout_approved',
                label: 'Принято',
            }),
            new filterClass.Compare({
                col: 4,
                colName: 'Выплата',
                tab: REPORT_FILTERS_TABS.METRICS,
                id: 'mt_payout_rejected',
                label: 'Отклонено',
            }),
            new filterClass.Compare({
                col: 4,
                colName: 'Выплата',
                tab: REPORT_FILTERS_TABS.METRICS,
                id: 'mt_payout_pending',
                label: 'В ожидании',
            }),
            new filterClass.Compare({
                col: 5,
                colName: 'Доход',
                tab: REPORT_FILTERS_TABS.METRICS,
                id: 'mt_revenue_total',
                label: 'Всего',
            }),
            new filterClass.Compare({
                col: 5,
                colName: 'Доход',
                tab: REPORT_FILTERS_TABS.METRICS,
                id: 'mt_revenue_approved',
                label: 'Принято',
            }),
            new filterClass.Compare({
                col: 5,
                colName: 'Доход',
                tab: REPORT_FILTERS_TABS.METRICS,
                id: 'mt_revenue_rejected',
                label: 'Отклонено',
            }),
            new filterClass.Compare({
                col: 5,
                colName: 'Доход',
                tab: REPORT_FILTERS_TABS.METRICS,
                id: 'mt_revenue_pending',
                label: 'В ожидании',
            }),
            new filterClass.Compare({
                col: 6,
                colName: 'Конкурс',
                tab: REPORT_FILTERS_TABS.METRICS,
                id: 'mt_bonus_amount',
                label: 'Бонусы',
            }),
            new filterClass.Compare({
                col: 6,
                colName: 'Конкурс',
                tab: REPORT_FILTERS_TABS.METRICS,
                id: 'mt_bonus_payout_approved',
                label: 'Принято с бонусами',
            }),
            new filterClass.Compare({
                col: 6,
                colName: 'Конкурс',
                tab: REPORT_FILTERS_TABS.METRICS,
                id: 'mt_bonus_epc',
                label: 'EPCu с бонусами',
            }),
            new filterClass.Compare({
                col: 6,
                colName: 'Конкурс',
                tab: REPORT_FILTERS_TABS.METRICS,
                id: 'mt_bonus_epc_t',
                label: 'EPCt с бонусами',
            }),
            new filterClass.Compare({
                col: 6,
                colName: 'Конкурс',
                tab: REPORT_FILTERS_TABS.METRICS,
                id: 'mt_bonus_epl',
                label: 'EPL с бонусами',
            }),
            new filterClass.Compare({
                col: 7,
                colName: 'Прибыль',
                tab: REPORT_FILTERS_TABS.METRICS,
                id: 'mt_profit',
                label: 'Прибыль',
            }),
            new filterClass.Datepicker({
                col: 1,
                tab: REPORT_FILTERS_TABS.REGISTER,
                id: 'affiliate_register_date',
                label: 'Дата регистрации',
                pickerType: 'date',
                range: true,
                isHidden: isReportAdvertiser,
            }),
            new filterClass.Input({
                col: 1,
                tab: REPORT_FILTERS_TABS.REGISTER,
                id: 'dm_affiliate_utm_source',
                label: 'UTM source',
            }),
            new filterClass.Input({
                col: 2,
                tab: REPORT_FILTERS_TABS.REGISTER,
                id: 'dm_affiliate_utm_medium',
                label: 'UTM medium',
            }),
            new filterClass.Input({
                col: 2,
                tab: REPORT_FILTERS_TABS.REGISTER,
                id: 'dm_affiliate_utm_campaign',
                label: 'UTM campaign',
            }),
            new filterClass.Input({
                col: 3,
                tab: REPORT_FILTERS_TABS.REGISTER,
                id: 'dm_affiliate_utm_term',
                label: 'UTM term',
            }),
            new filterClass.Input({
                col: 3,
                tab: REPORT_FILTERS_TABS.REGISTER,
                id: 'dm_affiliate_utm_content',
                label: 'UTM Content',
            }),
            new filterClass.Input({
                col: 4,
                tab: REPORT_FILTERS_TABS.REGISTER,
                id: 'dm_affiliate_utm_gid',
                label: 'Gid',
            }),
            new filterClass.Input({
                col: 4,
                tab: REPORT_FILTERS_TABS.REGISTER,
                id: 'dm_affiliate_utm_aid',
                label: 'Aid',
            }),
            new filterClass.Input({
                col: 5,
                tab: REPORT_FILTERS_TABS.REGISTER,
                id: 'dm_affiliate_utm_gclid',
                label: 'Gclid',
            }),
            new filterClass.Input({
                col: 5,
                tab: REPORT_FILTERS_TABS.REGISTER,
                id: 'dm_affiliate_utm_creative',
                label: 'Creative',
            }),
            new filterClass.Input({
                col: 6,
                tab: REPORT_FILTERS_TABS.REGISTER,
                id: 'dm_affiliate_utm_placement',
                label: 'Placement',
            }),
            new filterClass.Input({
                col: 6,
                tab: REPORT_FILTERS_TABS.REGISTER,
                id: 'dm_affiliate_utm_rand',
                label: 'Rand',
            }),
            new filterClass.Input({
                col: 1,
                tab: REPORT_FILTERS_TABS.MARKING,
                id: 'erir_ord',
                label: 'Мар-ка: ОРД',
                isSubmitEmptySelect: false,
                clearable: false,
            }),
            new filterClass.Input({
                col: 1,
                tab: REPORT_FILTERS_TABS.MARKING,
                id: 'erir_legal',
                label: 'Мар-ка: договор',
                isSubmitEmptySelect: false,
                clearable: false,
            }),
            new filterClass.Input({
                col: 2,
                tab: REPORT_FILTERS_TABS.MARKING,
                id: 'erir_platform_id',
                label: 'Мар-ка: ID площадки',
                isSubmitEmptySelect: false,
                clearable: false,
                type: "number",
            }),
            new filterClass.Input({
                col: 2,
                tab: REPORT_FILTERS_TABS.MARKING,
                id: 'erir_platform_url',
                label: 'Мар-ка: URL площадки',
                isSubmitEmptySelect: false,
                clearable: false,
            }),
            new filterClass.Input({
                col: 3,
                tab: REPORT_FILTERS_TABS.MARKING,
                id: 'erir_platform_type',
                label: 'Мар-ка: тип площадки',
                isSubmitEmptySelect: false,
                clearable: false,
            }),
            new filterClass.Select({
                col: 3,
                tab: REPORT_FILTERS_TABS.MARKING,
                label: 'Мар-ка: площадка используется',
                id: 'erir_platform_used',
                select: '',
                items: ['true', 'false'],
                clearable: true,
                isShowCheckbox: true,
                noDataText: 'Нет данных',
                remove: true,
                type: 'string',
                isSubmitEmptySelect: false,
                translate: (item: string): string => item === 'false' ? 'Нет' : 'Да',
            }),
            new filterClass.Select({
                col: 4,
                tab: REPORT_FILTERS_TABS.MARKING,
                label: 'Мар-ка: используется',
                id: 'erir_used',
                select: '',
                items: ['true', 'false'],
                clearable: true,
                isShowCheckbox: true,
                noDataText: 'Нет данных',
                remove: true,
                type: 'string',
                isSubmitEmptySelect: false,
                translate: (item: string): string => item === 'false' ? 'Нет' : 'Да',
            }),
            new filterClass.Input({
                col: 4,
                tab: REPORT_FILTERS_TABS.MARKING,
                id: 'erir_token',
                label: 'Мар-ка: токен',
                isSubmitEmptySelect: false,
                clearable: false,
            }),
        ];
    }

    private getTemplate(item: any): string {
        return item.name.ru || item.name || item.code || item.master?.id;
    }

    private getCampaignTemplate(item: any): string {
        return item.name + ' (' + translateCampaignStatus(item.status).text + ')';
    }

    changeGroup(filter: IFilter): void {
        eventBus.$emit('change-date', filter);
    }

    addSubsToFilters(): void {
        for (let i = 1; i <= 25; i++) {
            this.filters.push(
                new filterClass.Base({
                    col: 6,
                    colName: 'Sub ID',
                    tab: REPORT_FILTERS_TABS.MAIN,
                    id: `sub${i}`,
                    label: `Sub ID ${i}`,
                    type: 'string',
                    select: [],
                    customSlot: true,
                    multiple: true,
                    isSubmitEmptySelect: false,
                    isExcluded: false,
                }),
            );
        }
    }

    getFilterOperator(id: string): string {
        if (id.includes('mt_')) return this.getCompareOperator(id);
        const excludedFiltersIds = this.getFilterExcluded();
        return excludedFiltersIds.some(i => i === id) ? 'nin' : 'in';
    }

    clearExcluded(filters: any): void {
        this.filters.forEach(f => {
            if (!filters[f.id]?.length && f.isExcluded !== undefined) f.isExcluded = false;
        });
    }

    getCompareOperator(id: string): string {
        return this.filters.find(f => f.id === id)?.operator || COMPARE_OPERATORS.GT;
    }

    getFilterExcluded(): string[] {
        const filters = store.getters.GET_FILTERS_PARAMS_OBJECT;
        return this.filters.filter(f => f.isExcluded && filters[f.id]).map(i => i.id) || [];
    }

    getMetricOperatorsForQuery(): any[] {
        const filters = store.getters.GET_FILTERS_PARAMS_OBJECT;
        const mtFilters = this.filters.filter(f => f.compare && filters[f.id]);
        const operators = mtFilters.map(f => ({ [`${f.id}_operator`]: f.operator }));
        return Object.assign({}, ...operators);
    }

    concatFiltersForSend(selectedFilters: any): { column: string, operator: string, values: any }[] {
        this.clearExcluded(selectedFilters);
        const data: { column: string, operator: string, values: any }[] = [];
        if (!!selectedFilters.date_from && !!selectedFilters.date_to) {
            const date = {
                column: 'dm_lead_date',
                operator: 'between',
                values: [selectedFilters.date_from, selectedFilters.date_to],
            };
            data.push(date);
        }
        if (!!selectedFilters.affiliate_register_date_from && !!selectedFilters.affiliate_register_date_to) {
            const date = {
                column: 'dm_affiliate_register_date',
                operator: 'between',
                values: [selectedFilters.affiliate_register_date_from, selectedFilters.affiliate_register_date_to],
            };
            data.push(date);
        }
        const simpleTextFilterIds = this.getFilters().filter(f => f instanceof InputFilter && !f.id.includes('erir')).map(f => f.id);
        const notFixFilters = ['date_from', 'date_to', 'affiliate_register_date_from', 'affiliate_register_date_to', 'columns', 'group', 'erir_platform_used', 'erir_used', 'buffer_account_used', 'sort_desc', 'sort_by'];
        const geoAndUaFilters = ['geo_country', 'geo_city', 'ua_device', 'ua_os_name', 'ua_browser_name'];

        const isErir = (key) => key.includes('erir');
        const isWithSub = (key) => key.includes('sub');
        const isNotEmpty = (key) => selectedFilters[key]?.length > 0 || selectedFilters[key] > 0;
        const isNotBuffer = (key) => key !== 'buffer_account_id';

        for (const key in selectedFilters) {
            if (simpleTextFilterIds.includes(key)) {
                const item = {
                    column: key,
                    operator: this.getFilterOperator(key),
                    values: [selectedFilters[key]],
                };
                data.push(item);
            } else if (key.includes('mt_') && !key.includes('_operator')) {
                const item = {
                    column: key,
                    operator: this.getFilterOperator(key),
                    values: selectedFilters[key].map((item) => +item),
                };
                data.push(item);
            } else if (isWithSub(key) && router.currentRoute.query.hasOwnProperty(key) && selectedFilters[key]?.length >= 0) {
                const subId = {
                    column: `dm_aff_${key}`,
                    operator: this.getFilterOperator(key),
                    values: selectedFilters[key],
                };
                data.push(subId);
            } else if (((key.includes('_id') && !isWithSub(key) && isNotBuffer(key)) || key === 'master_account' || key === 'affiliate_tag' || geoAndUaFilters.includes(key)) && isNotEmpty(key)) {
                const item = {
                    column: `dm_${key}`,
                    operator: this.getFilterOperator(key),
                    values: Array.isArray(selectedFilters[key]) ?
                        { ...selectedFilters[key].map(i => +i || i) } : { key: selectedFilters[key] },
                };
                data.push(item);
            } else if (!notFixFilters.includes(key) && !isWithSub(key) && isNotBuffer(key) && !isErir(key) && isNotEmpty(key)) {
                const item = {
                    column: `dm_offer_${key}`,
                    operator: this.getFilterOperator(key),
                    values: { ...selectedFilters[key] },
                };
                data.push(item);
            } else if (key === 'buffer_account_id' && isNotEmpty(key)) {
                const item = {
                    column: `dm_${key}`,
                    operator: this.getFilterOperator(key),
                    values: selectedFilters[key].map((item) => +item),
                };
                data.push(item);
            } else if ((key === 'buffer_account_used' || key === 'erir_platform_used' || key === 'erir_used') && isNotEmpty(key)) {
                const valueBoolean = selectedFilters[key] === 'true';
                const item = {
                    column: `dm_${key}`,
                    operator: this.getFilterOperator(key),
                    values: [+selectedFilters[key] || valueBoolean],
                };
                data.push(item);
            } else if ((isErir(key) && !notFixFilters.includes(key)) && (isNotEmpty(key) )) {
                const item = {
                    column: `dm_${key}`,
                    operator: 'eq',
                    values: [selectedFilters[key]],
                };
                data.push(item);
            }
        }
        return data;
    }

    // ограничения по выбору дат в фильтре Период
    getAllowedDate(): { start: Date | null, filters: string[] }[] {
        return [
            // период не может быть раньше 2022-10-15, если в группировке выбраны sub_id5-sub_id10.
            {
                filters: ['dm_aff_sub5', 'dm_aff_sub6', 'dm_aff_sub7', 'dm_aff_sub8', 'dm_aff_sub9', 'dm_aff_sub10', 'dm_buffer_account_used', 'dm_buffer_account_id', 'dm_buffer_account_name'],
                start: new Date(REPORT_ALLOWED_DATE_SUBS),
            },
            // период не может быть раньше 2022-11-01, если выбраны гео
            {
                filters: ['geo_country', 'geo_city', 'ua_device', 'ua_os_name', 'ua_browser_name'],
                start: new Date(REPORT_ALLOWED_DATE_GEO),
            },
            // период не может быть больше чем 763 дней, если в группировке выбраны клики и метрики.
            {
                filters: ['mt_clicks_total', 'mt_clicks_unique', 'mt_cr_u', 'mt_cr_t', 'mt_epc_u', 'mt_epc_t', 'mt_epc_adv'],
                start: new Date(getPreviousDate('yyyy-MM-dd', 763)),
            },
            // период не может быть больше чем 548 дней, если в группировке выбраны sub_id1-sub_id5.
            {
                filters: ['dm_aff_sub1', 'dm_aff_sub2', 'dm_aff_sub3', 'dm_aff_sub4', 'dm_aff_sub5'],
                start: new Date(getPreviousDate('yyyy-MM-dd', 548)),
            },
        ];
    }
}

export enum REPORT_FILTERS_TABS {
    GROUPING = 0,
    INDICATORS = 1,
    MAIN = 2,
    METRICS = 3,
    REGISTER = 4,
    MARKING = 5
}

export const extendedBaseHeaders = (): TableHeader[] => {
    const isReportAdvertiser = router.currentRoute.path === '/statistics/extended-report/advertiser';
    return [
        { text: 'Дата', value: 'dm_lead_date', sortable: true, align: 'left', width: '120px' },
        { text: 'Дата', value: 'dm_lead_date_hour', sortable: true, align: 'left', width: '120px' },
        { text: 'Дата', value: 'dm_lead_date_week', sortable: true, align: 'left', width: '120px' },
        { text: 'Дата', value: 'dm_lead_date_month', sortable: true, align: 'left', width: '120px' },
        { text: 'Дата', value: 'dm_lead_date_year', sortable: true, align: 'left', width: '120px' },
        { text: 'Валюта', value: 'dm_offer_currency', sortable: true, disabled: false, align: 'center' },
        { text: 'Буфер', sortable: true, value: 'dm_buffer_account_used', align: 'left' },
        { text: isReportAdvertiser ? 'ID партнера' : 'ID буфера', sortable: true, value: 'dm_buffer_account_id', align: 'left' },
        { text: isReportAdvertiser ? 'Партнер' : 'Имя буфера', sortable: false, value: 'dm_buffer_account_name', align: 'left' },
        { text: 'Мастер-аккаунт', sortable: true, value: 'dm_master_account', align: 'left' },
        { text: 'Имя мастер-аккаунта', sortable: false, value: 'dm_master_account_name', align: 'left' },
        { text: 'ID партнера', value: 'dm_affiliate_id', sortable: true, align: 'left' },
        { text: 'Партнер', sortable: true, value: 'dm_affiliate_name', align: 'left' },
        { text: 'ID менеджера партнера', value: 'dm_affiliate_manager_id', sortable: true, align: 'left' },
        { text: 'Менеджер партнера', value: 'dm_affiliate_manager_name', sortable: true, align: 'left' },
        { text: 'Источник трафика', sortable: true, value: 'dm_affiliate_traffic_source_name', align: 'left' },
        { text: 'Способ выплаты', value: 'dm_affiliate_payment_method_name', sortable: true, align: 'left' },
        { text: 'Теги партнёров', value: 'dm_affiliate_tag', sortable: true, align: 'left' },
        { text: 'Вертикаль', value: 'dm_offer_vertical', sortable: true, align: 'left' },
        { text: 'ID рекламодателя', value: 'dm_advertiser_id', sortable: true, align: 'left' },
        { text: 'Рекламодатель', value: 'dm_advertiser_name', sortable: true, align: 'left' },
        { text: 'ID менеджера рекламодателя', value: 'dm_advertiser_manager_id', sortable: true, align: 'left' },
        { text: 'Менеджер рекламодателя', value: 'dm_advertiser_manager_name', sortable: true, align: 'left' },
        { text: 'Теги офферов', value: 'dm_offer_tag', sortable: true, align: 'left' },
        { text: 'ID оффера', value: 'dm_offer_id', sortable: true, align: 'left' },
        { text: 'Оффер', value: 'dm_offer_name', sortable: true, align: 'left' },
        { text: 'ГЕО(Страна)', value: 'dm_geo_country', sortable: true, align: 'left' },
        { text: 'ГЕО(Город)', value: 'dm_geo_city', sortable: true, align: 'left' },
        { text: 'ID лендинга', value: 'dm_offer_url_id', sortable: true, align: 'left' },
        { text: 'Название лендинга', value: 'dm_offer_url_name', sortable: true, align: 'left' },
        { text: 'Способ трекинга', value: 'dm_offer_tracking_protocol', sortable: true, align: 'left' },
        { text: 'Тип продукта', value: 'dm_offer_product', sortable: true, align: 'left' },
        { text: 'Тип устройства', value: 'dm_ua_device', sortable: true, align: 'left' },
        { text: 'ОС', value: 'dm_ua_os_name', sortable: true, align: 'left' },
        { text: 'Браузер', value: 'dm_ua_browser_name', sortable: true, align: 'left' },
        { text: 'Токен', value: 'dm_erir_token', sortable: true, align: 'left', class: 'no-wrap' },
        { text: 'ОРД', value: 'dm_erir_ord', sortable: true, align: 'left', class: 'no-wrap' },
        { text: 'Договор', value: 'dm_erir_legal', sortable: true, align: 'left', class: 'no-wrap' },
        { text: 'Используется', value: 'dm_erir_used', sortable: true, align: 'left', class: 'no-wrap' },
        { text: 'ID площадки', value: 'dm_erir_platform_id', sortable: true, align: 'left', class: 'no-wrap' },
        { text: 'Название площадки', value: 'dm_erir_platform_name', sortable: true, align: 'left', class: 'no-wrap' },
        { text: 'URL площадки', value: 'dm_erir_platform_url', sortable: true, align: 'left', class: 'no-wrap' },
        { text: 'Тип площадки', value: 'dm_erir_platform_type', sortable: true, align: 'left', class: 'no-wrap' },
        { text: 'Партнёр площадки', value: 'dm_erir_platform_affiliate_id', sortable: true, align: 'left', class: 'no-wrap' },
        { text: 'Площадка используется', value: 'dm_erir_platform_used', sortable: true, align: 'left', class: 'no-wrap' },
        { text: 'Sub ID1', value: 'dm_aff_sub1', sortable: true, align: 'left', class: 'no-wrap' },
        { text: 'Sub ID2', value: 'dm_aff_sub2', sortable: true, align: 'left', class: 'no-wrap' },
        { text: 'Sub ID3', value: 'dm_aff_sub3', sortable: true, align: 'left', class: 'no-wrap' },
        { text: 'Sub ID4', value: 'dm_aff_sub4', sortable: true, align: 'left', class: 'no-wrap' },
        { text: 'Sub ID5', value: 'dm_aff_sub5', sortable: true, align: 'left', class: 'no-wrap' },
        { text: 'Sub ID6', value: 'dm_aff_sub6', sortable: true, align: 'left', class: 'no-wrap' },
        { text: 'Sub ID7', value: 'dm_aff_sub7', sortable: true, align: 'left', class: 'no-wrap' },
        { text: 'Sub ID8', value: 'dm_aff_sub8', sortable: true, align: 'left', class: 'no-wrap' },
        { text: 'Sub ID9', value: 'dm_aff_sub9', sortable: true, align: 'left', class: 'no-wrap' },
        { text: 'Sub ID10', value: 'dm_aff_sub10', sortable: true, align: 'left', class: 'no-wrap' },
        { text: 'Sub ID11', value: 'dm_aff_sub11', sortable: true, align: 'left', class: 'no-wrap' },
        { text: 'Sub ID12', value: 'dm_aff_sub12', sortable: true, align: 'left', class: 'no-wrap' },
        { text: 'Sub ID13', value: 'dm_aff_sub13', sortable: true, align: 'left', class: 'no-wrap' },
        { text: 'Sub ID14', value: 'dm_aff_sub14', sortable: true, align: 'left', class: 'no-wrap' },
        { text: 'Sub ID15', value: 'dm_aff_sub15', sortable: true, align: 'left', class: 'no-wrap' },
        { text: 'Sub ID16', value: 'dm_aff_sub16', sortable: true, align: 'left', class: 'no-wrap' },
        { text: 'Sub ID17', value: 'dm_aff_sub17', sortable: true, align: 'left', class: 'no-wrap' },
        { text: 'Sub ID18', value: 'dm_aff_sub18', sortable: true, align: 'left', class: 'no-wrap' },
        { text: 'Sub ID19', value: 'dm_aff_sub19', sortable: true, align: 'left', class: 'no-wrap' },
        { text: 'Sub ID20', value: 'dm_aff_sub20', sortable: true, align: 'left', class: 'no-wrap' },
        { text: 'Sub ID21', value: 'dm_aff_sub21', sortable: true, align: 'left', class: 'no-wrap' },
        { text: 'Sub ID22', value: 'dm_aff_sub22', sortable: true, align: 'left', class: 'no-wrap' },
        { text: 'Sub ID23', value: 'dm_aff_sub23', sortable: true, align: 'left', class: 'no-wrap' },
        { text: 'Sub ID24', value: 'dm_aff_sub24', sortable: true, align: 'left', class: 'no-wrap' },
        { text: 'Sub ID25', value: 'dm_aff_sub25', sortable: true, align: 'left', class: 'no-wrap' },
        { text: 'ID цели', value: 'dm_offer_goal_id', sortable: true, align: 'left' },
        { text: 'Цель', value: 'dm_offer_goal_name', sortable: true, align: 'left' },
        { text: 'Страна', value: 'dm_offer_country', sortable: true, align: 'left' },
        { text: 'Клики: всего', value: 'mt_clicks_total', sortable: true, align: 'right' },
        { text: 'Клики: уник.', sortable: true, value: 'mt_clicks_unique', align: 'right' },
        { text: 'Лиды: всего', sortable: true, value: 'mt_leads_total', align: 'right' },
        { text: 'Лиды: принято', sortable: true, value: 'mt_leads_approved', align: 'right' },
        { text: 'Лиды: в ожидании', sortable: true, value: 'mt_leads_pending', align: 'right' },
        { text: 'Лиды: отклонено', sortable: true, value: 'mt_leads_rejected', align: 'right' },
        { text: 'CRu', value: 'mt_cr_u', sortable: true, align: 'right', class: 'no-wrap' },
        { text: 'CRt', value: 'mt_cr_t', sortable: true, align: 'right', class: 'no-wrap' },
        { text: 'AR', value: 'mt_ar', sortable: true, align: 'right', class: 'no-wrap' },
        { text: 'EPCu', value: 'mt_epc_u', sortable: true, align: 'right', class: 'no-wrap' },
        { text: 'EPCt', value: 'mt_epc_t', sortable: true, align: 'right', class: 'no-wrap' },
        { text: 'EPC Adv.', value: 'mt_epc_adv', sortable: true, align: 'right', class: 'no-wrap' },
        { text: 'EPL', value: 'mt_epl', sortable: true, align: 'right', class: 'no-wrap' },
        { text: 'UTM source', value: 'dm_affiliate_utm_source', sortable: true, align: 'left' },
        { text: 'UTM medium', value: 'dm_affiliate_utm_medium', sortable: true, align: 'left' },
        { text: 'UTM campaign', value: 'dm_affiliate_utm_campaign', sortable: true, align: 'left' },
        { text: 'UTM term', value: 'dm_affiliate_utm_term', sortable: true, align: 'left' },
        { text: 'UTM content', value: 'dm_affiliate_utm_content', sortable: true, align: 'left' },
        { text: 'Gid', value: 'dm_affiliate_utm_gid', sortable: true, align: 'left' },
        { text: 'Aid', value: 'dm_affiliate_utm_aid', sortable: true, align: 'left' },
        { text: 'Gclid', value: 'dm_affiliate_utm_gclid', sortable: true, align: 'left' },
        { text: 'Creative', value: 'dm_affiliate_utm_creative', sortable: true, align: 'left' },
        { text: 'Placement', value: 'dm_affiliate_utm_placement', sortable: true, align: 'left' },
        { text: 'Rand', value: 'dm_affiliate_utm_rand', sortable: true, align: 'left' },
        { text: 'Выплаты: всего', value: 'mt_payout_total', sortable: true, align: 'right' },
        { text: 'Выплаты: в ожидании', value: 'mt_payout_pending', sortable: true, align: 'right' },
        { text: 'Выплаты: принято', value: 'mt_payout_approved', sortable: true, align: 'right' },
        { text: 'Выплаты: отклонено', value: 'mt_payout_rejected', sortable: true, align: 'right' },
        { text: 'Выплаты: за лид', value: 'mt_ppl', sortable: true, align: 'right' },
        { text: 'Доход: всего', value: 'mt_revenue_total', sortable: true, align: 'right' },
        { text: 'Доход: в ожидании', sortable: true, value: 'mt_revenue_pending', align: 'right' },
        { text: 'Доход: принято', sortable: true, value: 'mt_revenue_approved', align: 'right' },
        { text: 'Доход: отклонено', sortable: true, value: 'mt_revenue_rejected', align: 'right' },
        { text: 'Доход: за лид', sortable: true, value: 'mt_rpl', align: 'right' },
        { text: 'Бонусы', value: 'mt_bonus_amount', sortable: true, align: 'right' },
        { text: 'Принято с бонусами', sortable: true, value: 'mt_bonus_payout_approved', align: 'right' },
        { text: 'EPCu с бонусами', sortable: true, value: 'mt_bonus_epc', align: 'right' },
        { text: 'EPCt с бонусами', sortable: true, value: 'mt_bonus_epc_t', align: 'right' },
        { text: 'EPL с бонусами', sortable: true, value: 'mt_bonus_epl', align: 'right' },
        { text: 'Прибыль', sortable: true, value: 'mt_profit', align: 'right', class: 'no-wrap' },
    ];
};
export const columnsExtendedReport: Array<{ base: string, letters: string }> = [
    { base: 'Оффер', letters: 'dm_offer_name' },
    { base: 'Статус оффера', letters: 'dm_offer_status' },
    { base: 'Дата', letters: 'dm_lead_date' },
    { base: ' (часы)', letters: '_hour' },
    { base: ' (недели)', letters: '_week' },
    { base: ' (года)', letters: '_year' },
    { base: ' (месяцы)', letters: '_month' },
    { base: 'ID Оффера', letters: 'dm_offer_id' },
    { base: 'ID Рекламодателя', letters: 'dm_advertiser_id' },
    { base: 'Рекламодатель', letters: 'dm_advertiser_name' },
    { base: 'ID цели', letters: 'dm_offer_goal_id' },
    { base: 'Цель', letters: 'dm_offer_goal_name' },
    { base: 'ID Менеджера рекламодателя', letters: 'dm_advertiser_manager_id' },
    { base: 'Менеджер рекламодателя', letters: 'dm_advertiser_manager_name' },
    { base: 'Теги офферов', letters: 'dm_offer_tag' },
    { base: 'ID Партнера', letters: 'dm_affiliate_id' },
    { base: 'Партнер', letters: 'dm_affiliate_name' },
    { base: 'ID Менеджера Партнера', letters: 'dm_affiliate_manager_id' },
    { base: 'Менеджер Партнера', letters: 'dm_affiliate_manager_name' },
    { base: 'Мастер-аккаунт', letters: 'dm_master_account' },
    { base: 'Буфер', letters: 'dm_buffer_account_used' },
    { base: 'ID Буфера', letters: 'dm_buffer_account_id' },
    { base: 'Источник трафика', letters: 'dm_affiliate_traffic_source_name' },
    { base: 'Способ выплаты', letters: 'dm_affiliate_payment_method_name' },
    { base: 'Теги партнёров', letters: 'dm_affiliate_tag' },
    { base: 'Вертикаль', letters: 'dm_offer_vertical' },
    { base: 'Итого', letters: 'summary' },
    { base: 'Страна', letters: 'dm_offer_country' },
    { base: 'Страна', letters: 'dm_geo_country' },
    { base: 'Город', letters: 'dm_geo_city' },
    { base: 'Устройство', letters: 'dm_ua_device' },
    { base: 'ОС', letters: 'dm_ua_os_name' },
    { base: 'Браузер', letters: 'dm_ua_browser_name' },
    { base: 'Выплаты партнёрам Всего', letters: 'mt_payout_total' },
    { base: 'Выплаты партнёрам Принято', letters: 'mt_payout_approved' },
    { base: 'Выплаты партнёрам В ожидании', letters: 'mt_payout_pending' },
    { base: 'Выплаты партнёрам Отклонено', letters: 'mt_payout_rejected' },
    { base: 'Выплаты партнёрам Выплаты за лид', letters: 'mt_ppl' },
    { base: 'Доход от рекламодателей Всего', letters: 'mt_revenue_total' },
    { base: 'Доход от рекламодателей Принято', letters: 'mt_revenue_approved' },
    { base: 'Доход от рекламодателей В ожидании', letters: 'mt_revenue_pending' },
    { base: 'Доход от рекламодателей Отклонено', letters: 'mt_revenue_rejected' },
    { base: 'Доход от рекламодателей Доход за лид', letters: 'mt_rpl' },
    { base: 'Прибыль', letters: 'mt_profit' },
    { base: 'Доход от рекламодателей Всего', letters: 'mt_revenue_total' },
    { base: 'Доход от рекламодателей Принято', letters: 'mt_revenue_approved' },
    { base: 'Доход от рекламодателей В ожидании', letters: 'mt_revenue_pending' },
    { base: 'Доход от рекламодателей Отклонено', letters: 'mt_revenue_rejected' },
    { base: 'Доход от рекламодателей Доход за лид', letters: 'mt_rpl' },
    { base: 'Клики всего', letters: 'mt_clicks_total' },
    { base: 'Клики уникальные', letters: 'mt_clicks_unique' },
    { base: 'Лиды Принято', letters: 'mt_leads_approved' },
    { base: 'Лиды В ожидании', letters: 'mt_leads_pending' },
    { base: 'Лиды Всего', letters: 'mt_leads_total' },
    { base: 'Лиды Отклонено', letters: 'mt_leads_rejected' },
    { base: 'CRu', letters: 'mt_cr_u' },
    { base: 'CRt', letters: 'mt_cr_t' },
    { base: 'AR', letters: 'mt_ar' },
    { base: 'EPCu', letters: 'mt_epc_u' },
    { base: 'EPCt', letters: 'mt_epc_t' },
    { base: 'EPL', letters: 'mt_epl' },
    { base: 'EPL ADV', letters: 'mt_epc_adv' },
    { base: 'Валюта', letters: 'dm_offer_currency' },
    { base: 'Токен', letters: 'dm_erir_token' },
    { base: 'ОРД', letters: 'dm_erir_ord' },
    { base: 'Договор', letters: 'dm_erir_legal' },
    { base: 'Используется', letters: 'dm_erir_used' },
    { base: 'ID площадки', letters: 'dm_erir_platform_id' },
    { base: 'Название площадки', letters: 'dm_erir_platform_name' },
    { base: 'URL площадки', letters: 'dm_erir_platform_url' },
    { base: 'Тип площадки', letters: 'dm_erir_platform_type' },
    { base: 'Партнёр площадки', letters: 'dm_erir_platform_affiliate_id' },
    { base: 'Площадка используется', letters: 'dm_erir_platform_used' },
    { base: 'sub1', letters: 'dm_aff_sub1' },
    { base: 'sub2', letters: 'dm_aff_sub2' },
    { base: 'sub3', letters: 'dm_aff_sub3' },
    { base: 'sub4', letters: 'dm_aff_sub4' },
    { base: 'sub5', letters: 'dm_aff_sub5' },
    { base: 'sub6', letters: 'dm_aff_sub6' },
    { base: 'sub7', letters: 'dm_aff_sub7' },
    { base: 'sub8', letters: 'dm_aff_sub8' },
    { base: 'sub9', letters: 'dm_aff_sub9' },
    { base: 'sub10', letters: 'dm_aff_sub10' },
    { base: 'sub11', letters: 'dm_aff_sub11' },
    { base: 'sub12', letters: 'dm_aff_sub12' },
    { base: 'sub13', letters: 'dm_aff_sub13' },
    { base: 'sub14', letters: 'dm_aff_sub14' },
    { base: 'sub15', letters: 'dm_aff_sub15' },
    { base: 'sub16', letters: 'dm_aff_sub16' },
    { base: 'sub17', letters: 'dm_aff_sub17' },
    { base: 'sub18', letters: 'dm_aff_sub18' },
    { base: 'sub19', letters: 'dm_aff_sub19' },
    { base: 'sub20', letters: 'dm_aff_sub20' },
    { base: 'sub21', letters: 'dm_aff_sub21' },
    { base: 'sub22', letters: 'dm_aff_sub22' },
    { base: 'sub23', letters: 'dm_aff_sub23' },
    { base: 'sub24', letters: 'dm_aff_sub24' },
    { base: 'sub25', letters: 'dm_aff_sub25' },
];

export const extendedFilterCols: IColumnFilter[] = [
    {
        name: '',
        items: [
            { title: 'Дата', value: 'dm_lead_date' },
            { title: 'Валюта оффера', value: 'dm_offer_currency' },
        ],
    },
    {
        name: '',
        items: [
            { title: 'ГЕО: Страна', value: 'dm_geo_country' },
            { title: 'ГЕО: Город', value: 'dm_geo_city' },
            { title: 'Устройство: Тип', value: 'dm_ua_device' },
            { title: 'Устройство: ОС', value: 'dm_ua_os_name' },
            { title: 'Устройство: Браузер', value: 'dm_ua_browser_name' },
        ],
    },
    {
        name: '',
        items: [
            { title: 'Рекламодатель', value: 'dm_advertiser_name' },
            { title: 'ID рекламодателя', value: 'dm_advertiser_id' },
            { title: 'Менеджер рекламодателя', value: 'dm_advertiser_manager_name' },
            { title: 'Теги офферов', value: 'dm_offer_tag' },
        ],
    },
    {
        name: '',
        items: [
            { title: 'Оффер', value: 'dm_offer_name' },
            { title: 'ID Оффера', value: 'dm_offer_id' },
            { title: 'Цель', value: 'dm_offer_goal_name' },
            { title: 'ID Цели', value: 'dm_offer_goal_id' },
            { title: 'Страна оффера', value: 'dm_offer_country' },
            { title: 'ID лендинга', value: 'dm_offer_url_id' },
            { title: 'Название лендинга', value: 'dm_offer_url_name' },
            { title: 'Способ трекинга', value: 'dm_offer_tracking_protocol' },
            { title: 'Тип продукта', value: 'dm_offer_product' },
        ],
    },
    {
        name: '',
        items: [
            { title: 'Менеджер партнера', value: 'dm_affiliate_manager_name' },
            { title: 'Буфер', value: 'dm_buffer_account_used' },
            { title: 'ID буфера', value: 'dm_buffer_account_id' },
            { title: 'Имя буфера', value: 'dm_buffer_account_name' },
            { title: 'Мастер-аккаунт', value: 'dm_master_account' },
            { title: 'Имя мастер-аккаунта', value: 'dm_master_account_name' },
        ],
    },
    {
        name: '',
        items: [
            { title: 'Партнер', value: 'dm_affiliate_name' },
            { title: 'ID партнера', value: 'dm_affiliate_id' },
            { title: 'Источник трафика', value: 'dm_affiliate_traffic_source_name' },
            { title: 'Способ выплаты', value: 'dm_affiliate_payment_method_name' },
            { title: 'Теги партнёров', value: 'dm_affiliate_tag' },
            { title: 'Вертикаль', value: 'dm_offer_vertical' },
        ],
    },
    {
        name: 'UTM-метки',
        isScrollable: true,
        items: [
            { title: 'UTM source', value: 'dm_affiliate_utm_source' },
            { title: 'UTM medium', value: 'dm_affiliate_utm_medium' },
            { title: 'UTM campaign', value: 'dm_affiliate_utm_campaign' },
            { title: 'UTM term', value: 'dm_affiliate_utm_term' },
            { title: 'UTM Content', value: 'dm_affiliate_utm_content' },
            { title: 'Gid', value: 'dm_affiliate_utm_gid' },
            { title: 'Aid', value: 'dm_affiliate_utm_aid' },
            { title: 'Gclid', value: 'dm_affiliate_utm_gclid' },
            { title: 'Creative', value: 'dm_affiliate_utm_creative' },
            { title: 'Placement', value: 'dm_affiliate_utm_placement' },
            { title: 'Rand', value: 'dm_affiliate_utm_rand' },
        ],
    },
    {
        name: 'Маркировка',
        isScrollable: true,
        items: [
            { title: 'Токен', value: 'dm_erir_token' },
            { title: 'ОРД', value: 'dm_erir_ord' },
            { title: 'Договор', value: 'dm_erir_legal' },
            { title: 'Используется', value: 'dm_erir_used' },
            { title: 'ID площадки', value: 'dm_erir_platform_id' },
            { title: 'URL площадки', value: 'dm_erir_platform_url' },
            { title: 'Название площадки', value: 'dm_erir_platform_name' },
            { title: 'Тип площадки', value: 'dm_erir_platform_type' },
            { title: 'Партнёр площадки', value: 'dm_erir_platform_affiliate_id' },
            { title: 'Площадка используется', value: 'dm_erir_platform_used' },
        ],
    },
    {
        name: 'Sub ID',
        isScrollable: true,
        items: [
            { title: 'Sub ID 1', value: 'dm_aff_sub1' },
            { title: 'Sub ID 2', value: 'dm_aff_sub2' },
            { title: 'Sub ID 3', value: 'dm_aff_sub3' },
            { title: 'Sub ID 4', value: 'dm_aff_sub4' },
            { title: 'Sub ID 5', value: 'dm_aff_sub5' },
            { title: 'Sub ID 6', value: 'dm_aff_sub6' },
            { title: 'Sub ID 7', value: 'dm_aff_sub7' },
            { title: 'Sub ID 8', value: 'dm_aff_sub8' },
            { title: 'Sub ID 9', value: 'dm_aff_sub9' },
            { title: 'Sub ID 10', value: 'dm_aff_sub10' },
            { title: 'Sub ID 11', value: 'dm_aff_sub11' },
            { title: 'Sub ID 12', value: 'dm_aff_sub12' },
            { title: 'Sub ID 13', value: 'dm_aff_sub13' },
            { title: 'Sub ID 14', value: 'dm_aff_sub14' },
            { title: 'Sub ID 15', value: 'dm_aff_sub15' },
            { title: 'Sub ID 16', value: 'dm_aff_sub16' },
            { title: 'Sub ID 17', value: 'dm_aff_sub17' },
            { title: 'Sub ID 18', value: 'dm_aff_sub18' },
            { title: 'Sub ID 19', value: 'dm_aff_sub19' },
            { title: 'Sub ID 20', value: 'dm_aff_sub20' },
            { title: 'Sub ID 21', value: 'dm_aff_sub21' },
            { title: 'Sub ID 22', value: 'dm_aff_sub22' },
            { title: 'Sub ID 23', value: 'dm_aff_sub23' },
            { title: 'Sub ID 24', value: 'dm_aff_sub24' },
            { title: 'Sub ID 25', value: 'dm_aff_sub25' },
        ],
    },
];

export const indicatorsExtendedReport: IColumnFilter[] = [
    {
        name: 'Клики',
        key: 'clicks',
        items: [
            { title: 'Всего', value: 'mt_clicks_total' },
            { title: 'Уникальные', value: 'mt_clicks_unique' },
        ],
        filter: ['mt_clicks'],
        isMultiselect: true,
    },
    {
        name: 'Лиды',
        key: 'leads',
        items: [
            { title: 'Всего', value: 'mt_leads_total' },
            { title: 'Принято', value: 'mt_leads_approved' },
            { title: 'В ожидании', value: 'mt_leads_pending' },
            { title: 'Отклонено', value: 'mt_leads_rejected' },
        ],
        filter: ['mt_leads'],
        isMultiselect: true,
    },
    {
        name: 'Метрики',
        key: 'metrics',
        items: [
            { title: 'CRu', value: 'mt_cr_u' },
            { title: 'CRt', value: 'mt_cr_t' },
            { title: 'AR', value: 'mt_ar' },
            { title: 'EPCu', value: 'mt_epc_u' },
            { title: 'EPCt', value: 'mt_epc_t' },
            { title: 'EPC Adv.', value: 'mt_epc_adv' },
            { title: 'EPL', value: 'mt_epl' },
        ],
        filter: ['mt_cr_u', 'mt_cr_t', 'mt_ep', 'mt_ar'],
        isMultiselect: true,
    },
    {
        name: 'Выплаты партнёрам',
        key: 'payout1',
        items: [
            { title: 'Принято', value: 'mt_payout_approved' },
            { title: 'В ожидании', value: 'mt_payout_pending' },
            { title: 'Отклонено', value: 'mt_payout_rejected' },
            { title: 'Выплаты за лид', value: 'mt_ppl' },
        ],
        filter: ['mt_payout', 'mt_ppl'],
        isMultiselect: true,
    },
    {
        name: 'Доход от рекламодателей',
        key: 'revenue',
        items: [
            { title: 'Принято', value: 'mt_revenue_approved' },
            { title: 'В ожидании', value: 'mt_revenue_pending' },
            { title: 'Отклонено', value: 'mt_revenue_rejected' },
            { title: 'Доход за лид', value: 'mt_rpl' },
        ],
        filter: ['mt_revenue', 'mt_rpl'],
        isMultiselect: true,
    },
    {
        name: 'Конкурс',
        key: 'fortune',
        items: [
            { title: 'Бонусы', value: 'mt_bonus_amount' },
            { title: 'Принято с бонусами', value: 'mt_bonus_payout_approved' },
            { title: 'EPCu с бонусами', value: 'mt_bonus_epc' },
            { title: 'EPCt с бонусами', value: 'mt_bonus_epc_t' },
            { title: 'EPL с бонусами', value: 'mt_bonus_epl' },
        ],
        filter: ['mt_bonus'],
        isMultiselect: true,
    },
    {
        name: 'Прибыль',
        key: 'profit',
        items: [{ title: 'Прибыль', value: 'mt_profit' }],
        filter: ['mt_profit'],
        colClass: 'profit-indicator',
    },
];

export const extendedAdvertiserFilterCols: IColumnFilter[] = [
    {
        name: '',
        items: [
            { title: 'Дата', value: 'dm_lead_date' },
            { title: 'Валюта оффера', value: 'dm_offer_currency' },
        ],
    },
    {
        name: '',
        items: [
            { title: 'ГЕО: Страна', value: 'geo_country' },
            { title: 'ГЕО: Город', value: 'geo_city' },
        ],
    },
    {
        name: '',
        items: [
            { title: 'Рекламодатель', value: 'dm_advertiser_name' },
            { title: 'ID рекламодателя', value: 'dm_advertiser_id' },
            { title: 'Менеджер рекламодателя', value: 'dm_advertiser_manager_name' },
            { title: 'Теги офферов', value: 'dm_offer_tag' },
        ],
    },
    {
        name: '',
        items: [
            { title: 'Оффер', value: 'dm_offer_name' },
            { title: 'ID Оффера', value: 'dm_offer_id' },
            { title: 'Цель', value: 'dm_offer_goal_name' },
            { title: 'ID Цели', value: 'dm_offer_goal_id' },
            { title: 'Страна оффера', value: 'dm_offer_country' },
            { title: 'ID лендинга', value: 'dm_offer_url_id' },
            { title: 'Название лендинга', value: 'dm_offer_url_name' },
        ],
    },
    {
        name: '',
        items: [
            { title: 'Менеджер партнера', value: 'dm_affiliate_manager_name' },
        ],
    },
    {
        name: '',
        items: [
            { title: 'Партнер', value: 'dm_buffer_account_name' },
            { title: 'ID партнера', value: 'dm_buffer_account_id' },
            { title: 'Теги партнёров', value: 'dm_affiliate_tag' },
            { title: 'Вертикаль', value: 'dm_offer_vertical' },
        ],
    },
    {
        name: 'Маркировка',
        isScrollable: true,
        items: [
            { title: 'Токен', value: 'dm_erir_token' },
            { title: 'ОРД', value: 'dm_erir_ord' },
            { title: 'Договор', value: 'dm_erir_legal' },
            { title: 'Используется', value: 'dm_erir_used' },
            { title: 'ID площадки', value: 'dm_erir_platform_id' },
            { title: 'URL площадки', value: 'dm_erir_platform_url' },
            { title: 'Название площадки', value: 'dm_erir_platform_name' },
            { title: 'Тип площадки', value: 'dm_erir_platform_type' },
            { title: 'Партнёр площадки', value: 'dm_erir_platform_affiliate_id' },
            { title: 'Площадка используется', value: 'dm_erir_platform_used' },
        ],
    },
    {
        name: 'Sub ID',
        isScrollable: true,
        items: [
            { title: 'Sub ID 1', value: 'dm_aff_sub1' },
            { title: 'Sub ID 2', value: 'dm_aff_sub2' },
            { title: 'Sub ID 3', value: 'dm_aff_sub3' },
            { title: 'Sub ID 4', value: 'dm_aff_sub4' },
            { title: 'Sub ID 5', value: 'dm_aff_sub5' },
            { title: 'Sub ID 6', value: 'dm_aff_sub6' },
            { title: 'Sub ID 7', value: 'dm_aff_sub7' },
            { title: 'Sub ID 8', value: 'dm_aff_sub8' },
            { title: 'Sub ID 9', value: 'dm_aff_sub9' },
            { title: 'Sub ID 10', value: 'dm_aff_sub10' },
            { title: 'Sub ID 11', value: 'dm_aff_sub11' },
            { title: 'Sub ID 12', value: 'dm_aff_sub12' },
            { title: 'Sub ID 13', value: 'dm_aff_sub13' },
            { title: 'Sub ID 14', value: 'dm_aff_sub14' },
            { title: 'Sub ID 15', value: 'dm_aff_sub15' },
            { title: 'Sub ID 16', value: 'dm_aff_sub16' },
            { title: 'Sub ID 17', value: 'dm_aff_sub17' },
            { title: 'Sub ID 18', value: 'dm_aff_sub18' },
            { title: 'Sub ID 19', value: 'dm_aff_sub19' },
            { title: 'Sub ID 20', value: 'dm_aff_sub20' },
            { title: 'Sub ID 21', value: 'dm_aff_sub21' },
            { title: 'Sub ID 22', value: 'dm_aff_sub22' },
            { title: 'Sub ID 23', value: 'dm_aff_sub23' },
            { title: 'Sub ID 24', value: 'dm_aff_sub24' },
            { title: 'Sub ID 25', value: 'dm_aff_sub25' },
        ],
    },
];

