
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import ReportLayout from '@/components/layout/ReportLayout.vue';
import { downloadExcelFile, showNotification, showServerError } from '@/utils';
import {
    postEmployeeExtendedReport,
    saveReportPresets,
    deleteReportPresets,
    patchReportPresets,
} from '@/api/stats';
import { IExtendedReport } from '@/api/types/stats';
import { TableHeader } from '@/types';
import { getDifferenceInDays } from '@/utils/formatDates';
import { eventBus } from '@/eventbus';
import ReportTable from '@/components/statistics/extended-report/ReportTable2.vue';
import StatisticReportMixin, { IExportFormat } from '@/mixins/statisticReport';
import {
    extendedBaseHeaders,
    columnsExtendedReport,
    ExtendedReportFilters,
} from "@/services/TablePage/extendedReport";
import ReportConstructor from "@/components/statistics/extended-report/ReportConstructor.vue";
import ReportFilters from "@/components/statistics/extended-report/ReportFilters.vue";
import StarOutlined from '@/assets/icons/star-outlined.svg';
import Pencil from '@/assets/icons/pencil.svg';
import Reload from '@/assets/icons/reload.svg';
import Check from '@/assets/icons/check.svg';
import BaseText from '@/components_v3/base/BaseText.vue';
import BaseTooltip from "@/components_v3/base/formComponents/BaseTooltip.vue";
import {
    preparePresetData,
    prepareQueryPresetData,
    findSimilarPreset,
    updateRouterFavoriteId,
    setWindowTitle,
} from "@/services/TablePage/reportPreset";

@Component({
    components: {
        ReportFilters,
        ReportConstructor,
        ReportLayout,
        ReportTable,
        StarOutlined,
        Pencil,
        Reload,
        Check,
        BaseText,
        BaseTooltip,
    },
})

export default class ExtendedReport extends StatisticReportMixin {
    @Mutation('SET_FILTERS_PARAMS_OBJECT') setFiltersParamsObject;
    @Mutation('DELETE_REPORT_PRESET') deleteReportPresetWithLS;
    @Mutation('SET_REPORT_PRESET') setReportPresetWithLS;
    @Mutation('UPDATE_REPORT_PRESET') updateReportPresetWithLS;
    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamsObject;
    @Getter('GET_REPORT_PRESETS') getReportPresets;
    @Getter('GET_ONE_REPORT_PRESET') getOneReportPreset;
    @Prop({ type: String, required: true }) readonly attachClass!: string;

    search = '';
    isByDateClick: 'conversion' | 'conversion-click' = 'conversion';
    headers: TableHeader[] = extendedBaseHeaders();
    filteredHeaders: TableHeader[] = [];
    filterClass = new ExtendedReportFilters();
    items = {} as IExtendedReport;
    pagination: { page: number } = {
        page: 1,
    };
    isNotFoundReport = false;
    currency = false;
    settings = {
        title: 'Расширенный отчет',
        loading: false,
        loadingExport: false,
        loadingUpdate: false,
        convertCurrency: '',
    };
    sortByFromUrl: string = '';
    isEdit: boolean = false;
    isConfirmDelete: boolean = false;
    isFavorite: boolean = false;
    isDisabledFavoriteBtn: boolean = true;

    get tooltipText(): string {
        return this.isFavorite && !this.isConfirmDelete ?
            'Удалить отчёт' : this.isFavorite && this.isConfirmDelete ?
                'Подтвердить удаление?' : 'Сохранить отчет?';
    }

    get isShowLoader(): boolean {
        return Object.keys(this.items).length >= 0 && this.items.report && this.items.report.length > 0;
    }

    get isDisabledSaveButton(): boolean {
        return window.document.title === this.settings.title;
    }

    get isAdvertiserReport(): boolean {
        return this.$route.path === '/statistics/extended-report/advertiser';
    }

    submitReportConstructor(): void {
        this.getReports();
        this.isDisabledFavoriteBtn = false;
        if (this.isAdvertiserReport) {
            this.setTitle();
            return;
        }
        this.newPreset();
        this.goToSimilarPreset();
    }

    editPreset(): void {
        if (this.isFavorite) {
            this.isConfirmDelete = true;
            return;
        }

        this.settings.title = `Мой отчет ${this.getReportPresets.length + 1}`;
        this.isEdit = true;
        this.isFavorite = true;
    }

    cancelEditPreset(): void {
        if (this.$router.currentRoute.params.favorite_id === 'nf') {
            this.isFavorite = false;
        }

        this.settings.title = window.document.title;
        this.isEdit = false;
        this.isConfirmDelete = false;
    }

    newPreset(): void {
        this.setTitle();
        this.isFavorite = false;
        this.isEdit = false;

        if (this.$router.currentRoute.params.favorite_id !== 'nf') {
            updateRouterFavoriteId();
        }
    }

    setTitle(): void {
        const title = this.isAdvertiserReport ? 'Отчет по рекламодателям' : 'Расширенный отчет';
        this.settings.title = title;
        setWindowTitle(title);
    }

    async submitPreset(): Promise<void> {
        if (this.goToSimilarPreset()) return;

        if (this.settings.title !== window.document.title &&
            this.$router.currentRoute.params.favorite_id !== 'nf') {
            await this.renamePreset();
            return;
        }

        if (this.$router.currentRoute.params.favorite_id === 'nf') {
            await this.savePreset();
            return;
        }
    }

    goToSimilarPreset(): boolean {
        const data = preparePresetData(this.settings.title, this.filterClass.filters);
        const findedPreset = findSimilarPreset(data);

        if (findedPreset.length >= 1) {
            this.updateQueryString(prepareQueryPresetData(findedPreset)[0].query);
            updateRouterFavoriteId(String(findedPreset[0].id));
            setWindowTitle(findedPreset[0].name);
            this.settings.title = findedPreset[0].name;
            this.isFavorite = true;
            this.isEdit = false;

            return true;
        }

        return false;
    }

    async renamePreset(): Promise<void> {
        try {
            const { id, query } = this.getOneReportPreset(this.$router.currentRoute.params.favorite_id);

            await patchReportPresets(id, { name: this.settings.title, query });
            setWindowTitle(this.settings.title);
            this.deleteReportPresetWithLS(id);
            this.setReportPresetWithLS({
                id: id,
                name: this.settings.title,
                query: query,
            });

            this.isEdit = false;
        } catch (e) {
            showServerError(e, 'Не удалось переименовать');
        }
    }

    async savePreset(): Promise<void> {
        try {
            const data = preparePresetData(this.settings.title, this.filterClass.filters);
            const savedPresetRespId = await saveReportPresets(data);
            updateRouterFavoriteId(String(savedPresetRespId.data.id));
            this.setReportPresetWithLS({
                id: String(savedPresetRespId.data.id),
                name: this.settings.title,
                query: data.query,
            });
            setWindowTitle(this.settings.title);

            this.isFavorite = true;
            this.isConfirmDelete = false;
            this.isEdit = false;

            showNotification("Отчёт сохранён");
        } catch (e) {
            showServerError(e, 'Не удалось сохранить данный отчёт');
        }
    }

    async deletePreset(): Promise<void> {
        try {
            if (this.$router.currentRoute.params.favorite_id !== 'nf') {
                await deleteReportPresets(this.$router.currentRoute.params.favorite_id);
                this.deleteReportPresetWithLS(this.$router.currentRoute.params.favorite_id);
                updateRouterFavoriteId();

                this.isDisabledFavoriteBtn = false;
                this.isFavorite = false;
                this.isConfirmDelete = false;
                this.isEdit = false;
                this.setTitle();
            }
        } catch (e) {
            showServerError(e, 'Не удалось удалить данный шаблон');
        }
    }

    searchTable(search: string): void {
        this.search = search;
    }

    updateTableHeaders(): void {
        this.filteredHeaders = [];
        if (this.getFiltersParamsObject?.columns) {
            this.headers?.forEach((header) => {
                if (this.getFiltersParamsObject.columns.includes(header.value) && header.value !== 'dm_lead_date') {
                    return this.filteredHeaders.push(header);
                }
                if (this.getFiltersParamsObject?.group === header.value) {
                    this.filteredHeaders.push(header);
                }
            });
        }
    }

    // изменение фильтров и их отправка с датапикера над таблицей который
    changeFilters(e: any): void {
        this.changeFiltersFromLayout(this.filterClass.filters, e);
        if (e) this.getReports();
        if (this.isAdvertiserReport) {
            this.setTitle();
            return;
        }
        this.newPreset();
        this.isDisabledFavoriteBtn = false;
        this.cancelEditPreset();
        this.goToSimilarPreset();
    }

    concatFiltersForSend(): any {
        const columns = [...this.getFiltersParamsObject.columns];

        const isNeedAffiliateId = columns.includes('dm_affiliate_name') && !columns.includes('dm_affiliate_id');
        if (isNeedAffiliateId) columns.push('dm_affiliate_id');
        const isNeedOfferId = columns.includes('dm_offer_name') && !columns.includes('dm_offer_id');
        if (isNeedOfferId) columns.push('dm_offer_id');

        const offerCols = ['dm_offer_goal_id', 'dm_offer_goal_name', 'dm_offer_name', 'dm_offer_id'];
        const needOfferStatus = offerCols.some(i => columns.includes(i));
        if (needOfferStatus) columns.push('dm_offer_status');

        if (this.getFiltersParamsObject.group && this.getFiltersParamsObject.group !== 'without_lead_date') {
            columns.splice(columns.findIndex((col) => col.includes('dm_lead_date')), 1);
            columns.push(this.getFiltersParamsObject.group);
        }
        const data: any = {
            columns,
            filters: this.filterClass.concatFiltersForSend(this.getFiltersParamsObject),
        };

        if (this.sortByFromUrl) {
            data.sorting = this.getSortBy()
                .map((column) => {
                    if (column === 'without_lead_date') return;
                    return {
                        column,
                        direction: this.$route.query?.sort_desc!.toString() === 'true' ? 'desc' : 'asc',
                    };
                })
                .filter(Boolean) as { column: string; direction: string }[];
        }

        return data;
    }

    created(): void {
        if (this.isAdvertiserReport) this.settings.title = "Отчет по рекламодателям";

        if (!this.$route.query.sort_by || !this.$route.query.hasOwnProperty('sort_desc')) {
            const query = { ...this.$route.query };

            if (!this.$route.query.sort_by && this.getSortBy().length) {
                query.sort_by = this.getSortBy()[0];
            }
            if (!this.$route.query.hasOwnProperty('sort_desc')) {
                query.sort_desc = 'true';
            }

            this.$router.replace({
                name: this.$route.name || '',
                query,
            });
        }

        if (this.$route.query.hasOwnProperty('convert_currency')) {
            this.settings.convertCurrency = this.$route.query.convert_currency as string;
        }
        if (this.$route.query.date_click === 'conversion' || this.$route.query.date_click === 'conversion-click') {
            this.isByDateClick = this.$route.query.date_click;
        } else {
            this.updateQueryString({ date_click: this.isByDateClick });
        }
        this.filterClass.addSubsToFilters();
    }

    mounted(): void {
        if (Object.keys(this.$route.query).filter(item => !['columns', 'offset', 'group'].includes(item)).length > 0) {
            if (!!this.settings.convertCurrency) {
                this.updateQueryString({ convert_currency: this.settings.convertCurrency });
            }
            this.getReports();
        }

        const preset = this.getOneReportPreset(this.$router.currentRoute.params.favorite_id);
        if (preset !== undefined) {
            this.settings.title = preset.name;
            this.isFavorite = true;
            this.isDisabledFavoriteBtn = false;
            setWindowTitle(preset.name);

            const { date_from, date_to } = this.$router.currentRoute.query;
            this.filterClass.filters[0].select = [date_from, date_to];
        } else {
            this.setTitle();
        }
    }

    findColumnInGetFiltersParams(columns: string[]): boolean {
        if (!this.getFiltersParamsObject.columns) return false;
        return columns.some((n) => this.getFiltersParamsObject.columns?.indexOf(n) !== -1 || false);
    }

    // ограничения по выбору дат в фильтре Период
    getAllowedDate(): { start: Date | null, end: Date | null } {
        const filtersAndStartLimit = this.filterClass.getAllowedDate();
        const end = new Date();
        const checkedFilters = filtersAndStartLimit.filter(f => this.findColumnInGetFiltersParams(f.filters));
        if (checkedFilters.length > 1) {
            checkedFilters.forEach((f: any) => f.diff = getDifferenceInDays(f.start, end));
            const diffs = checkedFilters.map((f: any) => f.diff);
            const minDiff = Math.min.apply(null, diffs);
            const startDate = checkedFilters.find((f: any) => f.diff === minDiff)?.start;
            return { start: startDate || null, end };
        } else if (checkedFilters.length === 1) {
            const startDate = checkedFilters[0].start;
            return { start: startDate || null, end };
        } else {
            return { start: null, end };
        }
    }

    async exportReport(item: IExportFormat): Promise<any> {
        this.settings.loadingExport = true;
        const data = this.concatFiltersForSend();
        const params = {} as { rename?: boolean };
        if (this.isAdvertiserReport) {
            params.rename = true;
        }
        try {
            // @ts-ignore
            const { headers } = item;
            const responseType = item.responseType;

            headers.lang = 'ru';
            headers['conversions-source-type'] = this.isByDateClick;
            headers['usernames-as-string'] = 'true';
            headers['convert-currency'] = this.settings.convertCurrency || undefined;
            const report = await postEmployeeExtendedReport({ data, headers, responseType, params });

            let file = report;
            if (item.format === 'csv') {
                file = this.translateReportHeader(report, columnsExtendedReport);
            }

            downloadExcelFile(file, 'Расширенный отчет', item.format);
        } catch (err) {
            showServerError(err, 'Расширенный отчет не загружен');
        }
        this.settings.loadingExport = false;
    }

    changeCurConversion(): void {
        this.updateQueryString({ convert_currency: this.settings.convertCurrency });
        this.getReports();
        if (this.isAdvertiserReport) {
            this.setTitle();
            return;
        }
        this.newPreset();
        this.isDisabledFavoriteBtn = false;
        this.cancelEditPreset();
        this.goToSimilarPreset();
    }

    changeDateClick(): void {
        this.updateQueryString({ date_click: this.isByDateClick });
        this.getReports();
        if (this.isAdvertiserReport) {
            this.setTitle();
            return;
        }
        this.newPreset();
        this.isDisabledFavoriteBtn = false;
        this.cancelEditPreset();
        this.goToSimilarPreset();
    }

    async getReports(): Promise<void> {
        this.settings.loading = true;
        const data = this.concatFiltersForSend();
        const headers: {
            'conversions-source-type': 'conversion' | 'conversion-click',
            'convert-currency'?: string,
            'usernames-as-string': string
        } = {
            'conversions-source-type': this.isByDateClick,
            'convert-currency': this.settings.convertCurrency || undefined,
            'usernames-as-string': 'true',
        };
        const excluded = this.filterClass.getFilterExcluded();
        const operators = this.filterClass.getMetricOperatorsForQuery();
        this.updateQueryString({ ...{ excluded }, ...operators, ...{ date_click: this.isByDateClick } });
        this.updateTableHeaders();

        try {
            const report = await postEmployeeExtendedReport({ data, headers });
            this.items = report?.data || [];
            this.isNotFoundReport = this.items.report?.length <= 0;
            this.filteredHeaders = this.calculateColumnWidths(report);
        } catch (err) {
            showServerError(err, 'Расширенный отчет не загружен');
        } finally {
            this.settings.loading = false;
            this.settings.loadingUpdate = false;
        }
    }

    calculateColumnWidths( report ):any {
        return this.filteredHeaders.map(header => {
            const key = header.value;

            // key === 'dm_offer_name'
            // maxLength = 22;

            const summaryValues = report.data.summary.map(item => item[key]);
            const reportValues = report.data.report.map(item => item[key]);

            const allValues = [...summaryValues, ...reportValues]
                .filter(value => value !== null && value !== undefined)
                .map(value => String(value));

            const maxLength = (allValues.reduce((max, value) => Math.max(max, value.length), 0)) + 4;

            let width: number | string;
            if(maxLength < 6) {
                width = 80;
            }else{
                width = (Math.max(-0.66 + 8.63 * maxLength + 10, 80)).toFixed(0);
            }

            return {
                ...header,
                width: width + 'px',
            };
        });
    }

    clearConstructorFilters(): void {
        this.clearFilters(this.filterClass.filters);
        this.updateQueryString({});
        this.clearTableData();
        const params = {
            columns: this.getFiltersParamObject.columns,
            date_to: this.getFiltersParamObject.date_to,
            date_from: this.getFiltersParamObject.date_from,
            group: this.getFiltersParamObject.group,
        };
        this.setFiltersParamsObject(params);
        this.filterClass.clearExcluded(this.getFiltersParamObject);
    }

    clearTableData(): void {
        this.items = {} as IExtendedReport;
        this.isNotFoundReport = false;
    }

    @Watch('getFiltersParamsObject', { deep: true, immediate: true })
    clearDate(): void {
        // сбрасываем до текущей даты в фильтре по дате при добавлении группировок гео, метрик или Sub1-Sub10
        const cols = ['dm_aff_sub1', 'dm_aff_sub2', 'dm_aff_sub3', 'dm_aff_sub4', 'dm_aff_sub5', 'dm_aff_sub6', 'dm_aff_sub7', 'dm_aff_sub8', 'dm_aff_sub9', 'dm_aff_sub10', 'dm_geo_country', 'dm_geo_city', 'dm_ua_device', 'dm_ua_os_name', 'dm_ua_browser_name', 'mt_clicks_total', 'mt_clicks_unique', 'mt_cr_u', 'mt_cr_t', 'mt_epc_u', 'mt_epc_t', 'mt_epc_adv', 'dm_buffer_account_used', 'dm_buffer_account_id', 'dm_buffer_account_name'];
        const isNotSupportedDate = this.getAllowedDate().start === null || new Date(this.getAllowedDate().start!) > new Date(this.filterClass.filters[0].select[0]);
        if (this.findColumnInGetFiltersParams(cols) && isNotSupportedDate) {
            this.filterClass.filters[0].select = this.filterClass.filters[0].defaultValue;
            this.filterClass.filters[0].allowedDates = this.getAllowedDate();
        } else {
            this.filterClass.filters[0].allowedDates = () => this.getAllowedDate();
            eventBus.$emit('check-allowed-dates', this.getAllowedDate());
        }
    }

    @Watch('$route.query.sort_by', { immediate: true })
    setSortByQuery(value: string | string[]): void {
        if (value === 'undefined') {
            this.sortByFromUrl = '';
        } else {
            this.sortByFromUrl = Array.isArray(value) ? value[0] : value;
        }
    }

    getSortBy(): string[] {
        const columns = this.getFiltersParamsObject?.columns;

        if (!Array.isArray(columns)) return [];

        // если есть такой столбец, то ставим сортировка из урла
        if (columns.includes(this.sortByFromUrl) && this.sortByFromUrl !== 'dm_lead_date') return [this.sortByFromUrl];

        // иначе ставим дефолтную сортировку по дате или кликам
        if (columns.includes('dm_lead_date')) {
            return this.getFiltersParamsObject.group && [this.getFiltersParamsObject.group] || ['dm_lead_date'];
        }
        if (columns.includes('mt_clicks_total')) {
            return ['mt_clicks_total'];
        }

        return [];
    }
}
