import {
    IItemsFromOffers, IMonetaryGoalData,
    IOffer, IOfferAdditionalUrl,
    IOfferBaseFields,
    IOfferGoal,
    IOfferMetric,
    IOfferMetricByPeriod,
    ITag, ITracking,
} from "@/api/types/offers";
import store from "@/store";
import { ICountry } from "@/api/types/catalogue";

export class Offer implements IOffer {
    id?: number;
    legacy_id?: number;
    name: string = '';
    logo: string | null = null;
    logo_toprec: string | null = null;
    private: number = 0;
    default_url: string = '';
    preview_url: string | null = null;
    deep_links: boolean = false;
    tracking_domains?: {
        domain: string;
        ssl_enabled: boolean;
    }[];
    tracking_protocol?: ITracking;
    additional_urls?: IOfferAdditionalUrl[];
    countries: string[] | [] = [];
    goals: IOfferGoal[] | [] = [];
    departments: IItemsFromOffers[] | [] = [];
    traffics: IItemsFromOffers[] | [] = [];
    products: IItemsFromOffers[] | [] = [];
    payouts: IItemsFromOffers[] | [] = [];
    target_actions: IItemsFromOffers[] | [] = [];
    verticals: IItemsFromOffers[] | [] = [];
    stats: IItemsFromOffers[] | [] = [];
    manager?: IOfferBaseFields;
    contests: {
        id: number;
        name: string;
        level: number;
    }[] | [] = [];
    affiliate_status: string | null = null;
    campaigns?: {id: number, name: string}[] | [] = [];
    status: string = '';
    advertiser?: {
        id: number;
        is_checker_used: boolean;
        legacy_id: number;
        name: string;
    };
    confirmations?: {
        requires_advertiser_approval: boolean;
        requires_approval: boolean;
    };
    ready_for_erir: number = 0;
    expiration_date: string | null = null;
    tags!: ITag[];
    metrics?: IOfferMetric;
    metrics_detailed?: IOfferMetricByPeriod;

    constructor(offer: IOffer) {
        if (offer !== undefined) {
            this.id = offer.id;
            this.legacy_id = offer.legacy_id;
            this.name = offer.name;
            this.logo = offer.logo;
            this.logo_toprec = offer.logo_toprec;
            this.private = offer.private;
            this.default_url = offer.default_url;
            this.deep_links = offer.deep_links || false;
            this.tracking_domains = offer.tracking_domains;
            this.additional_urls = offer.additional_urls;
            this.countries = this.getFlags(offer.countries);
            this.goals = this.setGoals(offer.goals);
            this.departments = offer.departments;
            this.traffics = offer.traffics;
            this.products = offer.products;
            this.payouts = offer.payouts;
            this.target_actions = offer.target_actions;
            this.verticals = offer.verticals;
            this.stats = offer.stats;
            this.manager = offer.manager;
            this.contests = offer.contests;
            this.affiliate_status = offer.affiliate_status || '';
            this.campaigns = offer.campaigns;
            this.status = offer.status;
            this.advertiser = offer.advertiser;
            this.confirmations = offer.confirmations;
            this.ready_for_erir = offer.ready_for_erir;
            this.expiration_date = offer.expiration_date;
            this.tags = offer.tags;
            this.preview_url = offer.preview_url;
            this.metrics_detailed = offer.metrics_detailed;
            this.setMetrics();
        }
    }

    getCategoryValues(key: string): string {
        return this[key].map((i: { name: string; }) => i.name).join(', ');
    }

    get getPayoutAndRevenueValue(): { payout: string, revenue: string } {
        const activeGoals: IOfferGoal[] = this.goals!.filter((goal: IOfferGoal) => goal.active);
        if (activeGoals.length === 0) return { payout: '-', revenue: '-' };

        const getMinMax = (key: 'revenue' | 'payout'): string => {
            const monetaryGoalData = activeGoals.map((goal: IOfferGoal) => goal[key]!);
            let goals: IMonetaryGoalData[] = [];
            let currency = '';

            const fixedTypeGoals = monetaryGoalData.filter((goal) => goal.type === 'fixed');
            if (fixedTypeGoals.length > 0) {
                goals = fixedTypeGoals;
                currency = fixedTypeGoals[0].currency;
            } else {
                const percentTypeGoals = monetaryGoalData.filter((goal) => goal.type === 'percent');
                if (percentTypeGoals.length > 0) {
                    goals = percentTypeGoals;
                    currency = '%';
                }
            }

            const amounts = goals.map((goal) => goal.amount);
            const nonZeroAmounts = amounts.filter((amount: number) => amount !== 0);
            const min = nonZeroAmounts.length > 0 ? Math.min(...nonZeroAmounts) : 0;
            const max = Math.max(...amounts);

            if (amounts.length > 1 && min !== max) {
                return `от ${min} до ${max} ${currency}`;
            } else {
                return  !!goals[0] ? `${max} ${currency}` : '';
            }
        };

        const payout = getMinMax('payout');
        const revenue = getMinMax('revenue');

        return { payout, revenue };
    }

    getFlags(countries: string[]): any[] {
        return countries.map(code => {
            const country: ICountry = store.getters['catalogueModule/GET_CURRENT_COUNTRY'](code);
            return { code, name: country?.name!, flag: country?.flag! };
        });
    }

    setGoals(goals: IOfferGoal[]): IOfferGoal[] {
        return goals.map(goal => {
            if (goal.payout) goal.payout.amount = parseFloat(String(goal.payout.amount));
            if (goal.revenue) goal.revenue.amount = parseFloat(String(goal.revenue.amount));
            return goal;
        });
    }

    setMetrics(): void {
        const statPeriod = store.state['offersModule'].statPeriodForList;
        if (!this.metrics_detailed) return;
        this.metrics = this.metrics_detailed![`days${statPeriod}`];
    }

    get isDisableChart(): boolean {
        if (!this.metrics) return false;
        return Object.values(this.metrics!).every(m => m === 0);
    }
}
