
import { Component, Vue } from 'vue-property-decorator';
import ExtendedReport from "@/components/statistics/extended-report/ExtendedReport2.vue";
import { Getter, Mutation } from "vuex-class";
import { eventBus } from "@/eventbus";

@Component({
    components: {
        ExtendedReport,
    },
})
export default class ExtendedReportPage extends Vue {
    @Mutation('SET_DEFAULT_FILTERS_PARAMS_OBJECT') setDefaultFiltersParams;
    @Mutation('SET_FILTERS_PARAMS_OBJECT') setFiltersParamsObject;
    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamsObject;

    componentKey = 0;

    created(): void {
        this.clearFilters();
        eventBus.$on('click-nav-drawer', this.changeExtendReport);
    }

    clearFilters(): void {
        this.setDefaultFiltersParams({});
        this.setFiltersParamsObject({});
        if (this.$route.query.hasOwnProperty('columns') ) {
            const filters = { ...this.getFiltersParamsObject, columns : this.$route.query.columns  as string[] };
            this.setFiltersParamsObject(filters);
        }
    }

    changeExtendReport(): void {
        this.clearFilters();
        this.componentKey += 1;
    }
    beforeDestroy(): void {
        eventBus.$off('click-nav-drawer', this.changeExtendReport);
    }
}
