
import { Component, Mixins, PropSync, Ref, Watch } from 'vue-property-decorator';
import {
    extendedAdvertiserFilterCols,
    extendedFilterCols,
    ExtendedReportFilters,
    indicatorsExtendedReport,
    REPORT_FILTERS_TABS,
} from "@/services/TablePage/extendedReport";
import PageTabs from "@/components/base/PageTabs.vue";
import FiltersDesign from "@/components/base/filters/FiltersDesign.vue";
import TrashBtn from "@/components/base/buttons/TrashBtn.vue";
import { IFilter } from "@/types";
import { Getter, Mutation } from "vuex-class";
import { FilterDateParser } from "@/services/filters/dateParser";
import {
    getEmployeesCampaigns,
    getEmployeesContests,
    getEmployeesCountries,
    getEmployeesDepartments,
    getEmployeesOfferVerticals,
    getEmployeesPayouts,
    getEmployeesProducts,
    getEmployeesStats,
    getEmployeesTargetActions,
    getEmployeesTraffics, getOfferTags,
} from "@/api/offers";
import { getAffiliateTags, getTrafficSource } from "@/api/user";
import { getEmployeeMethods } from "@/api/payout";
import { showServerError } from "@/utils";
import { eventBus } from "@/eventbus";
import ColumnsCheckboxes from "@/components/statistics/ColumnsCheckboxes.vue";
import ColumnsSubs from "@/mixins/columnsSubs";
import FilterSubIds from "@/components/statistics/extended-report/FilterSubIds.vue";

@Component({
    components: { FilterSubIds, ColumnsCheckboxes, TrashBtn, FiltersDesign, PageTabs },
})
export default class ReportFilters extends Mixins(ColumnsSubs) {
    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamsObject;
    @Mutation('SET_FILTERS_PARAMS_OBJECT') setFiltersParamsObject;

    @PropSync('filters', { required: true }) filtersSync!: ExtendedReportFilters;
    // для доступа из reportConstructor
    @Ref('filters-design') filtersDesign!: FiltersDesign;

    REPORT_FILTERS_TABS = REPORT_FILTERS_TABS;
    financeCols = ['mt_epc_u', 'mt_epc_t', 'mt_epc_adv', 'mt_epl', 'mt_payout_total', 'mt_payout_approved', 'mt_payout_pending', 'mt_payout_rejected', 'mt_ppl', 'mt_revenue_total', 'mt_revenue_approved', 'mt_revenue_pending', 'mt_revenue_rejected', 'mt_rpl', 'mt_profit'];
    columnTitles = ['Дата', 'Клики', 'Лиды', 'Показатели', 'Выплата', 'Доход', 'Конкурс', 'Прибыль', 'Sub ID'];
    tab = REPORT_FILTERS_TABS.MAIN;

    filterCols = extendedFilterCols;
    indicatorCols = indicatorsExtendedReport;
    selected: string[] = [];

    loadingDataForSelect = [
        { id: 'contest', errorName: 'Список конкурсов не загружен', api: getEmployeesContests },
        { id: 'campaign', errorName: 'Список акций не загружен', api: getEmployeesCampaigns },
        { id: 'tag', errorName: 'Список тегов не загружен', api: getOfferTags },
        { id: 'affiliate_tag', errorName: 'Список тегов не загружен', api: getAffiliateTags },
        { id: 'department', errorName: 'Список направлений не загружен', api: getEmployeesDepartments },
        { id: 'payout', errorName: 'Список типов выплат не загружен', api: getEmployeesPayouts },
        { id: 'product', errorName: 'Список типов продуктов не загружен', api: getEmployeesProducts },
        { id: 'stats', errorName: 'Список типов статистики не загружен', api: getEmployeesStats },
        { id: 'traffic', errorName: 'Список типов трафика не загружен', api: getEmployeesTraffics },
        { id: 'vertical', errorName: 'Список вертикалей не загружен', api: getEmployeesOfferVerticals },
        { id: 'country', errorName: 'Список стран не загружен', api: getEmployeesCountries },
        { id: 'target_action', errorName: 'Список целевых действий не загружен', api: getEmployeesTargetActions },
        { id: 'affiliate_traffic_source_id', errorName: 'Список источников трафика не загружен', api: getTrafficSource },
        { id: 'affiliate_payment_method_id', errorName: 'Список способов выплат не загружен', api: getEmployeeMethods },
    ];

    get isDesktop(): boolean {
        return this.$vuetify.breakpoint.width > 768;
    }

    get getTabs(): any {
        const tabs = [
            { tab: 'Основные', count: this.getCountByTab(REPORT_FILTERS_TABS.MAIN) },
            { tab: 'По метрикам', count: this.getCountByTab(REPORT_FILTERS_TABS.METRICS) },
            { tab: 'По регистрациям', count: this.getCountByTab(REPORT_FILTERS_TABS.REGISTER) },
            { tab: 'По маркировке', count: this.getCountByTab(REPORT_FILTERS_TABS.MARKING) },
        ];
        const colsTabs = [
            { tab: 'Группировка', hidden: this.isDesktop },
            { tab: 'Показатели', hidden: this.isDesktop },
        ];
        return [...colsTabs, ...tabs];
    }

    getCountByTab(tab: REPORT_FILTERS_TABS): number {
        const tabFilters = this.filtersSync.filters.filter(f => f.tab === tab);
        const notEmptyFilters = tabFilters.filter(f => {
            return Array.isArray(f.select) ? f.select.length > 0 : f.select !== '';
        });
        return notEmptyFilters.length;
    }

    created(): void {
        this.tab = this.isDesktop ? REPORT_FILTERS_TABS.MAIN : REPORT_FILTERS_TABS.GROUPING;
        this.prepareFilters();

        // показатели и группировки(первичные значения) берем из фильтров из свойства columns, которые туда положили в хуке created на странице отчетов
        if (this.getFiltersParamsObject.hasOwnProperty('columns')) {
            this.selected = [...this.getFiltersParamsObject.columns];
        }
        eventBus.$on('clear-checkboxes', this.clearSelected);
        // при смене группировки на и без группировки убираем чекбокс у даты
        eventBus.$on('change-date', this.changeDate);
        // при переходе с navbar обновляем чекбоксы в соответствии с query параметрами, передоенными с navbar
        eventBus.$on('update-selected', this.updateSelected);
        if (this.$route.path === '/statistics/extended-report/advertiser') {
            this.filterCols = extendedAdvertiserFilterCols;
        }
    }

    mounted(): void {
        // если урл содержит sub_id -ки с пустым значением, переводим соответсвующий свитч в положение тру(пустой)
        for (let i = 0; i < 25; ++i) {
            if (this.$route.query.hasOwnProperty(`sub${i}`) && this.$route.query[`sub${i}`][0] === '') {
                this.filtersSync.filters.find(f => f.id === `sub${i}`)!.isSubmitEmptySelect = true;
            }
        }
    }

    submitFilters(): void {
        eventBus.$emit('go-to-first-page');
        this.$emit('submit');
    }

    prepareFilters(): void {
        this.setExcludedToFilters();
        const activeFilters: object[] = [];
        let param;
        this.filtersSync.filters.forEach((filter) => {
            if (filter.select && filter.select!.length > 0 && !filter.datepicker) {
                activeFilters.push({
                    [filter.id]: filter.select,
                });
            } else if (filter.datepicker && filter.id === 'date') {
                param = new FilterDateParser({ [filter.id]: filter.select })!.parse(filter);
            }
        });
        const paramsObj = { ...Object.assign({}, ...activeFilters), ...param };
        const params = { ...this.getFiltersParamsObject, ...paramsObj };
        this.setFiltersParamsObject(params);
        // получаем данные для селектов в фильтрах
        for (const select of this.loadingDataForSelect) {
            this.getDataForSelect(select.api, select.id, select.errorName);
        }
    }

    setExcludedToFilters(): void {
        if (!this.$route.query.hasOwnProperty(`excluded`)) return;

        if (Array.isArray(this.$route.query.excluded)) {
            this.$route.query.excluded.forEach(route => {
                this.filtersSync.filters.forEach(f => {
                    if (f.id === route) {
                        f.isExcluded = true;
                    }
                });
            });
        } else {
            this.filtersSync.filters.find(f => f.id === this.$route.query.excluded)!.isExcluded = true;
        }
    }

    private async getDataForSelect(apiMethod: any, filterId: string, errorName: string = 'Список не загружен'): Promise<void> {
        const filter = this.filtersSync.filters.find(i => i.id === filterId);
        if (!filter) {
            return;
        }
        try {
            const items = await apiMethod();
            filter.items = items.data || items;
        } catch (err) {
            showServerError(err, errorName);
        }
    }

    changeTab(tab: number): void {
        this.tab = tab;
    }

    changeSwitch(item: IFilter): void {
        if (item.isSubmitEmptySelect) {
            item.select = [];
        }
    }

    clearSelected(): void {
        this.selected = [];
    }

    updateSelected(columns: string[]): void {
        this.selected = [...columns];
    }

    changeDate(filter: IFilter): void {
        if (filter.select === 'without_lead_date') {
            const itemIndex = this.selected.findIndex(i => i === 'dm_lead_date');
            if (itemIndex !== -1) {
                this.selected.splice(itemIndex!, 1);
            }
        } else {
            if (!this.selected.includes('dm_lead_date')) {
                this.selected.push('dm_lead_date');
            }
        }
    }

    enableGroupTemplate(value: boolean): void {
        const groupDate = this.filtersSync.filters.find(i => i.id === 'group');
        if (groupDate) {
            if (value) {
                groupDate.select = groupDate.select === 'without_lead_date' ? 'dm_lead_date_hour' : groupDate.select;
            } else {
                groupDate.select = 'without_lead_date';
            }
        }
    }

    @Watch('selected')
    currencyValue(): void {
        const currencyCol = this.filterCols[0].items?.find(i => i.value === 'dm_offer_currency');
        const isFinanceCol = this.financeCols.some((n) => {
            return this.selected.indexOf(n) !== -1;
        });
        if (currencyCol) {
            if (isFinanceCol) {
                if (!this.selected.includes('dm_offer_currency')) {
                    this.selected.push('dm_offer_currency');
                }
                this.$set(currencyCol, 'disabled', true);
            } else {
                this.$set(currencyCol, 'disabled', false);
            }
            const filters = { ...this.getFiltersParamsObject, ...{ columns: [...this.selected] } };
            this.setFiltersParamsObject(filters);
        }
        const dateCol = this.filterCols[0].items?.find(i => i.value === 'dm_lead_date');
        const isCheckDate = this.selected.includes('dm_lead_date');
        if (dateCol) {
            this.$set(dateCol, 'disabled', isCheckDate);
        }
        this.enableGroupTemplate(isCheckDate);
    }

    @Watch('selected')
    autoSelectCheckboxes(): void {
        const checkboxes = [
            {
                marker: 'dm_buffer_account_name',
                target: 'dm_buffer_account_id',
                index: 4,
            },
            {
                marker: 'dm_master_account_name',
                target: 'dm_master_account',
                index: 4,
            },
        ];

        checkboxes.forEach(pair => {
            const linkToTarget = this.filterCols[pair.index].items?.find(i => i.value === pair.target);
            if (linkToTarget) {
                const isMarker = this.selected.includes(pair.marker);
                const isTarget = this.selected.includes(pair.target);
                if (isMarker && !isTarget) {
                    this.selected.push(pair.target);
                    this.$set(linkToTarget, 'disabled', true);
                } else if (isMarker && isTarget) {
                    this.$set(linkToTarget, 'disabled', true);
                } else if (!isMarker && isTarget) {
                    this.$set(linkToTarget, 'disabled', false);
                }
            }
        });
    }

    beforeDestroy(): void {
        eventBus.$off('clear-checkboxes', this.clearSelected);
        eventBus.$off('change-date', this.changeDate);
        eventBus.$off('update-selected', this.updateSelected);
    }
}
