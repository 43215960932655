import RouteCell from "@/components_v3/base/table/cells/RouteCell.vue";
import NumberCell from "@/components_v3/base/table/cells/NumberCell.vue";
import ViewDateTime from "@/components/base/ViewDateTime.vue";
import { IStatus } from "@/types";
import StatusCell from "@/components_v3/base/table/cells/StatusCell.vue";

export interface ITableItem {
    value: any;
    component: any;
    componentData: any;
}

export enum CELL_TYPE {
    ROUTE = 'route',
    NUMBER = 'number',
    TIME = 'time',
    STATUS = 'status'
}

export class TableCell {
    public static create(type: CELL_TYPE, data: any, key: string, item: any): ITableItem {
        switch (type) {
        case CELL_TYPE.ROUTE:
            return new RouteTableCell(data, key, item).getCell();
        case CELL_TYPE.NUMBER:
            return new NumberTableCell(data, key, item).getCell();
        case CELL_TYPE.TIME:
            return new TimeTableCell(data, key, item).getCell();
        case CELL_TYPE.STATUS:
            return new StatusTableCell(data, key, item).getCell();
        default:
            throw new Error('Неизвестный тип ячейки');
        }
    }
}


// -- Route cell
export interface IRouteCellData {
    name: string,
    text?: ((i: any) => string),
    isChip?: ((i: any) => boolean) | boolean,
    tooltip?: ((i: any) => string) | string,
    icon?: ((i: any) => SVGElement) | SVGElement
}

class RouteTableCell {
    data: IRouteCellData;
    key: string;
    item: any;

    constructor(data: IRouteCellData, key: string, item: any) {
        this.data = data;
        this.key = key;
        this.item = item;
    }

    public getCell = (): ITableItem => {
        return {
            value: this.item[this.key],
            component: RouteCell,
            componentData: {
                routeData: this.data,
                item: this.item,
                valueKey: this.key,
            },
        };
    };
}

// -- Number cell
export interface INumberCellData {
    isMono?: boolean,
}

class NumberTableCell {
    data: INumberCellData;
    key: string;
    item: any;

    constructor(data: INumberCellData, key: string, item: any) {
        this.data = data;
        this.key = key;
        this.item = item;
    }

    getCell = (): ITableItem => {
        return {
            value: this.item[this.key],
            component: NumberCell,
            componentData: {
                ...this.data,
                value: this.item[this.key],
            },
        };
    };
}

// -- Time cell
export interface ITimeCellData {
    isMono?: boolean,
}

class TimeTableCell {
    data: ITimeCellData;
    key: string;
    item: any;

    constructor(data: ITimeCellData, key: string, item: any) {
        this.data = data;
        this.key = key;
        this.item = item;
    }

    getCell = (): ITableItem => {
        return {
            value: this.item[this.key],
            component: ViewDateTime,
            componentData: {
                ...this.data,
                value: this.item[this.key],
            },
        };
    };
}

export interface IStatusCellData {
    translate: (status: string) => IStatus
}

class StatusTableCell {
    data: IStatusCellData;
    key: string;
    item: any;

    constructor(data: IStatusCellData, key: string, item: any) {
        this.data = data;
        this.key = key;
        this.item = item;
    }

    getCell = (): ITableItem => {
        const status = this.data.translate(this.item[this.key]);
        return {
            value: this.item[this.key],
            component: StatusCell,
            componentData: {
                status,
            },
        };
    };
}
