
import { Component, Vue, Prop } from 'vue-property-decorator';
import BaseCard from "@/components/base/BaseCard.vue";
import { OfferSingle } from "@/services/offers/OfferSingle";
import TableWithFrontPaginationDesign from "@/components/base/table/TableWithFrontPaginationDesign.vue";

@Component({
    components: {
	    TableWithFrontPaginationDesign,
        BaseCard,
    },
})
export default class OfferCardMetrics extends Vue {
    @Prop({ required: true }) offer!: OfferSingle;

    headers = [
        { text: 'Период', sortable: false, value: 'period' },
        { text: 'CRu,%', sortable: false, value: 'metrics_averages.mt_cr_u' },
        { text: 'CRt,%', sortable: false, value: 'metrics_averages.mt_cr_t' },
        { text: 'EPCt', sortable: false, value: 'metrics_averages.mt_epc_t' },
        { text: 'EPCu', sortable: false, value: 'metrics_averages.mt_epc_u' },
        { text: 'AR,%', sortable: false, value: 'metrics_averages.mt_ar' },
        { text: 'EPL', sortable: false, value: 'metrics_averages.mt_epl' },
    ];

    get items(): any[] {
        return [{
            period: '30 дней',
            metrics_averages: {
                mt_cr_u: this.offer.metrics!.cr_u,
                mt_cr_t: this.offer.metrics!.cr_t,
                mt_epc_t: this.offer.metrics!.epc_t,
                mt_epc_u: this.offer.metrics!.epc_u,
                mt_epl: this.offer.metrics!.epl,
                mt_ar: this.offer.metrics!.ar,
            },
        }];
    }
}
