
import { Component, PropSync, Vue } from 'vue-property-decorator';
import { getFormatDate, getFormatDatesForDatepicker } from '@/utils/formatDates';
import { ISingleMyLeadgidInvoice } from '@/api/types/payout';
import BaseCard from '@/components/base/BaseCard.vue';
import { getEmployeesAffiliate } from '@/api/user';
import { IAffiliateItem } from '@/api/types/user';
import QuestionMarkTooltip from "@/components_v3/base/QuestionMarkTooltip.vue";
import { showServerError } from "@/utils";

@Component({
    components: {
        QuestionMarkTooltip,
        BaseCard,
    },
})
export default class MyLeadgidInvoiceAffiliateInfo extends Vue {
    @PropSync('invoice',{ required: true, default: () => ({})  }) invoiceSync!: ISingleMyLeadgidInvoice;

    affiliate: { value?: IAffiliateItem } = {};
    getFormatDatesForDatepicker = getFormatDatesForDatepicker;

    async created(): Promise<void> {
        await this.fetchAffiliateInfo();
    }

    async fetchAffiliateInfo(): Promise<void> {
        try {
            this.affiliate.value = await getEmployeesAffiliate(this.invoiceSync.affiliate_id!);
        } catch (err) {
            showServerError(err, 'Ошибка получения партнёра');
        }
    }

    get getManager(): string {
        return this.invoiceSync?.name || '';
    }

    getRequisite(item: any, method_id: string): string {
        const requisites: (string | number)[] = item instanceof Object ? Object.values(item) : [item];

        if (method_id === "contract_v1") {
            return item.legal_name;
        } else {
            return requisites.join(', ') || '';
        }
    }

    get isDesktop(): boolean {
        return this.$vuetify.breakpoint.width > 768;
    }

    get getDataForCard(): any[] {
        return [
            {
                key: 'A',
                route: 'affiliate',
                param: this.invoiceSync?.affiliate_id,
                text: 'Партнёр',
                value: `${this.invoiceSync?.affiliate_id} ${this.affiliate.value?.first_name || ''} ${this.affiliate.value?.last_name || ''}`,
            },
            {
                key: 'B',
                text: 'Валюта',
                value: this.invoiceSync?.currency,
            },
            {
                key: 'C',
                text: 'Способ выплаты',
                value: this.invoiceSync?.paid_by_payment_method?.name.ru || '',
                description: 'Основной способ выплаты партнёра на момент оплаты счёта',
            },
            {
                key: 'D',
                text: 'Реквизиты',
                value: this.getRequisite(this.invoiceSync?.paid_by_payment_method?.requisite, this.invoiceSync?.paid_by_payment_method.method_id),
                description: 'Реквизиты партнёра на момент оплаты счёта',
            },
            {
                key: 'E',
                text: 'Текущий сп.вып.',
                value: this.invoiceSync?.current_payment_method?.name.ru || '',
            },
            {
                key: 'F',
                text: 'Текущие рекв-ты',
                value: this.getRequisite(this.invoiceSync?.current_payment_method?.requisite, this.invoiceSync?.current_payment_method.method_id),
            },
            {
                key: 'G',
                text: 'Менеджер',
                value: this.getManager,
            },
            {
                key: 'H',
                text: 'Период оплаты',
                value: getFormatDate(this.invoiceSync?.pay_period, 'd MMMM yyyy'),
            },
            {
                key: 'I',
                text: 'Email',
                value: this.affiliate.value?.email,
            },
            {
                key: 'J',
                text: 'Телефон',
                value: this.affiliate.value?.phone,
            },
        ];
    }
}
