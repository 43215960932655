
import { Component, Mixins, ProvideReactive, Watch } from "vue-property-decorator";
import { Getter, Mutation, namespace } from "vuex-class";
import { eventBus } from "@/eventbus";
import { downloadExcelFile, showNotification, showServerError } from "@/utils";
import {
    geAffiliateChangeHistory,
    getAffiliateLoginURL,
    getEmployeesAffiliate, getEmployeesAffiliates,
    getUserActiveToken,
    getUserAuthorizations,
    patchUserByTypeV3,
} from '@/api/user';
import { translatePartnerStatus } from "@/utils/translate";
import { getEmployeesPersonalRates, getOffersApprovals, postEmployeesPersonalRates } from "@/api/offers";
import { getAffiliateInvoices, getPayments, newExportMyLeadgidInvoices } from "@/api/payout";
import { IColumnFilter, IFilter, IStatus } from "@/types";
import { Affiliate } from "@/services/affiliates/Affiliate";
import TableMixin from "@/mixins/table";
import PageLayout from "@/components/layout/PageLayout.vue";
import Status from "@/components/base/Status.vue";
import PaymentsTable from "@/components/affiliate/payments/PaymentsTable.vue";
import AffiliateSingle from "@/components/affiliate/AffiliateSingle.vue";
import AppTableApprovals from "@/components/offers/approvals/AppTableApprovals.vue";
import AppTableRates from "@/components/offers/personal-rates/AppTableRates.vue";
import AppTableInvoices from "@/components/affiliate/affiliateInvoices/AppTableInvoices.vue";
import AffiliateTable from "@/components/affiliate/AffiliateTable.vue";
import AffiliateAuthorizationsTable from "@/components/affiliate/AffiliateAuthorizationsTable.vue";
import TablePageFilters from "@/components/base/filters/TablePageFilters.vue";
import FormCreateApproval from "@/components/offers/approvals/FormCreateApproval.vue";
import FormPersonalRate from "@/components/offers/personal-rates/FormPersonalRate.vue";
import FormModal from "@/components/base/FormModal.vue";
import { InvoicesColumns, InvoicesFilters } from "@/services/TablePage/invoices";
import { PaymentsColumns, PaymentsFilters } from "@/services/TablePage/payments";
import { AffiliateColumns, AffiliateFilters } from "@/services/TablePage/affiliates";
import { ApprovalsColumns, ApprovalsFilters } from "@/services/TablePage/approvals";
import { PersonalRateColumns, PersonalRateFilters } from "@/services/TablePage/personalRates";
import SvgUser from "@/assets/icons/payout/user.svg";
import { parseISO } from "date-fns";
import { ICreatePersonalRate } from "@/api/types/offers";
import BulkEditing from "@/components/offers/personal-rates/BulkEditing.vue";
import PageTabs from "@/components/base/PageTabs.vue";
import EditBtn from "@/components/base/buttons/EditBtn.vue";
import CreateBtn from "@/components/base/buttons/CreateBtn.vue";
import CancelBtn from "@/components/base/buttons/CancelBtn.vue";
import DownloadBtn from "@/components/base/buttons/DownloadBtn.vue";
import AffiliateChangeHistoryTable from "@/components/affiliate/AffiliateChangeHistoryTable.vue";
import { IEmployeeAffiliateMethod } from "@/api/types/payout";
import AffiliateAdministration from "@/components/affiliate/AffiliateAdministration.vue";
import { USER_ROLES } from "@/mappings/user-roles";

const auth = namespace('authModule');

interface ITabItem {
    tab: string,
    component?: any,
    tableItemKey?: string,
    apiMethod?: Function,
    argument?: any,
    columns?: IColumnFilter[],
    filters?: IFilter[] | any,
    isGroupFilters?: boolean,
    errorText?: string,
    isDownload?: boolean,
    downloadMethod?: Function,
    isNeedDownloadParams?: boolean,
    isHideColumns?: boolean,
    isCreate?: boolean,
    createMethod?: Function,
    createFormComponent?: any,
    isEditable?: boolean,
    class?: string,
    isGetExcel?: boolean
    getExcelOptions?: IGetExcelOptions | undefined
}

interface IGetExcelOptions {
    tooltip: string,
    requiredFilters: string[]
    fileType: 'xls' | 'xlsx'
}
@Component({
    beforeRouteLeave(to: any, from: any, next: (arg0?: (vm: any) => void) => void) {
        this.setAffiliate(null);
        next();
    },
    beforeRouteEnter(to: any, from: any, next: (arg0?: (vm: any) => void) => void) {
        next(vm => {
            if (from.fullPath !== '/') {
                vm.beforeListRoute = from.fullPath;
            }
        });
    },
    components: {
        DownloadBtn,
        CancelBtn,
        CreateBtn,
        EditBtn,
        PageTabs, BulkEditing, SvgUser,
        AffiliateSingle, TablePageFilters, PageLayout, Status, FormModal },
})

export default class AffiliateSingleWrap extends Mixins(TableMixin) {
    @auth.Getter('GET_USER') user;
    @Getter('GET_UNSAVED_DATA_STATE') getUnsavedDataState;
    @Getter('authModule/GET_USER') getUser;
    @Mutation('SET_UNSAVED_DATA_STATE') setUnsavedDataState;
    @Mutation('SET_CURRENT_AFFILIATE') setAffiliate;
    @Mutation('SET_IS_MASTER_AFFILIATE') setIsMaster;
    beforeListRoute = '/affiliates/all';

    affiliate = {} as Affiliate;
    affiliateId: string = '';
    settings = { loading: false };
    editedItems: ICreatePersonalRate[] = [];
    currentFilters = [] as IFilter[];
    currentColumns = [] as IColumnFilter[];
    currentOffset = 0;
    isShowUnsavedChangesModal: boolean = false;
    isTableWithSections: boolean = false;
    isDownloadLoading: boolean = false;
    isShowCreateFormModal: boolean = false;
    isEditMode: boolean = false;
    tab: number = 0;
    targetTab: number | null = null;
    isNewDesignPage = true;

    @ProvideReactive() get getAffiliatePayments(): IEmployeeAffiliateMethod[] {
        return this.affiliate.payment_info || [];
    }

    get isShowAffiliateSingle(): boolean {
        return this.tab === 0 && Object.keys(this.affiliate).length > 0;
    }

    get getTitle(): string {
        return this.tab === 0 ? this.affiliate.full_name : `${this.affiliate.full_name} (${this.affiliateId})`;
    }

    get getStatus(): IStatus {
        return translatePartnerStatus(this.affiliate.status);
    }

    get getUserId(): number {
        return this.affiliate.user_id!;
    }

    get getAccountId(): number {
        return this.affiliate.id!;
    }

    get isBulkEdit(): boolean {
        return this.isEditMode && this.tab === 4;
    }

    get isShowTableFilters(): boolean {
        return !this.isBulkEdit && this.tab > 0 && !!this.tabItems[this.tab].columns && !!this.tabItems[this.tab].filters;
    }

    get isMaster(): boolean {
        return !!this.affiliate.is_master;
    }

    async getAffiliateData(): Promise<void> {
        this.affiliateId = this.$route.params.id;
        try {
            const data = await getEmployeesAffiliate(this.affiliateId);
            this.affiliate = new Affiliate(data);
            this.setAffiliate({ id: this.affiliate.id, name: this.affiliate.full_name });
            this.setIsMaster(this.isMaster);
            await this.affiliate.prepareAffiliateData();
        } catch (err) {
            showServerError(err, 'Партнер не найден');
        }
    }

    isTableSectionsAvailability(): void {
        const el = this.$el;
        this.isTableWithSections = !!el.querySelector('.extended-report-table__custom-header');
    }

    async updateUser (): Promise<void> {
        try {
            await patchUserByTypeV3(this.affiliateId, 'affiliate', {
                disable_2fa: !this.affiliate.two_factor_enabled,
            });
        } catch (e) {
            showServerError(e, 'Не удалось включить');
        }
    }

    async download(): Promise<void> {
        this.isDownloadLoading = true;
        const params = this.getFiltersParamsObject;
        try {
            const tab = this.tabItems[this.tab];

            if (tab.isNeedDownloadParams) {
                params.affiliate_id = [this.affiliateId];
            }

            if (!!tab.downloadMethod) {
                const file = await tab.downloadMethod(params, 'xls');
                const name = tab.tab;
                downloadExcelFile(file, name, 'xlsx');
            }
        } catch (err) {
            showServerError(err, 'Файл не загружен');
        }
        this.isDownloadLoading = false;
    }

    get getParams(): any {
        const params = {};
        for (const [key, value] of Object.entries(this.$route.query!)) {
            if (!['columns', 'offset', 'tab'].includes(key)) {
                params[key] = value;
            }
        }
        return params;
    }

    get tabItems(): ITabItem[] {
        const tabs: ITabItem[] = [
            {
                tab: 'Партнёр',
            },
            {
                tab: 'Счета',
                component: AppTableInvoices,
                apiMethod: getAffiliateInvoices,
                columns: new InvoicesColumns().columns,
                filters: InvoicesFilters,
                errorText: 'Ошибка загрузки списка счетов',
                downloadMethod: newExportMyLeadgidInvoices,
                isNeedDownloadParams: true,
                isHideColumns: true,
                isGetExcel: true,
                getExcelOptions: {
                    tooltip: 'В таблице нет данных',
                    requiredFilters: [],
                    fileType: 'xlsx',
                },
            },
            {
                tab: 'Досрочки',
                component: PaymentsTable,
                apiMethod: getPayments,
                columns: new PaymentsColumns().getColumnsForAffiliateDetailPage,
                filters: PaymentsFilters,
                errorText: 'Ошибка загрузки списка досрочек',
                downloadMethod: getPayments,
                isCreate: true,
                createMethod: this.redirectToCreatePayout,
                isGetExcel: true,
                getExcelOptions: {
                    tooltip: 'В таблице нет данных',
                    requiredFilters: [],
                    fileType: 'xlsx',
                },
            },
            {
                tab: 'Доступы к офферам',
                component: AppTableApprovals,
                apiMethod: getOffersApprovals,
                columns: new ApprovalsColumns().getColumnsForAffiliateDetailPage,
                filters: ApprovalsFilters,
                errorText: 'Ошибка загрузки списка доступов',
                isCreate: true,
                createMethod: this.showCreateForm,
                createFormComponent: FormCreateApproval,
                isGetExcel: true,
                getExcelOptions: {
                    tooltip: 'В таблице нет данных',
                    requiredFilters: [],
                    fileType: 'xls',
                },
            },
            {
                tab: 'Индивидуальные цены',
                component: AppTableRates,
                tableItemKey: 'personal_rates.id',
                apiMethod: getEmployeesPersonalRates,
                columns: new PersonalRateColumns().getColumnsForAffiliateDetailPage,
                filters: PersonalRateFilters,
                errorText: 'Ошибка загрузки списка цен',
                isCreate: true,
                createMethod: this.showCreateForm,
                createFormComponent: FormPersonalRate,
                isEditable: true,
                class: 'affiliate-rates',
                isGetExcel: true,
                getExcelOptions: {
                    tooltip: 'В таблице нет данных',
                    requiredFilters: [],
                    fileType: 'xls',
                },
            },
            {
                tab: 'Мастер-аккаунт',
                component: AffiliateTable,
                apiMethod: getEmployeesAffiliates,
                columns: new AffiliateColumns().getColumnsForDetailPage,
                filters: AffiliateFilters,
                errorText: 'Ошибка загрузки списка членов мастер аккаунта',
                isGroupFilters: true,
            },
            {
                tab: 'Авторизации',
                component: AffiliateAuthorizationsTable,
                apiMethod: getUserAuthorizations,
                argument: this.getUserId,
                errorText: 'Ошибка загрузки списка авторизаций',
            },
            {   tab: 'История изменений',
                component: AffiliateChangeHistoryTable,
                apiMethod: geAffiliateChangeHistory,
                argument: this.getAccountId,
                errorText: 'Ошибка загрузки истории изменений партнёра',
            },
        ];

        if (this.user.roles.includes(USER_ROLES.ADMIN)) {
            tabs.splice(7, 0, {
                tab: 'Администрирование',
                component: AffiliateAdministration,
                apiMethod: getUserActiveToken,
                argument: [this.affiliate.id, 'affiliate'],
                errorText: 'Ошибка загрузки списка токенов',
            });
        }

        return tabs;
    }

    async created(): Promise<void> {
        await this.getAffiliateData();
        this.tab = Number(this.$route.query.tab!) || 0;
        this.setTabToUrl();
        await this.updateTable();
    }

    changeTab(tab: number): void {
        this.isEditMode = false;
        if (this.tab === 4 && this.getUnsavedDataState) {
            this.isShowUnsavedChangesModal = true;
            this.targetTab = tab;
        } else {
            this.tab = tab;
        }
        this.additionalArguments = [];
        this.setDefaultParamsObject({});
        this.setFiltersParamsObject({});
        this.updateQueryString({});
        this.updateTable();
        this.clearSelectFilters(this.currentFilters);
    }

    @Watch('$route.query')
    setTabToUrl(query?: object): void {
        if (Number(this.$route.query.tab!) === this.tab) return;

        const params = { ...query, ...{ tab: this.tab } };
        this.updateQueryString(params);
    }

    async updateTable(): Promise<void> {
        if (this.tab > 0) {
            this.dataTable = [];
            this.isEditMode = false;
            const tab = this.tabItems[this.tab];
            this.currentFilters =  tab.filters ? new tab.filters().filters : [];
            this.currentColumns = tab.columns!;
            this.additionalArguments = !!tab.argument ? Array.isArray(tab.argument) ?
                tab.argument : [tab.argument] : [];
            this.apiMethod = tab.apiMethod;
            await this.getPagination();
            this.isTableSectionsAvailability();
        }
    }

    clearFilters(): void {
        if (this.getUnsavedDataState) {
            this.isShowUnsavedChangesModal = true;
            return;
        }
        this.clearFilter();
    }

    showCreateForm(): void {
        this.isShowCreateFormModal = true;
    }

    redirectToCreatePayout(): void {
        this.$router.push({ name: 'create-payment' });
    }

    createTableItem(): void {
        eventBus.$emit('clear-status');
        this.updateQueryString(this.getDefaultFiltersParamsObject);
        this.clearSelectFilters(this.currentFilters);
        this.getPagination();
    }

    closeUnsavedModal(): void {
        this.isShowUnsavedChangesModal = false;
    }

    cancelItemsFromModal(): void {
        this.isShowUnsavedChangesModal = false;
        this.setUnsavedDataState(false);
        this.isEditMode = false;
        this.editedItems = [];
        if (this.targetTab !== null) {
            this.tab = this.targetTab;
        } else {
            this.getPagination(this.currentOffset);
        }
    }

    editRateFromModal(): void {
        this.isShowUnsavedChangesModal = false;
        this.editItem();

        if (this.targetTab !== null) {
            this.tab = this.targetTab;
        }
    }

    cancelEdit(): void {
        this.isEditMode = false;
        this.setUnsavedDataState(false);
        this.editedItems = [];
        this.getPagination(this.currentOffset);
    }

    async editItem(): Promise<void> {
        this.isEditMode = !this.isEditMode;
        if (!this.isEditMode && this.editedItems.length > 0) {
            try {
                this.settings.loading = true;
                this.setUnsavedDataState(false);
                this.editedItems.forEach(item => {
                    if (parseISO(item.start_date!) <= new Date()) {
                        delete item.start_date;
                    }
                });
                const data = { pairs: this.editedItems.map(({ id, ...item }) => item) };
                await postEmployeesPersonalRates(data);
                showNotification('Изменения сохранены');
                await this.getPagination(this.currentOffset);
                this.editedItems = [];
            } catch (err) {
                showServerError(err, 'Ошибка сохранения');
                this.setUnsavedDataState(true);
                this.isEditMode = true;
            } finally {
                this.settings.loading = false;
            }
        }
    }

    async getPagination(offset?: number): Promise<void> {
        this.currentOffset = offset || 0;
        try {
            await this.getTableData(offset);
        } catch (err) {
            showServerError(err, this.tabItems[this.tab].errorText!);
        }
    }

    async login(): Promise<void> {
        try {
            const { url } = await getAffiliateLoginURL(this.affiliate.id!);
            window.open(url, '_blank');
        } catch (err) {
            showServerError(err, 'Не удалось авторизоваться как партнёр');
        }
    }

    backToList(): void {
        this.$router.push({ path: this.beforeListRoute });
    }

    beforeDestroy(): void {
        this.setAffiliate(null);
    }

    disabledDownload(): boolean {
        if (this.tabItems[this.tab].getExcelOptions?.requiredFilters.length)
            return !Object.keys(this.getFiltersParamsObject).some((item) => this.tabItems[this.tab].getExcelOptions?.requiredFilters.includes(item)) || !this.dataTable.length;

        return this.tabItems[this.tab].getExcelOptions === undefined || !this.dataTable.length;
    }

    downloadOptions = {
        loading: false,
        disabled: this.disabledDownload,
    };

    async getExcel(): Promise<void> {
        if (!this.tabItems[this.tab].isGetExcel && this.tabItems[this.tab].getExcelOptions === undefined ) return;
        this.downloadOptions.loading = true;
        try {
            const params = this.getFiltersParamsObject;
            if (typeof this.tabItems[this.tab].apiMethod === 'undefined') return;
            let file;
            if (this.tabItems[this.tab].downloadMethod) {
                file = await this.tabItems[this.tab].downloadMethod?.(params, this.tabItems[this.tab].getExcelOptions?.fileType);
            } else {
                file = await this.tabItems[this.tab].apiMethod?.(params, this.tabItems[this.tab].getExcelOptions?.fileType);
            }
            downloadExcelFile(file, this.tabItems[this.tab].tab, this.tabItems[this.tab].getExcelOptions?.fileType || '');
        } catch (err) {
            showServerError(err, 'Файл не загружен');
        }
        this.downloadOptions.loading = false;
    }
}

