import { AxiosResponse, ResponseType } from 'axios';
import { axiosInstance } from '@/api/index';
import { API_STATS_URL } from '@/configs/global';
import { IServerResponse } from "@/api/types";
import { IExtendedReport, IPresetParams } from "@/api/types/stats";

/**
 * Получение расширенного отчета для сотрудника
 */
export async function postEmployeeExtendedReport(
    {
        data,
        responseType = 'json',
        headers,
        params,
    }: {
        data: any
        responseType?: ResponseType
        headers?: {
            Accept?: string
            'conversions-source-type'?: 'conversion' | 'conversion-click'
            'convert-currency'?: string
            'usernames-as-string'?: string
        }
        params?: any
    },
): Promise<any> {
    return axiosInstance.request<IServerResponse<IExtendedReport>>({
        method: 'POST',
        headers: { ...headers },
        url: `${API_STATS_URL}/employees/stats`,
        data,
        responseType,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<IExtendedReport>>) => response.data);
}
// Получение шаблонов отчета
export async function getReportPresets(): Promise<any> {
    return axiosInstance.request<IServerResponse<IPresetParams[]>>({
        method: 'GET',
        url: `${API_STATS_URL}/presets`,
    })
        .then((response: AxiosResponse<IServerResponse<IPresetParams[]>>) => response.data);
}
// Сохранение шаблона отчета
export async function saveReportPresets(data: IPresetParams): Promise<any> {
    return axiosInstance.request<IServerResponse<{ id: number }>>({
        method: 'POST',
        url: `${API_STATS_URL}/presets`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<{ id: number }>>) => response.data);
}
// Обновление шаблона отчета
export async function patchReportPresets(id: string, data: IPresetParams): Promise<any> {
    return axiosInstance.request<IServerResponse<{ id: number }>>({
        method: 'PATCH',
        url: `${API_STATS_URL}/presets/${id}`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<{ id: number }>>) => response.data);
}
// Удаление шаблона отчета
export async function deleteReportPresets(id: string): Promise<unknown> {
    return axiosInstance.request<IServerResponse<unknown>>({
        method: 'DELETE',
        url: `${API_STATS_URL}/presets/${id}`,
    })
        .then((response: AxiosResponse<IServerResponse<unknown>>) => response.data);
}

export async function filterByGeoOrOS(
    params: {
        type: string,
        search: string,
    },
): Promise<any> {
    return axiosInstance.request<IServerResponse<string[]>>({
        method: 'GET',
        url: `${API_STATS_URL}/employees/filters`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<string[]>>) => response.data);
}
