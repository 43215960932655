import { AxiosResponse } from 'axios';
import {
    IParamsObject,
    IServerResponse,
} from '@/api/types';
import { axiosInstance } from '@/api/index';

import {
    API_OFFERS_URL, API_OFFERS_URL_V2,
} from '@/configs/global';
import {
    IBufferAccount,
    ICountries,
    ICreateOfferTopOfWeek,
    ICurrencies,
    IItemsFromOffers,
    IOffer,
    IOfferApproval,
    IOfferNote,
    IPersonalRates,
    IPostCreateOrUpdateApproval,
    IPostPersonalRate,
    IPatchOfferGoal,
    ITopOfWeek,
    ITag,
    IOfferV2,
    IRedirectToOffer,
    IOfferMetricsByDays,
    IEditableOfferFields,
    ITracking,
    ITrackingDomain,
    IOfferAdditionalUrl,
    ICreateOfferAdditionalUrl,
    ICreateOffer,
    IPostOfferGoal,
    IChangesHistoryItem,
    IPlannedChangesItem,
    ICreateAdvertiser,
} from '@/api/types/offers';
import { IFile } from '@/api/types/revenue';

/**
 *  Получение списка офферов
 */
export async function getEmployeesOffersV2(
    params?: IParamsObject,
    headers?: any,
    responseType?: any,
): Promise<IServerResponse<IOffer[]>> {
    return axiosInstance.request<IServerResponse<IOffer[]>>({
        method: 'GET',
        url: `${API_OFFERS_URL_V2}/employees/offers`,
        params,
        headers,
        responseType,
    })
        .then((response: AxiosResponse<IServerResponse<IOffer[]>>) => response.data);
}
/**
 *  Создание оффера
 */
export async function createEmployeesOffer(
    data: ICreateOffer,
): Promise<IServerResponse<{id: number}>> {
    return axiosInstance.request<IServerResponse<{id: number}>>({
        method: 'POST',
        url: `${API_OFFERS_URL_V2}/employees/offers`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<{id: number}>>) => response.data);
}

/**
 *  Получение списка индивидуальных цен
 */
export async function getEmployeesPersonalRates(
    params?: IParamsObject,
    downLoadFormat?: 'xls',
): Promise<IServerResponse<IPersonalRates[]>> {
    let headers;
    let responseType;
    if (downLoadFormat === 'xls') {
        headers = { 'accept': "application/vnd.ms-excel;charset=utf-8" };
        responseType = 'blob';
    }
    return axiosInstance.request<IServerResponse<IPersonalRates[]>>({
        method: 'GET',
        url: `${API_OFFERS_URL}/employees/personal-rates`,
        params,
        headers: { ...headers },
        responseType,
    })
        .then((response: AxiosResponse<IServerResponse<IPersonalRates[]>>) => response.data);
}

/**
 *  Создание и редактирование индивидуальной цены
 */
export async function postEmployeesPersonalRates(
    data?: IPostPersonalRate,
): Promise<IServerResponse<IPersonalRates[]>> {
    return axiosInstance.request<IServerResponse<IPersonalRates[]>>({
        method: 'POST',
        url: `${API_OFFERS_URL_V2}/employees/personal-rates`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<IPersonalRates[]>>) => response.data);
}

/**
 * Подтверждение некорректной ставки
 */
export async function approvePersonalRate(
    ids: number[],
): Promise<IServerResponse<IPersonalRates[]>> {
    return axiosInstance.request<IServerResponse<IPersonalRates[]>>({
        method: 'PATCH',
        url: `${API_OFFERS_URL}/employees/personal-rates/approve`,
        data: {
            ids,
        },
    })
        .then((response: AxiosResponse<IServerResponse<IPersonalRates[]>>) => response.data);
}

/**
 *  Удаление одной или нескольких индивидуальных цен
 */
export async function deleteEmployeesPersonalRates(
    data: {
        pairs: {
            offer_id: number,
            goal_id: number,
            affiliate_id: number,
            start_date?: string
        }[]
    },
): Promise<object> {
    return axiosInstance.request<object>({
        method: 'DELETE',
        url: `${API_OFFERS_URL_V2}/employees/personal-rates`,
        data,
    });
}

/**
 *  Получение индивидуальной цены
 */
export async function getEmployeesPersonalRate(
    id: string | number,
    params?: IParamsObject,
): Promise<IPersonalRates> {
    return axiosInstance.request<IServerResponse<IPersonalRates>>({
        method: 'GET',
        url: `${API_OFFERS_URL}/employees/personal-rates/${id}`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<IPersonalRates>>) => response.data.data);
}
export async function deletePersonalRateTask(id: string | number): Promise<object> {
    return axiosInstance.request<object>({
        method: 'DELETE',
        url: `${API_OFFERS_URL}/employees/personal-rates/tasks/${id}`,
    });
}

/**
 *  Получение одного оффера
 */
export async function getOneEmployeesOffer(
    id: string | number,
    params?: IParamsObject,
): Promise<IOfferV2> {
    return axiosInstance.request<IServerResponse<IOfferV2>>({
        method: 'GET',
        url: `${API_OFFERS_URL_V2}/employees/offers/${id}`,
        params: {
            ...params,
        },
    })
        .then((response: AxiosResponse<IServerResponse<IOfferV2>>) => response.data.data);
}


/**
 *  Редактирование одного оффера
 */
export async function editOneEmployeesOffer(
    id: string | number,
    data: IEditableOfferFields,
): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'PATCH',
        url: `${API_OFFERS_URL_V2}/employees/offers/${id}`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data);
}

/**
 *  Получение списка трекингов
 */
export async function getOfferTrackingList(
): Promise<ITracking[]> {
    return axiosInstance.request<IServerResponse<ITracking[]>>({
        method: 'GET',
        url: `${API_OFFERS_URL}/employees/tracking-protocols`,
    })
        .then((response: AxiosResponse<IServerResponse<ITracking[]>>) => response.data.data);
}

/**
 *  Получение списка трекинговых доменов
 */
export async function getOfferTrackingDomainsList(
): Promise<ITrackingDomain[]> {
    return axiosInstance.request<IServerResponse<ITrackingDomain[]>>({
        method: 'GET',
        url: `${API_OFFERS_URL_V2}/employees/tracking-domains`,
    })
        .then((response: AxiosResponse<IServerResponse<ITrackingDomain[]>>) => response.data.data);
}

/**
 *  Получение заметок оффера
 */
export async function getOfferNotes(
    id: string | number,
    params?: IParamsObject,
): Promise<IOfferNote[]> {
    return axiosInstance.request<IServerResponse<IOfferNote[]>>({
        method: 'GET',
        url: `${API_OFFERS_URL}/employees/offers/${id}/notes`,
        params: {
            ...params,
        },
    })
        .then((response: AxiosResponse<IServerResponse<IOfferNote[]>>) => response.data.data);
}

/*
 *  Создание заметки оффера
 */
export async function postOffersNote(
    data: any,
): Promise<IOfferNote> {
    return axiosInstance.request<IServerResponse<IOfferNote>>({
        method: 'POST',
        url: `${API_OFFERS_URL}/employees/offers/notes`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<IOfferNote>>) => {
            return  response.data.data;
        });
}

/*
 *  Редактирование заметки оффера
 */
export async function patchOffersNote(
    id: number,
    data: any,
): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'POST',
        url: `${API_OFFERS_URL}/employees/notes/${id}`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data.data);
}

/**
 *  Удалить заметку
 */
export async function deleteOffersNote(
    id: number,
): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'DELETE',
        url: `${API_OFFERS_URL}/employees/notes/${id}`,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data);
}

/**
 *  Получение списка конкурсов
 */
export async function getEmployeesContests(
): Promise<any> {
    return axiosInstance.request<IServerResponse<IItemsFromOffers>>({
        method: 'GET',
        url: `${API_OFFERS_URL}/employees/contests`,

    })
        .then((response: AxiosResponse<IServerResponse<IItemsFromOffers>>) => response.data.data);
}

/**
 *  Получение списка акций
 */
export async function getEmployeesCampaigns(
): Promise<any> {
    return axiosInstance.request<IServerResponse<IItemsFromOffers>>({
        method: 'GET',
        url: `${API_OFFERS_URL}/employees/campaigns`,

    })
        .then((response: AxiosResponse<IServerResponse<IItemsFromOffers>>) => response.data.data);
}

/**
 *  Получение списка направлений
 */
export async function getEmployeesDepartments(
    isWithMeta: boolean = false,
): Promise<any> {
    return axiosInstance.request<IServerResponse<IItemsFromOffers>>({
        method: 'GET',
        url: `${API_OFFERS_URL}/employees/departments`,
    })
        .then((response: AxiosResponse<IServerResponse<IItemsFromOffers>>) => isWithMeta ? response.data : response.data.data);
}

/**
 *  Получение списка типов выплат
 */
export async function getEmployeesPayouts(
    isWithMeta: boolean = false,
): Promise<any> {
    return axiosInstance.request<IServerResponse<IItemsFromOffers>>({
        method: 'GET',
        url: `${API_OFFERS_URL}/employees/payouts`,

    })
        .then((response: AxiosResponse<IServerResponse<IItemsFromOffers>>) => isWithMeta ? response.data : response.data.data);
}

/**
 *  Получение списка типов продуктов
 */
export async function getEmployeesProducts(
    isWithMeta: boolean = false,
): Promise<any> {
    return axiosInstance.request<IServerResponse<IItemsFromOffers>>({
        method: 'GET',
        url: `${API_OFFERS_URL}/employees/products`,

    })
        .then((response: AxiosResponse<IServerResponse<IItemsFromOffers>>) => isWithMeta ? response.data : response.data.data);
}

/**
 *  Получение списка типов статистики
 */
export async function getEmployeesStats(
    isWithMeta: boolean = false,
): Promise<any> {
    return axiosInstance.request<IServerResponse<IItemsFromOffers>>({
        method: 'GET',
        url: `${API_OFFERS_URL}/employees/stats`,

    })
        .then((response: AxiosResponse<IServerResponse<IItemsFromOffers>>) => isWithMeta ? response.data : response.data.data);
}

/**
 *  Получение списка типов трафика
 */
export async function getEmployeesTraffics(
    isWithMeta: boolean = false,
): Promise<any> {
    return axiosInstance.request<IServerResponse<IItemsFromOffers>>({
        method: 'GET',
        url: `${API_OFFERS_URL}/employees/traffics`,

    })
        .then((response: AxiosResponse<IServerResponse<IItemsFromOffers>>) => isWithMeta ? response.data : response.data.data);
}

/**
 *  Получение списка целевых действий
 */
export async function getEmployeesTargetActions(
    isWithMeta: boolean = false,
): Promise<any> {
    return axiosInstance.request<IServerResponse<IItemsFromOffers>>({
        method: 'GET',
        url: `${API_OFFERS_URL}/employees/target-actions`,

    })
        .then((response: AxiosResponse<IServerResponse<IItemsFromOffers>>) => isWithMeta ? response.data : response.data.data);
}

/**
 *  Получение списка вертикалей оффферов
 */
export async function getEmployeesOfferVerticals(
): Promise<any> {
    return axiosInstance.request<IServerResponse<IItemsFromOffers>>({
        method: 'GET',
        url: `${API_OFFERS_URL}/employees/verticals`,

    })
        .then((response: AxiosResponse<IServerResponse<IItemsFromOffers>>) => response.data.data);
}

/**
 *  Получение списка стран
 */
export async function getEmployeesCountries(
    isWithMeta: boolean = false,
): Promise<any> {
    return axiosInstance.request<IServerResponse<ICountries>>({
        method: 'GET',
        url: `${API_OFFERS_URL}/employees/countries`,
        params: { lang: 'ru', only_offers: true },

    })
        .then((response: AxiosResponse<IServerResponse<ICountries>>) => isWithMeta ? response.data : response.data.data);
}

/**
 *  Получение списка валют
 */
export async function getEmployeesCurrencies(
): Promise<any> {
    return axiosInstance.request<IServerResponse<ICurrencies>>({
        method: 'GET',
        url: `${API_OFFERS_URL}/employees/currencies`,

    })
        .then((response: AxiosResponse<IServerResponse<ICurrencies>>) => response.data.data);
}

/**
 *  Получение буферных аккаунтов
 */
export async function getEmployeesBufferAccounts(
): Promise<any> {
    return axiosInstance.request<IServerResponse<IBufferAccount[]>>({
        method: 'GET',
        url: `${API_OFFERS_URL}/employees/buffer-accounts`,

    })
        .then((response: AxiosResponse<IServerResponse<IBufferAccount[]>>) => response.data.data);
}


/*
 * Получение названий офферов по id
 */
export async function getOffersById(
    { ids }: { ids: number[] },
): Promise<IServerResponse<Array<{id: number, name: string}>>> {
    return axiosInstance.request<IServerResponse<Array<{id: number, name: string}>>>({
        method: 'POST',
        url: `${API_OFFERS_URL}/employees/offers-names`,
        data: {
            ids,
        },
    })
        .then((response: AxiosResponse<IServerResponse<Array<{id: number, name: string}>>>) => response.data);
}

/**
 *  Получение списка офферов, которые ожидают подключения
 */
export async function getOffersApprovals(
    params: {
        status?: string[];
        affiliate_id?: number[];
        offer_id?: number[];
        advertiser_manager_id?: number[];
        affiliate_manager_id?: number[];
        limit: number;
        offset: number;
    },
    downLoadFormat?: 'xls',
): Promise<IServerResponse<IOfferApproval[]>> {
    let headers;
    let responseType;
    if (downLoadFormat === 'xls') {
        headers = { 'accept': "application/vnd.ms-excel;charset=utf-8" };
        responseType = 'blob';
    }
    return axiosInstance.request<IServerResponse<IOfferApproval[]>>({
        method: 'GET',
        url: `${API_OFFERS_URL}/employees/approvals`,
        params,
        headers: { ...headers },
        responseType,
    })
        .then((response: AxiosResponse<IServerResponse<IOfferApproval[]>>) => response.data);
}

/**
 *  Получение оффера, который ожидает подключения
 */
export async function getOffersApproval(
    id: string,
): Promise<IOfferApproval> {
    return axiosInstance.request<IServerResponse<IOfferApproval>>({
        method: 'GET',
        url: `${API_OFFERS_URL}/employees/approvals/${id}`,
    })
        .then((response: AxiosResponse<IServerResponse<IOfferApproval>>) => {
            return response.data.data;
        });
}

/**
 *  Метод добавления логотипов оффера
 */
export const addOfferLogo = async (file: IFile, id: number): Promise<AxiosResponse> => {
    const formData = new FormData();
    formData.append('file', file as Blob);
    return await axiosInstance.post(`${API_OFFERS_URL}/employees/offers/${id}/logo`, formData);
};

/**
 *  Метод добавления логотипов оффера
 */
export const addOfferLogoV2 = async (file: IFile, id: number): Promise<AxiosResponse> => {
    const formData = new FormData();
    formData.append('file', file as Blob);
    return await axiosInstance.post(`${API_OFFERS_URL_V2}/employees/offers/${id}/logo`, formData);
};


/**
 *  Метод добавления логотипов оффера
 */
export const addOfferRoundLogo = async (file: IFile, id: number): Promise<AxiosResponse> => {
    const formData = new FormData();
    formData.append('file', file as Blob);
    return await axiosInstance.post(`${API_OFFERS_URL_V2}/employees/offers/${id}/round-logo`, formData);
};


/**
 * Изменение оффера для редиректа
 */
export async function patchRedirectOffer(
    id: number,
    redirect_offer_id: number | null,
): Promise<{ redirect_offer_id: number | null }> {
    return axiosInstance.request<IServerResponse<{ redirect_offer_id: number | null }>>({
        method: 'PATCH',
        url: `${API_OFFERS_URL}/employees/offers/${id}/redirect-offer?is_legacy=0`,
        data: {
            redirect_offer_id,
            is_legacy: 0,
        },
    })
        .then((response: AxiosResponse<IServerResponse<{ redirect_offer_id: number | null }>>) => response.data.data);
}


/**
 *  получить топ за неделю
 */
export async function getOffersTopOfWeek(
    params?: IParamsObject,
): Promise<object> {
    return axiosInstance.request<IServerResponse<ITopOfWeek>>({
        method: 'GET',
        url: `${API_OFFERS_URL}/employees/top-offers`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<ITopOfWeek>>) => response.data);
}


/**
 *  Удалить топ за неделю
 */
export async function deleteOffersTopOfWeek(
    id: number,
): Promise<object> {
    return axiosInstance.request<IServerResponse<ITopOfWeek>>({
        method: 'DELETE',
        url: `${API_OFFERS_URL}/employees/top-offers/${id}`,
    })
        .then((response: AxiosResponse<IServerResponse<ITopOfWeek>>) => response.data);
}


/**
 *  Создать топ за неделю
 */
export async function postOffersTopOfWeek(
    data?: ICreateOfferTopOfWeek,
): Promise<object> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'POST',
        url: `${API_OFFERS_URL}/employees/top-offers`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => {
            return  response.data.data;
        });
}
/**
 *  обновление топа офферов по стране
 */
export async function patchOffersTopOfWeek(
    id: string |  number,
    data?: any,
): Promise<object> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'POST',
        url: `${API_OFFERS_URL}/employees/top-offers/${id}`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => {
            return  response.data.data;
        });
}


/**
 *  обновление статуса оффера
 */
export async function patchStatusOffersTopOfWeek(
    id: string |  number,
    status?: string,
): Promise<object> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'POST',
        url: `${API_OFFERS_URL}/employees/top-offers/${id}/status`,
        data: {
            status,
        },
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => {
            return  response.data.data;
        });
}

/**
 * Создание новых или обновление статусов доступов
 */

export async function createOrUpdateStatusApproval(data: IPostCreateOrUpdateApproval): Promise<object> {
    return axiosInstance.request<object>({
        method: 'POST',
        url: `${API_OFFERS_URL_V2}/employees/offers/accesses`,
        data,
    });
}

/**
 *  Получение списка буферных аккаунтов для фильтра
 */
export async function getBufferAccounts(
    params?: IParamsObject,
): Promise<IServerResponse<IBufferAccount[]>> {
    return axiosInstance.request<IServerResponse<IBufferAccount[]>>({
        method: 'GET',
        url: `${API_OFFERS_URL}/employees/buffer-affiliates`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<IBufferAccount[]>>) => response.data);
}


/**
 *  Добавление партнера в буфферный аккаунт
 */
export async function postAffiliateToBufferAccountV2(
    data: any,
): Promise<IServerResponse<object>> {
    return axiosInstance.request<IServerResponse<object>>({
        method: 'POST',
        url: `${API_OFFERS_URL_V2}/employees/buffer-affiliates`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<object>>) => response.data);
}


/**
 *  Удаление партнеров из буфферного аккаунта
 */
export async function deleteAffiliateFromBufferV2(
    ids: number[],
): Promise<object> {
    return axiosInstance.request<object>({
        method: 'DELETE',
        url: `${API_OFFERS_URL_V2}/employees/buffer-affiliates`,
        data: { ids },
    });
}

export async function exportOffers(
    params?: any,
):Promise<IServerResponse<any[]>> {
    return axiosInstance.request<IServerResponse<any[]>>({
        method: 'GET',
        url: `${API_OFFERS_URL}/employees/top-offers/report`,
        responseType: 'blob',
        params,
    }).then((response: AxiosResponse<IServerResponse<any[]>>) => response.data);
}

/**
 *  Получение партнеров работающих с офферами
*/
export async function getAffiliatesByOffers(
    data: {
        offers: number[],
        days?: number | null
    },
): Promise<number[]>{
    return axiosInstance.request<IServerResponse<number[]>>({
        method: 'POST',
        url: `${API_OFFERS_URL}/employees/affiliates-offers`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<number[]>>) => response.data.data);
}

/**
 * Получение списка существующих тегов
 */
export async function getOfferTags():Promise<any> {
    return axiosInstance.request<IServerResponse<ITag[]>>({
        method: 'GET',
        url: `${API_OFFERS_URL}/employees/tags`,
    })
        .then((response: AxiosResponse<IServerResponse<ITag[]>>) => response.data.data);
}

export async function createOfferTag(data: {name: string}):Promise<IServerResponse<any>> {
    return axiosInstance.request<IServerResponse<any>>({
        method: "POST",
        url: `${API_OFFERS_URL}/employees/tags`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<any[]>>) => response.data);
}

export async function saveOfferTags(data: {tags: number[]}, id: number):Promise<IServerResponse<any>> {
    return axiosInstance.request<IServerResponse<any>>({
        method: "PATCH",
        url: `${API_OFFERS_URL}/employees/offers/${id}/tags`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<any[]>>) => response.data);
}

/**
 * Редактирование цели офера
 */

export async function updateOfferGoal(
    offer_id: number,
    goal_id: number,
    data: IPatchOfferGoal,
): Promise<IServerResponse<any>> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'PATCH',
        url: `${API_OFFERS_URL}/employees/offers/${offer_id}/goals/${goal_id}`,
        data,

    }).then((response: AxiosResponse<IServerResponse<any>>) => response.data.data);
}


/**
 * Создание цели оффера
 */
export async function createOfferGoal(
    offer_id: number,
    data: IPostOfferGoal,
): Promise<IServerResponse<any>> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'POST',
        url: `${API_OFFERS_URL}/employees/offers/${offer_id}/goals`,
        data,

    }).then((response: AxiosResponse<IServerResponse<any>>) => response.data.data);
}

/**
 *  Получение списка редиректов на оффер
 */
export async function getRedirectsToOffer(
    offer_id: number,
): Promise<IServerResponse<IRedirectToOffer[]>> {
    return axiosInstance.request<IServerResponse<IRedirectToOffer[]>>({
        method: 'GET',
        url: `${API_OFFERS_URL}/employees/offers/${offer_id}/redirects-to-offer`,
    })
        .then((response: AxiosResponse<IServerResponse<IRedirectToOffer[]>>) => response.data);
}

/**
 *  Получение истории изменениё оффер
 */
export async function getChangeHistory(
    offer_id: number,
    params: { limit: number, offset: number },
): Promise<IServerResponse<IChangesHistoryItem[]>> {
    return axiosInstance.request<IServerResponse<IChangesHistoryItem[]>>({
        method: 'GET',
        url: `${API_OFFERS_URL_V2}/employees/offers/${offer_id}/changes`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<IChangesHistoryItem[]>>) => response.data);
}

/**
 *  Получение запланированных изменений оффера
 */
export async function getPlannedChanges(
    offer_id: number,
    params: { is_done: 0 | 1 },
): Promise<IServerResponse<IPlannedChangesItem[]>> {
    return axiosInstance.request<IServerResponse<IPlannedChangesItem[]>>({
        method: 'GET',
        url: `${API_OFFERS_URL_V2}/employees/offers/${offer_id}/changes-task`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<IPlannedChangesItem[]>>) => response.data);
}

/**
 *  Изменение запланированных изменений оффера
 */
export async function patchPlannedChanges(
    task_id: number,
    data: { action_date: string, value: string },
): Promise<IServerResponse<IPlannedChangesItem[]>> {
    return axiosInstance.request<IServerResponse<IPlannedChangesItem[]>>({
        method: 'PATCH',
        url: `${API_OFFERS_URL_V2}/employees/offers/changes-tasks/${task_id}`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<IPlannedChangesItem[]>>) => response.data);
}
/**
 *  Удаление запланированных изменений оффера
 */
export async function deletePlannedChanges(
    task_id: number,
): Promise<IServerResponse<IPlannedChangesItem[]>> {
    return axiosInstance.request<IServerResponse<IPlannedChangesItem[]>>({
        method: 'DELETE',
        url: `${API_OFFERS_URL_V2}/employees/offers/changes-tasks/${task_id}`,
    })
        .then((response: AxiosResponse<IServerResponse<IPlannedChangesItem[]>>) => response.data);
}


/**
 *  Получение метрик оффера по дням
 */
export async function getOfferMetricsByPeriod(
    id: number,
    days: number,
): Promise<IServerResponse<IOfferMetricsByDays>> {
    return axiosInstance.request<IServerResponse<IOfferMetricsByDays>>({
        method: 'GET',
        url: `${API_OFFERS_URL}/employees/offers/${id}/metrics-daily/days`,
        params: {
            legacy_id: 1,
            days,
        },
    })
        .then((response: AxiosResponse<IServerResponse<IOfferMetricsByDays>>) => response.data);
}

/**
 * Редактирование ссылок оффера
 */

export async function updateOfferAdditionalUrls(
    url_id: number,
    data: IOfferAdditionalUrl,
): Promise<IServerResponse<any>> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'PATCH',
        url: `${API_OFFERS_URL_V2}/employees/offers/urls/${url_id}`,
        data,

    }).then((response: AxiosResponse<IServerResponse<any>>) => response.data.data);
}

/**
 * Создание ссылок оффера
 */

export async function createOfferAdditionalUrls(
    data: ICreateOfferAdditionalUrl,
): Promise<IServerResponse<any>> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'POST',
        url: `${API_OFFERS_URL_V2}/employees/offers/urls`,
        data,

    }).then((response: AxiosResponse<IServerResponse<any>>) => response.data.data);
}

/**
 * Создание рекламодателя
 */

export async function createAdvertiser(
    data: ICreateAdvertiser,
): Promise<IServerResponse<any>> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'POST',
        url: `${API_OFFERS_URL_V2}/employees/offers/advertisers`,
        data,

    }).then((response: AxiosResponse<IServerResponse<any>>) => response.data.data);
}

/**
 *  Получение параметров продукта оффера
 */
export async function getOfferParams(
    id: number,
): Promise<IServerResponse<any>> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'GET',
        url: `${API_OFFERS_URL}/employees/offers/${id}/params`,
        params: {
            by_legacy_id: 1,
        },
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data);
}

/**
 *  Сохранение параметров продукта оффера
 */
export async function postOfferParams(
    id: number,
    data: any,
): Promise<IServerResponse<any>> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'POST',
        url: `${API_OFFERS_URL}/employees/offers/${id}/params`,
        params: {
            by_legacy_id: 1,
        },
        data,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data);
}

/**
 *  Получение формы параметров продукта
 */
export async function getParamsForm(
    product_id: number,
): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'GET',
        url: `${API_OFFERS_URL}/employees/params/form`,
        params: {
            product_id,
        },
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data.data);
}

/**
 *  Обновлениие данных рекламодателя
 *  {
 *      name: string,
 *      manager_id: number,
 *      status: string
 *  }
 */
export async function patchAdvertiserInfo(
    data: {
        name?: string,
        manager_id?: number,
        status?: string
    },
    id: number,
): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'PATCH',
        url: `${API_OFFERS_URL_V2}/employees/offers/advertisers/${id}`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data.data);
}
