
import { Component, Prop, Vue, Watch, PropSync } from 'vue-property-decorator';
import omit from 'lodash-es/omit';
import reduce from 'lodash-es/reduce';
import getByKey from 'lodash-es/get';
import isEqual from 'lodash-es/isEqual';
import { getFormatDatesForDatepicker } from '@/utils/formatDates';
import { eventBus } from '@/eventbus';
import TableScroll from '@/directives/TableScroll';
import Status from '@/components/base/Status.vue';
import TooltipButton from '@/components/base/buttons/TooltipButton.vue';
import MultiButton from '@/components/base/buttons/MultiButton.vue';
import CardMaterial from '@/components/base/CardMaterial.vue';
import AppTableRouter from '@/components/base/TableUI/AppTableRouter.vue';
import Pagination from '@/components/base/table/Pagination.vue';
import { PAGINATION_PER_PAGE } from '@/configs/global';
import SvgScrollArrNext from '@/assets/icons/scroll-arr-next.svg';
import SvgScrollArrPrev from '@/assets/icons/scroll-arr-prev.svg';
import TdRoute from "@/components/base/TableUI/TdRoute.vue";
import TdActions from "@/components/base/TableUI/TdActions.vue";
import TdNumber from "@/components/base/TableUI/TdNumber.vue";
import ViewDateTime from "@/components/base/ViewDateTime.vue";
import SvgActions from "@/assets/icons/actions.svg";
import SvgPause from "@/assets/icons/pause.svg";
import SvgPlay from "@/assets/icons/play.svg";
import TdStatus from "@/components/base/TableUI/TdStatus.vue";
import TdDropDown from "@/components/base/TableUI/TdDropDown.vue";
import { ITableStatus } from '@/types';
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import ThSort from "@/components/base/TableUI/ThSort.vue";
import QuestionMarkTooltip from "@/components_v3/base/QuestionMarkTooltip.vue";
import TableFooterButtons from "@/components/base/TableUI/TableFooterButtons.vue";
import BaseTable from "@/components_v3/base/table/BaseTable.vue";

@Component({
    components: {
        BaseTable,
        TableFooterButtons,
        QuestionMarkTooltip,
        ThSort,
        BaseSelect,
        TdDropDown,
        TdStatus,
        ViewDateTime,
        TdNumber,
        TdActions,
        TdRoute,
        Status,
        TooltipButton,
        MultiButton,
        CardMaterial,
        AppTableRouter,
        Pagination,
        SvgScrollArrNext,
        SvgScrollArrPrev,
        SvgActions,
        SvgPause,
        SvgPlay,
    },
    directives: {
        TableScroll,
    },
})

export default class AppTableDesign extends Vue {
    @Prop({ type: Boolean, default: false }) customHeader!: boolean;
    @Prop({ type: Boolean, default: false }) sectionHeader!: boolean;
    @Prop({ type: Boolean, default: true }) isShowSelect!: boolean;
    @Prop({ type: Boolean, default: false }) classId!: boolean;
    @Prop({ type: Boolean, default: true }) renderPagination!: boolean;
    @Prop({ type: Boolean, default: false }) rowSelect!: boolean;
    @Prop({ type: Boolean, default: true }) isIcon!: boolean;
    @Prop({ type: Boolean, default: true }) loading!: boolean;
    @Prop({ type: Boolean, default: false }) expand!: boolean;
    @Prop({ type: Boolean, default: true }) hideDefaultFooter!: boolean;
    @Prop({ type: Number, default: 0 }) totalElementDataTable!: number;
    @Prop({ type: String, default: 'primary' }) activeColor!: string;
    @Prop({ type: String, default: 'id' }) tableItemKey!: string;
    @Prop({ default: null }) addRowClass!: any;
    @Prop({ default: '' }) search!: string;
    @Prop({ type: Array, default: () => [] }) statuses!: ITableStatus[] | [];
    @Prop({ type: Array, default: () => [] }) routes!: [];
    @Prop({ type: Array, default: () => [] }) dropDownItems!: [];
    @Prop({ type: Array, required: true }) items!: [];
    @Prop({ type: Array, required: true }) headers!: any[];
    @Prop({ type: Array, default: () => [] }) booleanItems!: [];
    @Prop({ type: Array, default: () => [] }) actions!: [];
    @Prop({ type: Array, default: () => [] }) numberFormatting!: Array<{}>;
    @Prop({ type: Array, default: () => [] }) uniqueData!: Array<{}>;
    @Prop({ type: Array, default: () => [] }) timeData!: Array<{}>;
    @Prop({ type: Array, default: () => [] }) images!: Array<{}>;
    @Prop({ type: Boolean, default: false }) needTopScroll!: boolean;
    @Prop({ type: [String, Number], default: '' }) id!: number | string;
    @Prop({ type: Boolean, default: true }) disableSort!: boolean;
    @Prop({ type: Boolean, default: false }) isNotFound!: boolean;
    @Prop({ type: String, default: 'Ничего не найдено' }) noDataText!: string;
    @Prop({ type: String, default: 'Ничего не найдено' }) noResultText!: string;
    @Prop({ type: Boolean, default: false }) truncateStatus!: boolean;
    @Prop({ type: Boolean, default: true }) isSetOffsetToUrl!: boolean;
    @Prop({ type: Boolean, default: true }) needFilterColumns!: boolean;
    @Prop({ type: Array, default: () => [] }) footerButtons!: Array<{}>;
    @Prop({ type: Boolean, default: false }) isHideDefaultFooterButtons!: boolean;
    @Prop() lockedRow!: any;
    @Prop({ type: Number, default: 0 }) itemsPerPage!: number;
    @Prop({ type: Boolean, default: true }) isShowScrollButtons!: boolean;

    @PropSync('options', { type: Object, default: () => ({}) }) optionsSync!: {};
    @PropSync('sortBy', { type: String, default: '' }) sortBySync!: string;
    @PropSync('sortDesc', { type: Boolean, default: false }) sortDescSync!: boolean;
    @PropSync('selected', { type: Array, default: () => ([]) }) selectedSync!: any[];

    getByKey = getByKey;

    isResendData: boolean = false;
    // встроенный Vuetify объект
    pagination: { page: number } = {
        page: 1,
    };
    selectedLimit = PAGINATION_PER_PAGE || 25;
    getFormatDatesForDatepicker = getFormatDatesForDatepicker;
    // для скрола
    scrollX = 0;

    closeTooltips(): void {
        const tooltips = this.$refs['markTooltip'] as any[];
        if (tooltips) {
            tooltips.forEach(i => i.closeTooltip());
        }
        if (!tooltips || tooltips.length === 0) return;
        tooltips.forEach(i => i.closeTooltip());
    }

    get isScrollBtns(): boolean {
        const table = this.$refs['tableBase'] as Vue | HTMLElement | null;
        if (table && 'offsetWidth' in table) {
            const overWidth = table.offsetWidth + 50 > document.body.clientWidth;
            return this.$props.items?.report && overWidth;
        }
        return true;
    }

    get isShowFooterButtons(): boolean {
        return this.selectedSync?.length > 0;
    }

    scrollXNext(): void {
        const vs = this.$refs['vs'] as Vue & { scrollTo: (options: { x: number }, duration: number) => void };
        if (vs && vs.scrollTo) {
            if (this.scrollX) this.scrollX += 250;
            vs.scrollTo({ x: this.scrollX }, 500);
        }
    }

    scrollXPrev(): void {
        const vs = this.$refs['vs'] as Vue & { scrollTo: (options: { x: number }, duration: number) => void };
        if (vs && vs.scrollTo) {
            if (this.scrollX) this.scrollX -= 250;
            vs.scrollTo({ x: this.scrollX }, 500);
        }
    }

    scrollToTop(): void {
        const table = this.$refs.tableBase as Vue | null;
        const wrapper = document.querySelector('.__panel') as HTMLElement | null;
        if (table && wrapper) {
            const tableComponent = table.$el as HTMLElement;
            wrapper.scrollTo({
                top: tableComponent.offsetTop,
                behavior: 'smooth',
            });
        }
    }

    created(): void {
        eventBus.$on('go-to-first-page', this.goToFirstPage);
        if (this.$props.itemsPerPage) {
            this.selectedLimit = this.$props.itemsPerPage;
        }
    }

    goToFirstPage(): void {
        this.pagination.page = 1;
    }

    @Watch('items')
    goToPrevPage(): void {
        if (this.isNotFound) {
            this.pagination.page = 1;
        } else if (this.items?.length <= 0 && this.pagination.page > 1) {
            this.pagination.page = --this.pagination.page;
            this.isResendData = true;
        }
    }

    @Watch('pagination.page')
    cleanSelected(): void {
        this.selectedSync = [];
    }

    @Watch('selectedSync')
    resetFooterSelect(): void {
        if (this.selectedSync.length === 0) {
            this.$emit('reset-select');
        }
    }

    updateSelectedSync(newVal): void {
        this.selectedSync = newVal;
    }

    @Watch('$route.query')
    cleanSelectedByFilterChange(value: any, oldValue: any): void {
        const IGNORE_QUERY_PARAMS = ['columns'];

        const prev = omit(oldValue, IGNORE_QUERY_PARAMS);
        const current = omit(value, IGNORE_QUERY_PARAMS);

        const diff = reduce(
            current,
            (result, value, key) => isEqual(value, prev[key]) ? result : result.concat(value),
            [],
        );

        if (diff.length) {
            this.selectedSync = [];
        }
    }

    getPaginationData(offset: number, limit: number, isChangeLimit?: boolean): void {
        this.selectedLimit = limit;
        this.scrollToTop();
        if (isChangeLimit) {
            this.$emit('get-page-data', 0, limit, isChangeLimit);
        } else {
            this.$emit('get-page-data', offset, limit);
        }
        this.isResendData = false;
    }

    beforeDestroy(): void {
        eventBus.$off('go-to-first-page', this.goToFirstPage);
    }
}
