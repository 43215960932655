
import {
    Component, Prop, PropSync, Vue,
} from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import CardMaterial from '@/components/base/CardMaterial.vue';
import OfferServicesSelectTable from '@/components/revenue/offerServicesSelectTable.vue';
import { IInvoice, INettingItem } from '@/api/types/revenue';
import FormModal from '@/components/base/FormModal.vue';
import { eventBus } from '@/eventbus';
import store from '@/store';
import { getUnpaidInvoices } from '@/api/revenue';
import { getCurrencyIcon } from '@/utils/translate';
import { getFormatDate } from '@/utils/formatDates';
import { NETTING_TYPE } from '@/mappings/netting-type';
import { showServerError } from '@/utils';
import { getCurrencyRate } from '@/api/catalogue';
import { ALLOWED_DIFFERENCE_NETTING } from '@/configs/global';
import EditModeTextField from "@/components/base/form/EditModeTextField.vue";

@Component({
    components: {
        EditModeTextField,
        FormModal,
        OfferServicesSelectTable,
        CardMaterial,
    },
})

export default class Netting extends Vue {
    @PropSync('invoice', { required: true }) invoiceSync!: IInvoice;
    @PropSync('totalSumOfNetting', { required: true }) totalSumOfNettingSync!: number;
    @PropSync('nettingType', { required: true }) nettingTypeSync!: string;
    @PropSync('nettingErrorText', { required: true }) nettingErrorTextSync!: string;
    @Prop({ required: true }) getOfferAndServicesAmount!: number;

    @Getter('GET_EDITING_MODE') editingMode;
    @Mutation('SET_UNSAVED_DATA_STATE') setUnsavedDataState;

    getFormatDate = getFormatDate;
    NETTING_TYPE = NETTING_TYPE;
    ALLOWED_DIFFERENCE_NETTING = ALLOWED_DIFFERENCE_NETTING;

    get nettingTableItemsFromProps(): INettingItem[] {
        return this.invoiceSync.netting!.map((obj) => {
            if (!obj.hasOwnProperty('course')) {
                const unpaidItem = this.findUnpaidItem(obj);
                const keyDate = obj.hasOwnProperty('id') ? 'affiliate_invoice_period' : 'created_at';
                const course = unpaidItem ? unpaidItem.course
                    : this.transferCourseCurrency(obj, 'affiliate_invoice_currency', keyDate);
                this.$set(obj, 'course', course);
            }
            obj.rate_paid_sum = Math.round((obj.paid_sum! * (Number(obj.course) || 1)) * 100) / 100;
            return obj;
        });
    }

    get getCurrencyIcon(): string {
        return getCurrencyIcon(this.invoiceSync.currency as string);
    }

    get nettingInvoiceMapper(): any {
        const count: number = 0;
        let sum: number = 0;
        if (this.invoiceSync.netting!.length > 0 && this.invoiceSync.netting![0].type === NETTING_TYPE.WM) {
            sum = this.invoiceSync.netting!
                .map((item) => ((item.paid_sum! * (Number(item.course) || 1))! * 100) / 100 || 0)
                .reduce((a, b) => Number(a) + Number(b), 0);
        }
        return {
            count,
            sum,
        };
    }

    totalElementDataTable: number = 0;
    copyNettingTableItems: INettingItem[] = [];
    // для маски
    mask = '';
    nettingId: number | null = null;
    isUnpaidAffiliateAccountsReceived = false;
    unpaidAffiliateHeaders = [
        { text: 'ID счёта', value: 'affiliate_invoice_id', width: '10%' },
        { text: 'ID партнёра', value: 'affiliate_id', width: '10%' },
        { text: 'Дата создания', value: 'created_at', width: '10%' },
        { text: 'Сумма', value: 'amount', width: '20%', align: 'right' },
        { text: 'Курс', value: 'course', align: 'right' },
        { text: 'Итоговая сумма', value: 'total_amount', align: 'right', width: '20%' },
        { text: 'Доступный остаток', value: 'partials_amount', align: 'right', width: '20%' },
        { text: '', align: 'right', value: 'actions', width: '15%' },
    ];
    // Данные для взаимозачета
    unpaidAffiliateAccounts: INettingItem[] = [];
    // Тип взаимозачета
    type: any = NETTING_TYPE.NONE;
    nettingWasUpdated = false;
    nettingActAmount = 0;
    // модалка для удаления
    deletingNetting: any = null;
    // модалка-предупреждение, если счет уже добавлен в таблицу
    showWarning = false;
    // Добавить копии данных для взаимозачета,
    // чтобы при переключении типа взаимозачета данные не удалялись
    nettingWebMastersData: INettingItem[] = [];
    nettingActData: INettingItem[] = [
        {
            type: NETTING_TYPE.ACT,
            amount: 0,
            paid_sum: 0,
        },
    ];
    uniqueData = [
        {
            slot: 'item.amount',
            template: (item: any): string => `${item.amount.toLocaleString('ru-RU', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            })} ${item.currency}`,
        },
        {
            slot: 'item.partials_amount',
            template: (item: any): string => (`${(Math.round(((item.amount - item.partials_amount)
                * (Number(item.course) || 1)) * 100) / 100).toLocaleString('ru-RU', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            })} ${this.$props.invoice?.currency ?? item.currency}`) || '0,00',
        },
        {
            slot: 'item.total_amount',
            template: (item: any): string => (`${(item.amount * (Number(item.course) || 1)).toLocaleString('ru-RU', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            })} ${this.$props.invoice?.currency ?? item.currency}`) || '0,00',
        },
    ];

    // получение курса валют на определенную дату
    async getCurrencyCourse(item: INettingItem, keyCurrency: string, keyDate: string): Promise<void> {
        const date = item[keyDate]?.substr(0, 10);
        if (item[keyCurrency] && date) {
            try {
                await this.getCurrencyRate(item,date, item[keyCurrency]);
            } catch (err) {
                if (err.status === 404) {
                    await this.getCurrencyRateFromBank(item, date, item[keyCurrency]);
                } else {
                    showServerError(err, 'Не удалось получить информацию по курсу валют');
                }
            }
        }
    }

    // получение курса валют с банка
    async getCurrencyRateFromBank(item: INettingItem, date: string, currencyFrom: string): Promise<void>{
        try {
            await this.getCurrencyRate(item, date, currencyFrom, 'cb');
        } catch (err) {
            if (err.status === 404) {
                await this.getCurrencyRateFromAPI(item, date, currencyFrom);
            } else {
                showServerError(err, 'Не удалось получить информацию по курсу валют');
            }
        }
    }

    // получение курса валют с API
    async getCurrencyRateFromAPI(item: INettingItem, date: string, currencyFrom: string): Promise<void> {
        try {
            await this.getCurrencyRate(item, date, currencyFrom, 'api');
        } catch (err) {
            showServerError(err, 'Не удалось получить информацию по курсу валют');
        }
    }

    async getCurrencyRate(item: INettingItem, date: string, currencyFrom: string, source='excel'): Promise<void> {
        const data = await getCurrencyRate({
            source,
            currency_from: currencyFrom,
            currency_to: this.invoiceSync.currency,
            date,
        });
        const course = data.rate || '-';
        this.$set(item, 'course', course);
    }

    // если валюта неоплаченного счета отличается от валюты счета, отправляем запрос на получение курса
    transferCourseCurrency(item: INettingItem, keyCurrency: string = 'currency', keyDate: string = 'created_at'): any {
        if (item[keyCurrency] !== this.invoiceSync.currency) {
            this.getCurrencyCourse(item, keyCurrency, keyDate);
            return item.course;
        }
        return '-';
    }

    get showNettingCheckbox(): boolean {
        const { count } = this.nettingInvoiceMapper;
        const { sum: sumOfNettingInvoices } = this.nettingInvoiceMapper;
        const offerAndServicesAmount = this.getOfferAndServicesAmount;
        if (this.invoiceSync.netting!.length >= 1 && sumOfNettingInvoices > offerAndServicesAmount) {
            if (count === 0) {
                this.nettingErrorTextSync = `Сумма партнёрских счетов превышает счёт рекламодателя
                     на ${(sumOfNettingInvoices - offerAndServicesAmount).toLocaleString('ru-RU', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    })} ${this.getCurrencyIcon}.
                    Укажите меньшую сумму оплаты в одном из партнерских счетов`;
            } else {
                this.nettingErrorTextSync = '';
                return false;
            }
            return true;
        }
        this.nettingErrorTextSync = '';
        return false;
    }

    setType(type: string): void {
        this.nettingTypeSync = type;
        this.invoiceSync.netting = [];
        if (type === NETTING_TYPE.NONE) {
            this.invoiceSync.netting = [];
        }
        if (type === NETTING_TYPE.ACT) {
            this.invoiceSync.netting = this.nettingActData;
        }
        if (type === NETTING_TYPE.WM) {
            this.invoiceSync.netting = this.nettingWebMastersData;
        }
    }

    // подсчет итоговой суммы взаимозачета
    get totalSumNetting(): number {
        if (this.type === NETTING_TYPE.NONE) {
            this.totalSumOfNettingSync = 0;
        }
        if (this.type === NETTING_TYPE.ACT) {
            this.totalSumOfNettingSync = this.nettingActData![0]!.amount || 0;
        }
        if (this.type === NETTING_TYPE.WM) {
            this.totalSumOfNettingSync = this.nettingTableItemsFromProps
                .map((item) => (item.paid_sum ? item.paid_sum * (Number(item.course) || 1) : 0))
                .reduce((a, b) => Number(a) + Number(b), 0);
        }
        return this.totalSumOfNettingSync;
    }

    // ошибка в сумме нетинга
    get errorTextNetting(): string {
        const diffSum = this.totalSumNetting - this.getOfferAndServicesAmount;
        if (diffSum - ALLOWED_DIFFERENCE_NETTING > 0) {
            this.nettingErrorTextSync = `Сумма взаимозачета превышает итоговую сумму счета на ${(diffSum).toLocaleString('ru-RU', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            })} ${this.getCurrencyIcon}.`;
        } else if (diffSum >= 0 && diffSum <= ALLOWED_DIFFERENCE_NETTING && this.totalSumNetting > 0) {
            this.nettingErrorTextSync = 'Если счёт рекламодателя полностью закрывается взаимозачётом, '
                + 'то из заказа покупателя нужно удалить все услуги.';
        } else {
            this.nettingErrorTextSync = '';
        }
        return this.nettingErrorTextSync;
    }

    // показывать предупреждение в блоке неттинг
    get isShowErrorText(): boolean {
        return this.errorTextNetting && this.editingMode;
    }

    // получение списка неоплаченных счетов
    async getUnpaidInvoices(offset: number, limit: number): Promise<void> {
        try {
            const advertiserId = this.invoiceSync.advertiser!.id;
            const { meta, data } = await getUnpaidInvoices(advertiserId, { offset, limit });
            if (data) {
                data!.forEach((item) => {
                    // получаем курс валюты неоплаченных счетов
                    const course = this.transferCourseCurrency(item);
                    this.$set(item, 'course', course);
                    this.invoiceSync.netting!.some((n) => n.affiliate_invoice_id === item.affiliate_invoice_id)
                        ? this.$set(item, 'disabled', true)
                        : this.$set(item, 'disabled', false);
                });
                this.unpaidAffiliateAccounts = data;
                this.totalElementDataTable = meta!.total || 0;
            }
            this.isUnpaidAffiliateAccountsReceived = true;
        } catch (err) {
            store.commit('SET_SERVER_ERROR', { status: err.status, message: 'Неоплаченные счета не загружены' });
        }
    }

    get editedTableHeaders(): any {
        const tableHeaders = [
            {
                text: 'ID счёта',
                align: 'left',
                sortable: true,
                value: 'affiliate_invoice_id',
                class: 'primary--text font-weight-regular subtitle-2',
            },
            {
                text: 'ID партнёра',
                align: 'left',
                sortable: true,
                value: 'affiliate_id',
                class: 'primary--text font-weight-regular subtitle-2',
            },
            {
                text: 'Сумма счета',
                align: 'right',
                sortable: true,
                value: 'amount',
                class: 'primary--text font-weight-regular subtitle-2',
            },
            {
                text: 'Сумма оплаты',
                value: 'rate_paid_sum',
                align: 'right',
                class: 'primary--text font-weight-regular subtitle-2',
            },
            {
                text: '',
                value: 'action',
                align: 'right',
                sortable: false,
                class: 'primary--text font-weight-regular subtitle-2',
            },
        ];
        if (this.editingMode) {
            const partialsAmount = {
                text: 'Доступный остаток',
                value: 'partials_amount',
                align: 'right',
                class: 'primary--text font-weight-regular subtitle-2',
            };
            tableHeaders.splice(3, 0, partialsAmount);
        }
        return tableHeaders;
    }

    findUnpaidItem(item: INettingItem): any {
        return this.unpaidAffiliateAccounts.find(
            (i: any) => i.affiliate_invoice_id === item.affiliate_invoice_id,
        );
    }

    // расчет доступного остатка
    // для редактируемых строк в неттинге получаем его из таблицы
    // (amount - partial_amount) - значение из инпута
    getPartialsAmount(item: any): number {
        const paidSumRate = item.rate_paid_sum / (Number(item!.course!) || 1);
        const partialsAmount = (item.amount - (item.partials_amount || 0)) - paidSumRate;
        const totalPartialsAmount = partialsAmount * (Number(item!.course) || 1);
        return Math.round(totalPartialsAmount * 100) / 100;
    }

    emitNettingAmount(): void {
        this.nettingActData[0].amount = this.nettingActAmount;
        this.nettingActData[0].paid_sum = this.nettingActAmount;
        this.invoiceSync.netting = this.nettingActData;
    }

    created(): void {
        if (this.invoiceSync?.netting?.[0]) {
            this.type = this.invoiceSync.netting[0]?.type || NETTING_TYPE.NONE;
            switch (this.type) {
            case NETTING_TYPE.WM:
                this.nettingWebMastersData = this.invoiceSync.netting;
                break;
            case NETTING_TYPE.ACT:
                this.nettingActData = this.invoiceSync.netting;
                break;
            default:
                break;
            }
            this.setNettingType(this.type);
        }
        // получаем список неоплаченных счетов
        this.getUnpaidInvoices(0, 10);
        // клонируем массив объектов чтобы посчитать доступный остаток при редактировании
        this.copyNettingTableItems = this.invoiceSync.netting!.map((a) => ({ ...a }));
        this.nettingActAmount = this.invoiceSync.netting![0]?.type
        && this.invoiceSync.netting![0]?.type === NETTING_TYPE.ACT
            ? this.invoiceSync.netting![0].amount as number
            : 0;
    }

    addUnpaidAccount(item: INettingItem): void {
        const isItemAlreadyAdded = !!this.invoiceSync.netting!.find((n) => n.affiliate_invoice_id === item.affiliate_invoice_id);
        if (isItemAlreadyAdded) {
            this.showWarning = true;
            return;
        }
        const newItem: INettingItem | undefined = this.unpaidAffiliateAccounts.find(
            (a: INettingItem) => a.affiliate_invoice_id === item.affiliate_invoice_id,
        );
        if (newItem) {
            newItem!.type = NETTING_TYPE.WM;
            const balance = Math.round((item!.amount! - item!.partials_amount!)! * 100) / 100;
            this.$set(newItem, 'paid_sum', balance);
            this.invoiceSync.netting!.push(newItem);
        }
        this.setUnsavedDataState(true);
    }

    setNettingType(nettingType: string): void {
        this.setType(nettingType);
        if (this.nettingWasUpdated) {
            this.setUnsavedDataState(true);
        }
        this.nettingWasUpdated = true;
    }

    changePaidSum(item: any): void {
        item.paid_sum = Number(item.course) ? Math.round((Number(item.rate_paid_sum) / item.course!)! * 100) / 100 : Number(item.rate_paid_sum);
    }

    deleteItem(): void {
        this.deletingNetting!.disabled = false;
        const disabledItem = this.unpaidAffiliateAccounts.find(
            (a: INettingItem) => a.affiliate_invoice_id === this.deletingNetting.affiliate_invoice_id,
        );
        if (disabledItem) {
            disabledItem.disabled = false;
        }
        const index = this.invoiceSync.netting!.indexOf(this.deletingNetting);
        this.invoiceSync.netting!.splice(index, 1);
        this.setUnsavedDataState(true);
        this.deletingNetting = null;
    }

    unpaidInvoices(offset: number, limit: number, isChangeLimit?: boolean): void {
        if (offset === 0 || isChangeLimit) {
            eventBus.$emit('go-to-first-page');
        }
        this.getUnpaidInvoices(offset, limit);
    }

    createMask(item: INettingItem, numberOfSigns = 6): void {
        this.mask = String(item.rate_paid_sum).replace(/[^\d.-]/g, '');
        this.mask = this.mask.split('.')[0].slice(0, numberOfSigns);
        this.mask = `${this.mask.replace(/\d/g, '#')}.##`;
        this.nettingId = item.affiliate_invoice_id as number;
    }
}
