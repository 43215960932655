import { IAffiliateItem, IAffiliateLastLogin, IAffiliateManager, IMessenger, INote, ITag } from '@/api/types/user';
import {
    createUserNote,
    deleteUserNote,
    editUserNote, getEmployeesAffiliates,
    getUserNotes, saveAffiliateTags,
} from '@/api/user';
import { showNotification, showServerError } from "@/utils";
import { getCurrencyIcon } from "@/utils/translate";
import {
    approveRequestAffiliateMethod,
    getEmployeeAffiliateMethods, getEmployeeBalance, getEmployeeNearestPayouts, getInvoicesSummary, getPayoutSummary,
    makeDefaultAffiliateMethod,
    rejectRequestAffiliateMethod,
} from "@/api/payout";
import { IEmployeeAffiliateMethod } from "@/api/types/payout";
import { getFormatDate } from "@/utils/formatDates";
import { postEmployeeExtendedReport } from "@/api/stats";
import { IReport } from "@/api/types/stats";

export const translateAffiliateField = (key:string): string => {
    switch (key) {
    case 'Affiliate': return 'Партнёр';
    case 'AffiliateSettings': return 'Настройки партнёра';
    case 'Note': return 'Заметка';
    case 'PartnerMethod': return 'Способ выплаты';
    case 'User': return 'Партнёр';
    case 'UserSettings': return 'Настройки';
    case 'accountId': return 'Account ID';
    case 'account_id': return 'Account ID';
    case 'advance_payments': return 'Кабинет: Досрочки';
    case 'all': return 'Создание';
    case 'birthday': return 'Дата рождения';
    case 'city': return 'Город';
    case 'comment': return 'Комментарий';
    case 'country': return 'Страна';
    case 'deletedAt': return 'Дата удаления';
    case 'email': return 'Email';
    case 'emailVerified': return 'Статус email';
    case 'email_verified': return 'Подтверждение почты';
    case 'firstName': return 'Имя';
    case 'first_name': return 'Имя';
    case 'id': return 'ID';
    case 'image': return 'Изображение';
    case 'invoice_master': return 'Мастер счета';
    case 'isDefault': return 'Основной';
    case 'is_default': return 'Основной';
    case 'is_deleted': return 'Удаление';
    case 'keyName': return 'Поле';
    case 'language': return 'Язык';
    case 'lastName': return 'Фамилия';
    case 'last_name': return 'Фамилия';
    case 'manager': return 'ID менеджера';
    case 'managerData': return 'Данные менеджера';
    case 'managerId': return 'ID менеджера';
    case 'MasterAccount':
    case 'masterId': return 'Мастер-аккаунт';
    case 'master': return 'Мастер';
    case 'slaves': return 'Слейв';
    case 'master_extension': return 'Расширенный МА';
    case 'master_id': return 'Мастер-аккаунт';
    case 'messengers': return 'Мессенджеры';
    case 'methodSetting': return 'Способ выплаты';
    case 'nick_name': return 'Никнейм';
    case 'nickname': return 'Никнейм';
    case 'note': return 'Текст заметки';
    case 'open_erid': return 'Кабинет: Мар-ка EN';
    case 'partnerData': return 'Данные партнёра';
    case 'partnerId': return 'ID партнёра';
    case 'partnerManagerId': return 'ID менеджера партнёра';
    case 'partner_manager_id': return 'Менеджер партнёра';
    case 'phone': return 'Телефон';
    case 'phoneVerified': return 'Статус телефона';
    case 'phone_verified': return 'Подтверждение телефона';
    case 'status': return 'Статус';
    case 'tags': return 'Теги';
    case 'theme': return 'Тема';
    case 'trafficSources': return 'Источники трафика';
    case 'traffic_sources': return 'Источники трафика';
    case 'two_factor_enable': return '2fa';
    case 'utms': return 'UTM-метки';
    case 'value': return 'Значение';
    case 'verticals': return 'Вертикаль';
    default: return key;
    }
};

export interface IAffiliateBalance {
    currency: string;
    limit: string | number;
    date: string;
    amount: string | number;
    invoiceSum: string | number;
    payoutSum: string | number;
}

export class Affiliate implements IAffiliateItem {
    id?: number;
    user_id?: number;
    first_name: string = '';
    last_name: string = '';
    full_name: string = '';
    nickname: string = '';
    is_master: boolean | number = 0;
    newcomer: boolean | number = 0;
    master_id: number | null = null;
    master_account_name: string = '';
    email?: string;
    email_verified: boolean = false;
    phone: string = '';
    phone_verified: boolean = false;
    manager?: IAffiliateManager;
    verticals: { id: number; name: string }[] = [];
    country: string = '';
    city: string = '';
    language: string = '';
    status: string = '';
    traffic_sources: {
        id: any;
        name: string;
    }[] | [] = [];
    referer_url: string = '';
    signup_at: string = '';
    created_at: string = '';
    updated_at: string = '';
    last_login: IAffiliateLastLogin = {
        created_at: '',
        expires_at: '',
        id: 0,
        ip: null,
        user_agent: null,
    };
    birthday: null | string = '';
    theme: string = '';
    messenger?: IMessenger;
    account_id?: number | undefined;
    account_type?: string | undefined;
    payment_info?: IEmployeeAffiliateMethod[];
    isLoadingData: boolean = false;
    notes?: INote[];
    balance?: IAffiliateBalance[];
    master_group?: {id: number, name: string}[];
    statistic?: IReport[];
    open_erid: string = '';
    advance_payments: string = '';
    master_extension: string = '';
    invoice_master: string = '';
    tags: ITag[] = [];
    currencyList: string[] = [];
    two_factor_enabled: number = 0;
    roles: string[] = [];

    constructor(affiliate: IAffiliateItem) {
        if (affiliate !== undefined) {
            this.id = affiliate.id;
            this.user_id = affiliate.user_id;
            this.first_name = affiliate.first_name;
            this.last_name = affiliate.last_name;
            this.full_name = this.getName;
            this.nickname = affiliate.nickname;
            this.is_master = affiliate.is_master;
            this.newcomer = affiliate.newcomer;
            this.master_id = affiliate.master_id;
            this.master_account_name = affiliate.master_account_name;
            this.email = affiliate.email;
            this.email_verified = !!affiliate.email_verified;
            this.phone = affiliate.phone;
            this.phone_verified = affiliate.phone_verified;
            this.manager = affiliate.manager;
            this.verticals = affiliate.verticals;
            this.country = affiliate.country;
            this.city = affiliate.city;
            this.language = affiliate.language;
            this.status = affiliate.status;
            this.traffic_sources = affiliate.traffic_sources;
            this.referer_url = affiliate.referer_url;
            this.signup_at = affiliate.signup_at;
            this.created_at = affiliate.created_at;
            this.updated_at = affiliate.updated_at;
            this.last_login = affiliate.last_login;
            this.messenger = affiliate.messenger;
            this.birthday = affiliate.birthday;
            this.theme = affiliate.theme;
            this.open_erid = affiliate.open_erid;
            this.advance_payments = affiliate.advance_payments;
            this.master_extension = affiliate.master_extension;
            this.invoice_master = affiliate.invoice_master;
            this.tags = affiliate.tags;
            this.two_factor_enabled = affiliate.two_factor_enabled;
            this.roles = affiliate.roles;
        }
    }

    private get getName(): string {
        return `${this.first_name} ${this.last_name}`;
    }

    private async getNotes(): Promise<void> {
        try {
            const { data } = await getUserNotes({ affiliate_id: this.id, limit: 100 });
            this.notes = data.reverse();
        } catch (err) {
            showServerError(err, 'Заметки не загружены');
        }
    }

    async addNote(note: {note: string, image: any}): Promise<void> {
        try {
            await createUserNote(note, this.id!);
            showNotification('Заметка создана');
            await this.getNotes();
        } catch (err) {
            showServerError(err, 'Ошибка создания заметки');
        }
    }

    async editNote(note: INote): Promise<void> {
        try {
            await editUserNote({ note: note.note, image: note.images }, note.id);
            showNotification('Заметка отредактирована');
            await this.getNotes();
        } catch (err) {
            showServerError(err, 'Ошибка редактирования заметки');
        }
    }

    async deleteNote(id: number): Promise<void> {
        try {
            await deleteUserNote(id);
            showNotification('Заметка успешно удалена');
            await this.getNotes();
        } catch (err) {
            showServerError(err, 'Ошибка удаления заметки');
        }
    }

    async saveTags(tags: { tags: number[] }): Promise<void> {
        try {
            await saveAffiliateTags( tags, this.id!);
            showNotification('Теги успешно сохранены');
        } catch (err) {
            showServerError(err, 'Ошибка сохранения тегов');
        }
    }

    async getPaymentInfo(statuses: string[] = [], is_cabinet: boolean | null = null): Promise<void> {
        try {
            const payload: any = { partner_ids: [this.id], limit: 999 };
            if (statuses !== null) payload.statuses = statuses;
            if (typeof is_cabinet === "boolean") payload.is_cabinet = is_cabinet;
            const { data } = await getEmployeeAffiliateMethods(payload);
            this.payment_info = data;
        } catch (err) {
            showServerError(err, 'Ошибка загрузки платёжных данных');
        }
    }

    async makeDefaultMethod(id: string | number): Promise<void> {
        try {
            await makeDefaultAffiliateMethod(id);
            showNotification('Способ выплаты назначен основным');
            await this.getPaymentInfo();
        } catch (err) {
            showServerError(err, 'Ошибка назначения основного способа выплаты');
        }
    }

    async approveMethod(id: string | number, statuses: string[] = [], is_cabinet: boolean | null): Promise<void> {
        try {
            await approveRequestAffiliateMethod(id);
            showNotification('Способ выплаты одобрен');
            await this.getPaymentInfo(statuses,is_cabinet);
        } catch (err) {
            showServerError(err, 'Ошибка одобрения способа выплаты');
        }
    }

    async rejectMethod(id: string | number, statuses: string[] = [], is_cabinet: boolean | null): Promise<void> {
        try {
            await rejectRequestAffiliateMethod(id);
            showNotification('Способ выплаты отклонён');
            await this.getPaymentInfo(statuses,is_cabinet);
        } catch (err) {
            showServerError(err, 'Ошибка отклонения способа выплаты');
        }
    }

    async getBalance(id: number = this.id!, as_master: boolean = false): Promise<void> {
        try {
            const data = await Promise.all([
                getEmployeeNearestPayouts(id, as_master),
                getInvoicesSummary(id, as_master),
                getPayoutSummary(id, as_master),
                getEmployeeBalance(id, as_master),
            ]);
            const all = data.flat();
            const currencies = this.currencyList = [...new Set(all.map(i => i.currency))];
            const balanceData = currencies.map(c => Object.assign({}, ...all.filter(i => i.currency === c)));
            this.balance = balanceData.map(i => {
                return {
                    currency: this.getCurrencySymbol(i.currency),
                    limit: this.moneyFormat(i.approved! || 0),
                    date: !!i.payout_date ? getFormatDate(i.payout_date, 'dd MMMM yyyy') : '-',
                    amount: this.moneyFormat(i.amount! || 0),
                    invoiceSum: this.moneyFormat(i.total_paid! || 0),
                    payoutSum: this.moneyFormat(i.total_done! || 0),
                };
            });
        } catch (err) {
            showServerError(err, 'Ошибка загрузки баланса');
        }
    }

    private async getGroup(id: number = this.id!): Promise<void> {
        try {
            const { data } = await getEmployeesAffiliates({ master_or_slave_ids: [id] });
            const users = data.map((aff) => {
                return {
                    id: aff.id,
                    name: `${aff.id} ${aff.first_name} ${aff.last_name}`,
                };
            });
            if (users.every(u => u.id !== this.id) ) {
                users.unshift({
                    id: this.id,
                    name: `${this.id} ${this.getName}`,
                });
            }
            if (!this.is_master) {
                users.unshift({
                    id: this.master_id!,
                    name: `${this.master_id} ${this.master_account_name}`,
                });
            }
            this.master_group = users as {id: number, name: string}[];
        } catch (err) {
            showServerError(err, 'Ошибка загрузки группы пользователей из мастер аккаунта');
        }
    }

    async getStatistic(id: number = this.id!, cur: string = 'RUB', filter: string = 'dm_affiliate_id'): Promise<void> {
        try {
            const startDate = new Date(new Date().setDate(new Date().getDate() - 7));
            const week = [ getFormatDate(startDate, 'yyyy-MM-dd'), getFormatDate(new Date(), 'yyyy-MM-dd') ];

            const postData: any = {
                columns: [
                    'dm_lead_date',
                    'mt_clicks_total',
                    'mt_clicks_unique',
                    'mt_leads_total',
                    'mt_leads_approved',
                    'mt_leads_pending',
                    'mt_leads_rejected',
                    'mt_cr_u',
                    'mt_cr_t',
                    'mt_epc_u',
                    'mt_epc_t',
                    'mt_ar',
                    'mt_payout_approved',
                ],
                filters: [
                    {
                        column: "dm_lead_date",
                        operator: "between",
                        values: week,
                    },
                    {
                        column: "dm_offer_currency",
                        operator: "in",
                        values: {
                            0: cur,
                        },
                    },
                    {
                        column: filter,
                        operator: "in",
                        values: {
                            0: id,
                        },
                    },
                ],
                sorting: [
                    {
                        column: "dm_lead_date",
                        direction: "asc",
                    },
                ],
            };
            const { data } = await postEmployeeExtendedReport({ data: postData });
            this.statistic = data.report;
        } catch (err) {
            showServerError(err, 'Ошибка загрузки статистики');
        }
    }

    async prepareAffiliateData(): Promise<void> {
        this.isLoadingData = true;
        await Promise.all([
            this.getPaymentInfo(),
            this.getNotes(),
            this.getBalance(this.id, !!this.is_master),
            this.getGroup(),
            this.getStatistic(this.id, 'RUB', !!this.is_master? 'dm_master_account' : 'dm_affiliate_id'),
        ]);
        this.isLoadingData = false;
    }

    private getCurrencySymbol(cur: string): string {
        return `${cur} ${getCurrencyIcon(cur)}`;
    }

    private moneyFormat(value: number | string): string {
        return Number(value).toLocaleString('ru-RU', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        });
    }

    get isMaster(): boolean {
        return !!this.is_master;
    }
}
