
import { Component, Prop, Vue } from 'vue-property-decorator';
import { EmployeeNotification } from "@/services/notifications/EmployeeNotification";
import { translateOffersStatus } from "@/utils/translate";
import { offersTableDetail } from "@/services/TablePage/notifications";
import { getEmployeesOffersV2 } from "@/api/offers";
import { getAdvertisersSearch } from "@/api/revenue";
import SvgOffers from "@/assets/icons/notification/offers.svg";
import { offerStatuses } from "@/mappings/statuses";
import NotificationDetailTable from "@/components/notifications/NotificationDetailTable.vue";
import { IOfferSearch } from "@/api/types/offers";
import { IAdvertiser } from "@/api/types/revenue";
import { initial } from "lodash-es";
import { filterClass } from '@/services/filters/filterClass';
import { ITableStatus } from "@/types";

@Component({
    components: {
        NotificationDetailTable,
        SvgOffers,
    },
})
export default class NotificationOffers extends Vue {
    @Prop({ required: true }) notification!: EmployeeNotification;

    SvgOffers = SvgOffers;
    headers = [...offersTableDetail];
    filters = [
        {
            id: 'legacy_id',
            errorText: 'Оффер не найден',
            itemValue: 'legacy_id',
            template: (item: IOfferSearch): string => item.legacy_id + ' ' + item.name,
            label: 'Оффер',
            clearable: true,
            apiMethod: getEmployeesOffersV2,
            key: 'search',
            isChip: true,
            chipWidth: 120,
            multiple: false,
            select: '',
        },
        {
            id: 'advertiser_id',
            errorText: 'Рекламодатель не найден',
            apiMethod: getAdvertisersSearch,
            multiple: false,
            itemValue: 'id',
            template: (item: IAdvertiser): string => item.id + ' ' + item.name,
            label: 'Рекламодатель',
            clearable: true,
            chipWidth: 120,
            isChip: true,
            select: '',
        },
        new filterClass.Select({
            id: 'status',
            label: 'Статус',
            itemValue: 'value',
            itemText: 'name',
            items: offerStatuses,
            select: '',
            clearable: true,
            remove: true,
            translate: (i) => translateOffersStatus(i).text,
        }),
    ];
    statuses: ITableStatus[] = [
        {
            slot: 'item.status',
            key: 'status',
            translate: translateOffersStatus,
        },
    ];
    routes = [
        {
            routeParam: 'id',
            extraRouteParamName: 'legacy_id',
            slot: 'item.legacy_id',
            routerName: 'offer',
            text: 'legacy_id',
        },
        {
            routeParam: 'id',
            extraRouteParamName: 'advertiser_id',
            slot: 'item.advertiser',
            routerName: 'advertiser',
            text: 'advertiser',
        },
    ];

    actions = [
        {
            title: 'Удалить',
            color: '#1B1B1D',
            icon: 'SvgTrashWhite',
            callback: async({ id }: { id: number }): Promise<void> => {
                await this.notification?.deleteOffer(id);
                await this.notification.editNotification();
            },
        },
    ];

    created(): void {
        if (this.notification.isDisabledEditField) {
            this.headers = initial([...offersTableDetail]);
        }
    }
}
