
import { Vue, Component, Prop, PropSync } from "vue-property-decorator";
import ImageInput from "@/components/base/form/ImageInput.vue";

@Component({
    components: { ImageInput },
})

export default class FormLogo extends Vue {
    @PropSync('product') productSync!: any;
    @Prop({ required: true }) param!: any;
}
