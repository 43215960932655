import { getCurrentDate, getFormatDate } from "@/utils/formatDates";
import { IEvents, INotification, INotificationCategory } from "@/api/types/notifications";
import {
    deleteNotification,
    getNotificationEvents, getNotificationUserIds,
    patchNotification,
    postNotification,
} from "@/api/notifications";
import { GetInfo } from "@/utils/getInfo";
import { getAffiliatesByOffers, getEmployeesOffersV2 } from "@/api/offers";
import uniqBy from "lodash-es/uniqBy";
import { showServerError } from "@/utils";
import uniq from "lodash-es/uniq";

export interface PostNotificationDTO  {
    event?: string;
    message_ru: string;
    message_en:	string;
    published_at: string | null;
    pinned_to:	string | null;
    pinned_from: string | null;
    offers:	number[];
    users: number[];
    send_all?: boolean;
}

export class EmployeeNotification implements INotification {
    id?: number;
    event: string = '';
    category: string = '';
    message_en: string = '';
    message_ru: string = '';
    created_at: string = getCurrentDate();
    updated_at: string | null = null;
    published_at: string | null = null;
    pinned_to: string | null = null;
    pinned_from: string | null = null;
    created_by: {
        id: number;
        name: string;
    } | null = null;
    updated_by: {
        id: number;
        name: string;
    } | null = null;
    lang: string = 'ru';
    offers: {
        id: number;
        legacy_id: number;
        name: string;
        status: string;
        manager: string;
    }[]  = [];
    categories: INotificationCategory[] = [];
    advertiser: number | null = null;
    affiliates: any[] = [];
    userIds: number[] = [];
    offerIds: number[] = [];
    is_deleted: boolean = false;
    send_all = false;

    constructor(notification?: INotification) {
        if (notification !== undefined) {
            this.id = notification.id;
            this.message_ru = notification.message_ru;
            this.message_en = notification.message_en;
            this.created_at = notification.created_at;
            this.updated_at = notification.updated_at;
            this.published_at = notification.published_at;
            this.pinned_to = notification.pinned_to;
            this.pinned_from = notification.pinned_from;
            this.created_by = notification.created_by;
            this.updated_by = notification.updated_by;
            this.lang = notification.lang || 'ru';
            this.offers = [...notification.offers];
            this.is_deleted = notification.is_deleted;
            this.offerIds = this.getOfferIds();
        }
        (async () => {
            await this.getCategories();
            if (notification !== undefined) {
                this.category = this.getValueCategory(notification.category);
                this.event = this.getValueEvent(notification.event);
                await this.getUsers();
            }
        })();
    }

    translateLang(item: string): string {
        switch (item) {
        case 'ru':
            return 'Русский';
        case 'en':
            return 'Английский';
        default:
            return item;
        }
    }

    getValueCategory(category: string): string{
        return this.categories!.find(i => i.category.name === category)!.category.value || category;
    }

    getNameCategory(): string{
        return this.categories!.find(i => i.category.value === this.category)!.category.name || this.category;
    }

    getValueEvent(event: string): string {
        return this.getPossibleEvents().find(i => i.name === event)!.value || event;
    }

    getNameEvent(): string {
        return this.getPossibleEvents().find(i => i.value === this.event)!.name || this.event;
    }

    get isDisabledEditField(): boolean {
        return  !this.category || (new Date(this.published_at!) < new Date() && !!this.id);
    }

    get isOffer(): boolean {
        return this.category === 'offer';
    }

    get isFullOffers(): boolean {
        return this.offers.length > 0;
    }

    get isFullAffiliates(): boolean {
        return this.affiliates.length > 0;
    }

    async getCategories(): Promise<void> {
        try {
            this.categories = await getNotificationEvents();
        } catch(err) {
            this.categories = [];
            showServerError(err, 'Категории не получены');
        }
    }

    getEventType(): string | undefined {
        return this.getPossibleEvents().find(i => i.value === this.event)!.type;
    }

    async getUsers(): Promise<void> {
        try {
            this.userIds = await getNotificationUserIds(this.id as number);
        } catch(err) {
            this.affiliates = [];
            showServerError(err, 'Партнёры не получены');
        }
    }

    getPossibleEvents(): IEvents[] {
        return this.categories.find(i => i.category.value === this.category)?.events || [];
    }

    getPossibleManualEvents(): IEvents[] {
        return this.getPossibleEvents().filter(e => e.type === 'manual');
    }

    getUserIds(): any[] {
        return this.isFullAffiliates ? this.affiliates.map(i => i.id): [];
    }

    getOfferIds(): number[] {
        return this.isFullOffers ? this.offers.map(i => i.legacy_id): [];
    }

    async getDataToSend(): Promise<any> {
        let users: number[] = this.getUserIds();
        // отправляем и старые id, и новые
        const offers: number[] = uniq([...this.offerIds, ...this.getOfferIds()]) || [];
        if (this.isOffer && offers.length > 0 && !this.send_all) {
            users = await getAffiliatesByOffers({ offers }) || [];
        }
        return { users , offers };
    }

    getPrepareMessage(message: string): string {
        const prepareMessage = message.replace(/<p>(<br>|&nbsp;|\s)*<\/p>/g, '');
        return prepareMessage.replace('<br><br>', '<br>');
    }

    async createNotification(): Promise<void> {
        const { offers, users } = await this.getDataToSend();
        const moscowTime = new Date(new Date().toLocaleString("en-US", { timeZone: "Europe/Moscow" }));
        if (new Date(this.published_at!) < moscowTime) this.published_at = getFormatDate(moscowTime, 'yyyy-MM-dd HH:mm');
        const messageRu = this.getPrepareMessage(this.message_ru);
        const messageEn = this.getPrepareMessage(this.message_en);
        await postNotification({
            event: this.event,
            message_ru: messageRu,
            message_en:	messageEn,
            published_at: this.published_at,
            pinned_to:	this.pinned_to ? this.pinned_to : null,
            pinned_from: this.pinned_from ? this.pinned_from : null,
            offers: offers || [],
            users: users || [],
            send_all: this.send_all,
        });
    }

    async editNotification(): Promise<void> {
        const { offers, users } = await this.getDataToSend();
        const messageRu = this.getPrepareMessage(this.message_ru);
        const messageEn = this.getPrepareMessage(this.message_en);
        // отправляем и старые id, и новые
        const filteredUsers = uniq([...this.userIds, ...users]).filter(Boolean) || [];
        await patchNotification(this.id as number, {
            message_ru: messageRu,
            message_en:	messageEn,
            published_at: this.published_at,
            pinned_to: this.pinned_to ? this.pinned_to : null,
            pinned_from: this.pinned_from ? this.pinned_from : null,
            offers: offers || [],
            users: filteredUsers,
            send_all: this.send_all,
        });
    }

    async addOffers(offers: any, advertiserOffers: any) : Promise<void> {
        let offersCompleted: any[] = [];
        if (offers?.length > 0) {
            offersCompleted = await new GetInfo(offers, 'legacy_id', getEmployeesOffersV2).getInfoByFields('legacy_id');
        }
        const concatOffers = uniqBy([...this.offers,...advertiserOffers, ...offersCompleted], 'legacy_id');
        this.offers = [...new Set([...concatOffers])];
    }

    addAffiliates(affiliates: any): void {
        const concatAffiliates = uniqBy([...this.affiliates, ...affiliates], 'id');
        this.affiliates = [...new Set([...concatAffiliates])];
    }

    deleteAffiliate(id: number): void {
        const index =  this.affiliates.findIndex(i => i.id === id);
        this.affiliates.splice(index, 1);
    }

    deleteOffer(id: number): void {
        const index =  this.offers.findIndex(i => i.id === id);
        this.offers.splice(index, 1);
    }

    async deleteNotification(id: string | number = this.id!): Promise<void> {
        try {
            await deleteNotification(id);
            this.is_deleted = true;
        } catch(err) {
            showServerError(err, 'Уведомление не удалено');
        }
    }
}
