
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import SvgRangeCalendar from '@/assets/icons/range-calendar.svg';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import TrashBtn from '@/components/base/buttons/TrashBtn.vue';
import { getFormatDate, getCurrentDate } from '@/utils/formatDates';
import TimePicker from '@/components/base/pickers/TimePicker.vue';
import SvgTrash from '@/assets/icons/trash.svg';
import { delay } from "@/utils";

@Component({
    components: {
        DatePicker,
        TimePicker,
        TrashBtn,
        SvgRangeCalendar,
        SvgTrash,
    },
    methods: { getFormatDate, getCurrentDate },
})
export default class DateTimePickerNew extends Vue {
    @PropSync('value', { required: true }) valueSync!: string;
    @Prop({ default: '' }) propValue!: string;
    @Prop({ default: false }) isInline!: boolean;
    @Prop({ default: false }) isCanChangeTimeOnly!: boolean;
    @Prop({ default: true }) isDefaultDate!: boolean;
    @Prop({ default: '' }) defaultDate!: string;
    @Prop({ default: false }) emptyIsAllowed!: boolean;
    @Prop({ default: 'Период' }) placeholder!: string;
    @Prop({ default: '' }) label!: string;
    @Prop({ default: true }) isIcon!: boolean;
    @Prop({ default: false }) disabled!: boolean;
    @Prop({ default: false }) clearable!: boolean;
    @Prop({ default: false }) withoutTime!: boolean;
    @Prop({ default: false }) isOnlyHour!: boolean;
    @Prop({ type: Function, default: () => ({}) }) readonly availableDates!: () => any;

    date = new Date().toString();
    time: {hour: number | string, minutes: string | number} = {
        hour: new Date().getHours(),
        minutes: new Date().getMinutes(),
    };
    menu = false;
    manualDate = '';
    isClear = false;

    async clearDate(): Promise<void> {
        this.isClear = true;
        const now = new Date();
        this.date = this.isDefaultDate ? this.getDefaultDate : now.toString();
        this.time.hour = now.getHours();
        this.time.minutes = now.getMinutes();
        const defaultDate = this.isDefaultDate ? this.getDefaultDate : '';
        this.manualDate = defaultDate ? getFormatDate(new Date(defaultDate), 'dd.MM.yyyy') : '';
        this.$emit('input', defaultDate);
        await delay(500);
        this.isClear = false;
    }

    closeModal(): void {
        this.manualDate = '';
        this.menu = false;
    }

    manualDateInput(): void {
        const [day, month, year] = this.manualDate.split('.');
        const rawDate = `${month}/${day}/${year}`;
        const date = new Date(rawDate);
        const isAvailableDate = this.availableDates()!.days ? this.availableDates()!.days.includes(date.getDate()) : true;
        const isEndDate = this.availableDates().end  ? date <= new Date(this.availableDates().end) : true;
        const isStartDate = this.availableDates().start  ? date >= new Date(this.availableDates().start) : true;
        if (isAvailableDate && isEndDate && isStartDate) {
            const newDate = new Date(date);
            (this.$refs.calendar as Vue & { move: (Date) => void }).move(newDate);
            this.date = newDate.toString();
        } else {
            this.manualDate = this.getCurrentDateValue;
        }
    }

    get getCurrentDateValue(): string {
        return getFormatDate(new Date(this.date), 'dd.MM.yyyy');
    }

    updateManualDate(): void {
        this.manualDate = this.getCurrentDateValue;
    }

    get valueTime(): Date | string {
        if (this.valueSync) {
            return getFormatDate(new Date(this.valueSync), 'HH:mm') || '';
        } else {
            return this.isDefaultDate ? getCurrentDate('HH:mm') : !!this.manualDate ? `${this.time.hour}:${this.time.minutes}` : '';
        }
    }

    get getIsDisabledDates(): boolean {
        return (this.availableDates()!.days &&
            this.availableDates()!.days.includes(new Date(this.date).getDate())) ||
                !this.availableDates()!.days;
    }

    submitDateAfterOutsideClick(): void {
        if (!this.isDefaultDate) return;
        if (!this.getIsDisabledDates) {
            if (!this.menu) {
                this.manualDate = this.getCurrentDateValue;
                this.submitDate();
            }
        }
    }

    submitDate(): void {
        if (!this.isDefaultDate && !this.manualDate)  {
            this.menu = false;
            return;
        }
        const selectedDate = new Date(this.date);
        let formattingSelectedDate = getFormatDate(selectedDate, 'yyyy-MM-dd');

        if (!this.withoutTime) {
            selectedDate.setHours(+this.time.hour);
            selectedDate.setMinutes(this.time.minutes as number);
            formattingSelectedDate = getFormatDate(selectedDate, 'yyyy-MM-dd HH:mm');
        }
        this.$emit('input', formattingSelectedDate);
        this.menu = false;
        this.$set(this.$props, 'valueSync', formattingSelectedDate);
    }

    get getDefaultDate(): string {
        const format = this.withoutTime ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH:mm';
        if (this.defaultDate) return getFormatDate(this.defaultDate, format);
        if (!this.availableDates()!.days) return getCurrentDate(format);

        const availableDays = this.availableDates()!.days;
        const currentDay = new Date().getDate();
        if (availableDays.includes(currentDay)) {
            return getCurrentDate(format);
        } else {
            const lastDay = availableDays[availableDays.length - 1];
            const date = (index: number) => new Date(new Date().setDate(availableDays[index]));
            if (lastDay! < currentDay) return getFormatDate(date(0), format);
            const getDate = (i: number) => {
                if (availableDays[i] > currentDay) return getFormatDate(date(i), format);
                return getDate(++i);
            };
            return getDate(0);
        }
    }

    created(): void {
        if (!this.valueSync) {
            const defaultDate = this.isDefaultDate && !this.emptyIsAllowed ? this.getDefaultDate : null;
            this.date = this.isDefaultDate && !this.emptyIsAllowed ? this.getDefaultDate : this.emptyIsAllowed ? '' : new Date().toString();
            if (this.isDefaultDate && !this.emptyIsAllowed) this.$emit('input', defaultDate);
            this.manualDate = this.emptyIsAllowed ? '' : this.getCurrentDateValue;
        } else {
            this.date = new Date(this.valueSync).toString();
            this.time = {
                hour: new Date(this.date).getHours(),
                minutes: new Date(this.date).getMinutes(),
            };
            this.manualDate = this.getCurrentDateValue;
        }
    }

    handleUpdateDateTime(time: { hour: string | number, minutes: string | number }): void {
        if (this.isClear || !this.getIsDisabledDates) return;
        const { year, month, day } = {
            year: new Date().getFullYear(),
            month: new Date().getMonth(),
            day: this.availableDates()!.days?.[0] || 1,
        };

        if (!this.date) {
            this.date = !this.getIsDisabledDates ?
                new Date().toString() : this.date = new Date(year, month, day).toString();
        }
        this.time = time;
        this.updateManualDate();
        this.$emit('dayclick', this.date);
    }
}
