
import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import BaseAutocomplete from "@/components/base/design/BaseAutocomplete.vue";
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import TagSelect from "@/components/base/TagSelect.vue";
import { createOfferTag, getOfferTags } from "@/api/offers";
import DateTimePickerNew from "@/components/base/pickers/DateTimePickerNew.vue";
import SvgRangeCalendar from "@/assets/icons/range-calendar.svg";
import { getFormatDate } from "@/utils/formatDates";
import EditModeTextField from "@/components/base/form/EditModeTextField.vue";
import { CreateOffer, IOfferFiled, OFFER_FIELD } from "@/services/offers/CreateOffer";
import { CreateGoal } from "@/services/offers/CreateGoal";
import { ICountry } from "@/api/types/catalogue";
import { namespace } from "vuex-class";
import { addDays, addYears } from "date-fns";
import CookiesSelect from "@/components/base/form/CookiesSelect.vue";
import cookiesSelectItems from "@/mappings/cookies-select-items";
import MaskedTextField from "@/components/base/form/MaskedTextField.vue";
import QuestionMarkTooltip from "@/components_v3/base/QuestionMarkTooltip.vue";

const catalogue = namespace('catalogueModule');

@Component({
    components: {
        MaskedTextField,
        CookiesSelect,
        EditModeTextField,
        SvgRangeCalendar,
        DateTimePickerNew,
        TagSelect,
        BaseSelect,
        BaseAutocomplete,
        QuestionMarkTooltip,
    },
})

export default class createOfferFields extends Vue {
    @Prop({ required: true }) options!: IOfferFiled[];
    @Prop({ default: false }) isDefaultGoal!: boolean;
    @PropSync('offer', { required: true }) offerSync!: CreateOffer;
    @PropSync('goal', { required: false }) goalSync!: CreateGoal | undefined;
    @catalogue.State('countries') countries!: ICountry[];

    OFFER_FIELD = OFFER_FIELD;
    getOfferTags = getOfferTags;
    createOfferTag = createOfferTag;
    cookiesSelectItems = cookiesSelectItems;

    cookiesValue = 0;
    userCookiesValue = 0;
    oneMonthCookie = 672;

    get getOptions(): IOfferFiled[] {
        return this.cookiesValue === 0 ? this.options : this.options.filter(i => i.type !== OFFER_FIELD.USER_COOKIES);
    }

    get getEditableEntity(): CreateOffer | CreateGoal {
        if (this.goalSync !== undefined && !this.isDefaultGoal) return this.goalSync;
        return this.offerSync;
    }

    getDigitsCountAndSymbol(property: string): { count: number, symbol: string } {
        const fixed = { count: 6, symbol: this.offerSync.currency };
        const percent = { count: 2, symbol: '%' };
        const def = { count: 6, symbol: '' };

        const getResult = (property: string) => {
            return  this.getEditableEntity[property] === undefined ? def :
                this.getEditableEntity[property] === 'fixed' ? fixed : percent;
        };

        if (['revenue_amount', 'revenue'].includes(property)) return getResult('revenue_type');
        if (['payout_amount', 'payout'].includes(property)) return getResult('payout_type');
        return def;
    }

    get getDefaultDate(): Date {
        return addYears(new Date(), 5);
    }

    availableDates(): { start: Date, end: null } {
        const start = addDays(new Date(), 1);
        return { start, end: null };
    }

    changeValue(property: string, value: any): void {
        const arrayValues = ['verticals', 'departments', 'traffics', 'payouts', 'stats'];
        this.getEditableEntity[property] = arrayValues.includes(property) ? [value] : value;
    }

    setDate(property: string, value: string): void {
        this.offerSync[property] = getFormatDate(value, 'yyyy-MM-dd HH:mm');
    }

    mounted(): void {
        setTimeout(() => this.selectCookies(this.oneMonthCookie), 700);
    }

    get getCookiesDefaultValues(): { [key: number]: string } {
        return Object.fromEntries(this.cookiesSelectItems.map(i => ([i.id, i.text]))) ;
    }

    selectCookies(value: number): void {
        const isDefValue = this.getCookiesDefaultValues[value];
        if (isDefValue && value !== 0) {
            this.offerSync.session_hours = value;
            this.cookiesValue = value;
        } else {
            this.cookiesValue = 0;
            this.userCookiesValue = value;
            this.offerSync.session_hours = value;
        }
    }

    selectUserCookies(value: number): void {
        const val = value > 17119 ? 17119 : value;
        this.userCookiesValue = val;
        this.offerSync.session_hours = val;
    }
}

