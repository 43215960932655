
import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';
import { OfferSingle } from "@/services/offers/OfferSingle";
import { eventBus } from "@/eventbus";
import ImageInput from "@/components/base/form/ImageInput.vue";
import BaseCard from "@/components/base/BaseCard.vue";
import { addOfferLogo, addOfferLogoV2, addOfferRoundLogo } from "@/api/offers";
import { showServerError } from "@/utils";
import QuestionMarkTooltip from "@/components_v3/base/QuestionMarkTooltip.vue";

interface IHeaderOffer {
    id: number;
    name: string;
    value: any;
    newValue: string;
    api: Function;
    update: Function;
    isRound: boolean;
    tooltip?: string;
    tooltipWidth?: string
}

@Component({
    components: {
        QuestionMarkTooltip,
        BaseCard,
        ImageInput,
    },
})
export default class OfferCardLogo extends Vue {
    @Prop({ required: true }) offer!: OfferSingle;
    @PropSync('isLoading') isLoadingSync!: boolean;

    isEdit = false;
    logo: any = null;
    logoToprec: any = null;
    logoRound: any = null;
    validFlags = {};

    get getOptions(): IHeaderOffer[] {
        return [
            {
                id: 0,
                name: 'Основной',
                value: this.offer.logo,
                newValue: this.logo,
                api: addOfferLogoV2,
                isRound: false,
                update: (file) => this.logo = file,
                tooltip: 'Отображается на детальной странице оффера и в списке.',
                tooltipWidth: '215',
            },
            {
                id: 1,
                name: 'Дополнительный',
                value: this.offer.logo_toprec,
                newValue: this.logoToprec,
                api: addOfferLogo,
                isRound: false,
                update: (file) => this.logoToprec = file,
                tooltip: 'Используется в топе офферов и в рекомендациях на дашборде, а также при маркировке офферов. На основании данного логотипа создаётся креатив по умолчанию. При изменении дополнительного логотипа автоматически, оффер будет перемаркирован и для него будет создан новый erid.',
                tooltipWidth: this.$vuetify.breakpoint.width < 600 ? '200' : '320',
            },
            {
                id: 2,
                name: 'Круглый',
                value: this.offer.logo_round,
                newValue: this.logoRound,
                api: addOfferRoundLogo,
                isRound: true,
                update: (file) => this.logoRound = file,
                tooltip: 'Предназначен для виджетов.',
            },
        ];
    }


    get isDisabled(): boolean {
        return this.isEdit ? this.offer.isDisabledOtherCards || Object.values(this.validFlags).some(i => i === false) : false;
    }

    editInfo(edit: boolean): void {
        if (!edit) {
            this.updateAllOfferData();
        }
        this.isEdit = edit;
    }

    cancelEdit(): void {
        eventBus.$emit('clear-image-input');
        this.isEdit = false;
    }

    updateLogo(file: any, item: IHeaderOffer): void {
        item.newValue = file;
        item.update(file);
    }

    async updateAllOfferData(): Promise<void> {
        this.isLoadingSync = true;
        try {
            for (const item of this.getOptions) {
                if (item.newValue) {
                    await item.api(item.newValue, this.offer.legacy_id!);
                }
            }
            if (this.getOptions.some(i => i.newValue !== null)) eventBus.$emit("update-offer");
            eventBus.$emit('clear-image-input');
        } catch (err) {
            showServerError(err, 'Ошибка загрузки логотипа');
        } finally {
            this.isLoadingSync = false;
        }
    }
}
