
import {
    Component, Vue, Prop, PropSync,
} from 'vue-property-decorator';
import { Getter, Mutation, namespace } from 'vuex-class';
import CardMaterial from '@/components/base/CardMaterial.vue';
import AddingServices from '@/components/revenue/AddingServices.vue';
import TooltipButton from '@/components/base/buttons/TooltipButton.vue';
import FormModal from '@/components/base/FormModal.vue';
import {
    IOfferService,
    ICustomerOrders,
    IContractor,
    ILegalEntityOrg,
} from '@/api/types/revenue';
import { eventBus } from '@/eventbus';
import Documents from '@/components/revenue/invoice/Documents.vue';
import Status from '@/components/base/Status.vue';
import CustomerOrderTop from '@/components/revenue/invoice/CustomerOrderTop.vue';
import PaymentCalendar from '@/components/revenue/invoice/PaymentCalendar.vue';
import Payments from '@/components/revenue/invoice/Payments.vue';
import PaymentInvoices from '@/components/revenue/invoice/PaymentInvoicesList.vue';

const revenue = namespace('revenueModule');

@Component({
    components: {
        PaymentCalendar,
        Payments,
        CustomerOrderTop,
        Documents,
        AddingServices,
        CardMaterial,
        TooltipButton,
        FormModal,
        Status,
        PaymentInvoices,
    },
})

export default class CustomerOrder extends Vue {
    @Getter('GET_EDITING_MODE') editingMode;
    @Mutation('SET_UNSAVED_DATA_STATE') setUnsavedDataState;
    @revenue.Mutation('SET_ACCOUNTING_AMOUNT') setAccountingAmount;

    // Посчитать сумму услуг по офферам и прочих услуг бухгалтерского счёта
    get getTotalAmountOffersAndServicesList(): number {
        const result = Number(this.getTotalAmountOffersList()) + Number(this.getTotalAmountServicesList()) || 0;
        this.$emit('changed-sum', result, this.$props.accountIndex);

        this.setAccountingAmount(
            {
                index: this.$props.accountIndex,
                fieldName: 'amountOfTotals',
                result,
            },
        );

        return result;
    }

    get isPaymentCalendars(): boolean {
        return this.customerOrderSync?.payment_calendars!.length > 0;
    }

    get isOtherServices(): boolean {
        return this.customerOrderSync?.other_services!.length > 0;
    }

    get isOfferServices(): boolean {
        return this.customerOrderSync?.offer_services!.length > 0;
    }

    get isShowDocuments(): boolean {
        return (this.customerOrderSync.files!.length > 0) || this.editingMode;
    }

    get showOfferServices(): boolean {
        return this.getServiceVisibility('offer_services');
    }

    get showOtherServices(): boolean {
        return this.getServiceVisibility('other_services');
    }

    get accountingIsDisabled(): boolean {
        if (
            (this.customerOrderSync.status === 'paid'
            || this.customerOrderSync.status === 'partly_paid')
            && !this.$props.isPrepayment
        ) {
            return true;
        }
        return !this.editingMode;
    }

    get modalText(): string {
        return this.typeModal === 'payment_calendars'
            ? 'При редактировании календарь выплат будет сброшен'
            : 'Вы действительно хотите удалить услуги из заказа покупателя?';
    }

    // Посчитать сумму прямой выручки
    get getTotalDirectIncomeOfOneAccount(): number {
        return this.customerOrderSync.offer_services!
            .filter((offer: IOfferService) => offer.offer_direct)
            .map((item: IOfferService) => item.total)
            .reduce((a: number, b) => Number(a) + Number(b), 0);
    }

    @PropSync('customerOrder', { required: false }) customerOrderSync!: ICustomerOrders;

    @Prop({ required: false }) offersListForSelect!: [];
    @Prop({ required: false }) servicesListForSelect!: [];
    @Prop({ required: false }) accountIndex!: number;
    @Prop({ required: false }) accountsLength!: number;
    @Prop({ required: false }) isInvalidSelect!: boolean;
    @Prop({ required: false }) isNewInvoice!: boolean;
    @Prop({ required: false, default: false }) isFinmedia!: string;
    @Prop({ required: false }) financialStatus!: string;
    @Prop({ type: Boolean, default: false }) isDraftInvoice!: boolean;
    @Prop({ default: false }) isPrepayment!: boolean;
    @Prop({ type: String, default: '' }) getCurrencyIcon!: string;

    // Заголовки колонок в таблице услуг по офферам внутри бухгалтерского счёта
    // с возможность указать прямую выручку
    accountAddingServicesHeaders = [
        { text: 'Прямая выручка', value: 'offer_direct' },
        { text: 'Оффер', value: 'offer' },
        { text: 'Цель', align: 'left', value: 'goal' },
        { text: 'Ставка', align: 'right', value: 'offer_rate' },
        { text: 'Количество', align: 'right', value: 'offer_qty' },
        { text: 'Итого', align: 'right', value: 'total', width: '200px'  },
        { text: '', align: 'right', value: 'actions' },
    ];

    otherServicesHeaders = [
        { text: 'Услуга', align: 'left', value: 'service_name' },
        { text: 'Сумма', align: 'left', value: 'service_amount' },
        { text: '', align: 'right', value: 'actions' },
    ];

    finmediaOtherServicesHeaders = [
        { text: 'Услуга', align: 'left', value: 'service_name' },
        { text: 'Цена', align: 'left', value: 'service_amount' },
        { text: 'Количество', align: 'left', value: 'service_qty' },
        { text: 'Итого', align: 'right', value: 'total' },
        { text: '', align: 'right', value: 'actions' },
    ];

    showModal = false;
    typeModal: 'payment_calendars' | 'other_services' | 'offer_services' = 'payment_calendars';

    openModal(type: 'payment_calendars' | 'other_services' | 'offer_services' ): void {
        this.typeModal = type;
        this.showModal = true;
    }

    deleteDataFromModal(): void {
        this.customerOrderSync[this.typeModal] = [];
        this.setUnsavedDataState(true);
        this.showModal = false;
    }

    changeModalVisibility(index: number): void {
        if (index === this.$props.accountIndex && this.isPaymentCalendars) {
            this.openModal('payment_calendars');
        }
    }

    async created(): Promise<void> {
        if (this.customerOrderSync) {
            if (!this.customerOrderSync.files) {
                this.$set(this.customerOrderSync, 'files', []);
            }
        }

        eventBus.$on('focus-on-accounting-table-control', this.changeModalVisibility);
    }

    beforeDestroy(): void {
        eventBus.$off('focus-on-accounting-table-control', this.changeModalVisibility);
    }

    getTotalAmountOffersList(): number {
        if (this.customerOrderSync.offer_services) {
            const result = Object.assign(this.customerOrderSync.offer_services
                .map((item) => item.total))
                .reduce((a, b) => Number(a) + Number(b), 0);

            this.setAccountingAmount(
                {
                    index: this.$props.accountIndex,
                    fieldName: 'amountOfOffers',
                    result,
                },
            );
            return result;
        }
        return 0;
    }

    getTotalAmountServicesList(): number | undefined {
        if (this.customerOrderSync.other_services) {
            const result = Object.assign(this.customerOrderSync.other_services
                .map((item) => (this.$props.isFinmedia ? item.total : item.service_amount)))
                .reduce((a, b) => Number(a) + Number(b), 0);
            this.setAccountingAmount(
                {
                    index: this.$props.accountIndex,
                    fieldName: 'amountOfServices',
                    result,
                },
            );
            return result;
        }
    }

    getServiceVisibility(serviceName: string): boolean {
        if (!this.customerOrderSync.id) {
            return true;
        }

        if (this.customerOrderSync[serviceName].length > 0) {
            return true;
        }

        return this.customerOrderSync[serviceName].length === 0 && this.editingMode;
    }

    // установить значение выбранного контрагента или проекта
    setAccountingFields(item: IContractor | string, field: string, setUnsavedData?: boolean): void {
        this.$set(this.customerOrderSync, field, item);
        if (setUnsavedData) {
            this.setUnsavedDataState(true);
        }
    }

    // установить значение выбранного Юр.лица
    setAccountingEntity(item: ILegalEntityOrg): void {
        if (item && typeof item.id !== 'string') {
            this.$set(this.customerOrderSync, 'legal_id', item.id);
            this.$set(this.customerOrderSync, 'legal_entity', item);
        } else {
            delete this.customerOrderSync.legal_id;
        }
        this.setUnsavedDataState(true);
    }
}
