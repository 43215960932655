
import { Component, Prop, Vue } from 'vue-property-decorator';
import SvgOpenLink from'@/assets/icons/open-link.svg';
import SvgCopy from "@/assets/icons/copy.svg";
import { showNotification } from "@/utils";
import { OfferSingle } from '@/services/offers/OfferSingle';
import BaseCard from "@/components/base/BaseCard.vue";

interface IHeaderOffer {
    name: string;
    value: any;
    type: string;
}

@Component({
    components: {
        BaseCard,
        SvgOpenLink,
        SvgCopy,
    },
})
export default class GoalTracking extends Vue {
    @Prop({ required: true }) offer!: OfferSingle | null;
    @Prop({ required: true }) goal!: any;
    isEdit = false;
    documentation: string = "https://docs.google.com/document/d/1jle34Gsrcyc0qXUS4h2UwGytZlyLNAzEDrG8dDkBoz0/edit#heading=h.a2u3k23vb5wr";

    get getOptions(): IHeaderOffer[] {
        const options = [
            { name: this.goal?.protocol  == 'pixel' ? 'Код пикселя:' : 'Постбэк HasOffers: ', value: this.getPostbackLink, type: 'hasoffers' },
            { name: 'Постбэк Leadgid: ', value: this.offer?.status, type: 'leadgid' },
        ];
        return this.goal?.protocol  === 'pixel' ? options.slice(0,1) : options;
    }

    async copyUrl(): Promise<void> {
        try {
            //не через clipboard, потому что через него не сработало.
            //cannot read properties of undefined (reading 'writeText') в await navigator.clipboard.writeText(link);
            let link = '';
            if ((this.goal.protocol === 'pixel')) {
                if (this.goal.legacy_id === 0) {
                    link = `<!-- Offer Conversion: ${this.offer?.name} -->` + this.getPostbackLink + `<!-- // End Offer Conversion -->`;
                } else {
                    link = `<!-- Offer Goal Conversion: ${this.offer?.name} -->` + this.getPostbackLink + `<!-- // End Offer Conversion -->`;
                }
            } else {
                link = this.getPostbackLink;
            }
            const tempInput = document.createElement('input');
            tempInput.style.position = 'absolute';
            tempInput.style.left = '-9999px';
            tempInput.value = link;
            document.body.appendChild(tempInput);
            tempInput.select();
            document.execCommand('copy');
            document.body.removeChild(tempInput);
            showNotification('Ссылка скопирована');
        } catch (err) {
            console.error('Ошибка при копировании: ', err);
        }
    }

    get getPostbackLink(): string {
        if (this.goal.protocol === 'postback' && this.offer) {
            const baseUrl = "https://go.leadgid.com";
            let url = '';

            if (this.goal.legacy_id === 0) {
                // Если цель нулевая, используем ID оффера
                url = `${baseUrl}/aff_lsr?offer_id=${this.offer.legacy_id}&adv_sub=SUB_ID&transaction_id=TRANSACTION_ID`;
            } else {
                // Если цель не нулевая, используем ID цели
                url = `${baseUrl}/aff_goal?a=lsr&goal_id=${this.goal.legacy_id}&transaction_id=TRANSACTION_ID`;
            }

            return url;
        } else if (this.goal.protocol === 'pixel' && this.offer){
            let img = '';

            if (this.goal.legacy_id === 0) {
                // Если цель нулевая, используем ID оффера
                img = `<img src="https://${this.offer.tracking_domain?.domain}/aff_l?offer_id=${this.offer.legacy_id}&adv_sub=SUB_ID" width="1" height="1" />`;
            } else {
                // Если цель не нулевая, используем ID цели
                img = `<img src="https://${this.offer.tracking_domain?.domain}/aff_goal?a=l&goal_id=${this.goal.legacy_id}&adv_sub=SUB_ID" width="1" height="1" />`;
            }
            return img;
        } else {
            return "";
        }
    }
}
