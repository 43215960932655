export enum OFFER_PRODUCT_PARAMS {
    'МФО' = 1,
    'Дебетовые карты' = 2,
    'Кредитные карты'= 3,
    'Потребительские кредиты'= 4,
    'Вклады' = 5,
    'Ипотека' = 6,
    'РКО' = 8,
    'HR' = 11,
    'Кредиты под залог' = 14,
    'Автокредит' = 20,
}
