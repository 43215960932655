
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { OfferSingle } from "@/services/offers/OfferSingle";
import { IEditableOfferFields } from "@/api/types/offers";
import BaseSelect from "@/components/base/design/BaseSelect.vue";
import { namespace } from "vuex-class";
import { eventBus } from "@/eventbus";
import TooltipCountryList from "@/components/base/TooltipCountryList.vue";
import { ICountry } from "@/api/types/catalogue";
import BaseCard from "@/components/base/BaseCard.vue";
import QuestionMarkTooltip from "@/components_v3/base/QuestionMarkTooltip.vue";

const catalogue = namespace('catalogueModule');

@Component({
    components: {
        QuestionMarkTooltip,
        BaseCard,
        TooltipCountryList,
        BaseSelect,
    },
})
export default class OfferCardTargeting extends Vue {
    @Prop({ required: true }) offer!: OfferSingle;
    @PropSync('isLoading') isLoadingSync!: boolean;
    @catalogue.State('countries') countries!: ICountry[];

    isEdit: boolean = false;
    offerData: IEditableOfferFields = {} as IEditableOfferFields;
    itemsEncoreGeoTargeting: {name: number}[] = [ { name: 0 }, { name: 1 } ];

    geotargetingTooltip = `Подробно работа настройки “Enforce Geotargeting“ описана в <a style="color: white; text-decoration: underline" href="https://leadgid.atlassian.net/wiki/spaces/faq/pages/479821915" target="_blank">Confluence</a>.`;


    translateEnforceGeotargeting(item: number ): string {
        switch (item) {
        case 0 : return 'Выключено';
        case 1: return 'Включено';
        default: return '';
        }
    }

    editInfo(edit: boolean): void {
        if (edit) {
            this.setDefaults();
        } else {
            this.updateAllOfferData();
        }
        this.isEdit = edit;
    }

    cancelEdit(): void {
        this.isEdit = false;
    }

    setDefaults(): void {
        this.offerData.countries = [...this.offer.countries.map(i => i.code)];
        this.$set(this.offerData, 'enforce_geo_targeting', this.offer.enforce_geo_targeting ? 1 : 0);
    }

    async updateAllOfferData(): Promise<void> {
        this.isLoadingSync = true;
        const isOfferDataSaved = await this.offer.editOffer(this.offerData);
        if (isOfferDataSaved) {
            eventBus.$emit("update-offer");
        }
        this.isLoadingSync = false;
    }
}
