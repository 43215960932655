
import { Vue, Component, PropSync, Watch } from "vue-property-decorator";
import BaseCard from "../../../base/BaseCard.vue";
import { ICreatePayment, IEmployeeMethod, IMyLeadgidInvoice, IPaymentRequisite } from "../../../../api/types/payout";
import EditModeTextField from "../../../base/form/EditModeTextField.vue";
import paymentCurrencies from "@/mappings/paymentCurrencies";
import BaseAutocomplete from "@/components/base/design/BaseAutocomplete.vue";
import DateTimePickerNew from "@/components/base/pickers/DateTimePickerNew.vue";
import { showServerError } from "@/utils";
import { translatePaymentsRequisite } from "@/utils/translate";
import { getEmployeeMethods, getAffiliateInvoices } from "@/api/payout";
import { searchWebmasters } from "@/api/org-cabinets-backend";

@Component({
    components: { DateTimePickerNew, BaseAutocomplete, EditModeTextField, BaseCard },
})

export default class CreatePaymentsBody extends Vue {
    @PropSync('payment', { required: true }) paymentSync!: ICreatePayment;
    @PropSync('isRequisiteFully', { required: true }) isRequisiteFullySync!: boolean;


    translatePaymentsRequisite = translatePaymentsRequisite;
    searchWebmasters = searchWebmasters;
    currencies = paymentCurrencies;
    methods: IEmployeeMethod[] = [];
    unpaidInvoices: IMyLeadgidInvoice[] = [];
    unpaidInvoice = {} as any;
    isManagerFieldsRequired?: boolean = false;
    isContract: boolean = false;
    requisites?: string[] = [];
    requisitesForCreate: IPaymentRequisite = {
        partner_data: {},
        manager_data: {},
    };
    currentAffiliateId: number | null = null;
    paidSumValue: number | null = null;

    get getCurrencyItems(): any {
        return this.currencies.map(i => {
            return { itemText: i, itemValue: i };
        });
    }

    get getAvailablePaidSum(): string {
        return Object.keys(this.unpaidInvoice).length > 0 ?
            (this.unpaidInvoice.total_amount - this.unpaidInvoice.partials_amount)
                .toLocaleString('ru-RU', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + ' ' +
            this.unpaidInvoice.currency : '';
    }

    get isInvoice(): boolean {
        return this.paymentSync.payment_type === 'invoice_payment';
    }

    get disabledDates(): number[] {
        const days: number[] = [];
        for (let i = 1; i <= 31; i++) {
            if (i !== 1 && i !== 15) days.push(i);
        }
        return days;
    }

    async created(): Promise<void> {
        if (this.isInvoice) {
            await this.getUnpaidInvoice();
        }
        await this.getEmployeeMethods();
        this.currentAffiliateId = this.paymentSync.affiliate_id!;
    }

    async getEmployeeMethods(): Promise<void> {
        try {
            const { data } = await getEmployeeMethods({ is_active: 1 });
            const blockedMethods = ['custom_v1', 'transfer_v1', 'bank_card_europe_v1', 'yandex_v1', 'contract_eur_individual_v1', 'contract_eur_legal_v1', 'payoneer_v1', 'paypal_v1'];
            this.methods = data.filter((item) => !blockedMethods.includes(item.id));
            this.methods = this.methods.map(i => ({ ...i, itemText: i.name.ru }));
        } catch (err) {
            showServerError(err, 'Список способов выплат не загружен');
        }
    }

    async getUnpaidInvoice(): Promise<void> {
        try {
            const { data } = await getAffiliateInvoices({
                offset: 0, limit: 500,
                affiliate_id: [this.paymentSync.affiliate_id], paid_status: ['partial_paid', 'not_paid'],
            });
            this.unpaidInvoices = data.map(i => {
                return {
                    ...i,
                    itemText: `[${i.id}] - ${i.total_amount.toLocaleString('ru-RU', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    })} ${i.currency}`,
                };
            });
        } catch (err) {
            showServerError(err, 'Список неоплаченных счетов не загружен');
        }
    }

    setInvoice(value: any): void {
        this.unpaidInvoice = value;
        const sum = value.total_amount - value.partials_amount;
        this.$set(this.paymentSync, 'invoice_id', value.id);
        this.$set(this.paymentSync, 'available_sum', sum);
        this.$set(this.paymentSync, 'currency', value.currency);
    }

    input(prop: string, value: any): void {
        this.$set(this.paymentSync, prop, value);
    }

    changeMethod(methodId: any): void {
        this.$set(this.paymentSync, 'payment_method', methodId);
        this.clearRequisites();
        const method = this.methods.find(m => m.id === methodId);
        this.isContract = methodId === 'contract_v1';
        this.isManagerFieldsRequired = method?.manager_fields_required;
        if (methodId === 'Charity_v1') {
            this.isRequisiteFullySync = true;
            this.requisites = [];
        } else {
            this.isRequisiteFullySync = false;
            this.requisites = method?.manager_fields_required ? method?.manager_fields_keys : method?.partner_fields_keys;
        }
    }

    clearRequisites(): void {
        const defaultValue = {
            partner_data: {},
            manager_data: {},
        };
        this.paymentSync.requisite = this.requisitesForCreate = defaultValue;
    }

    getRequisiteName(item: string): any {
        return this.isManagerFieldsRequired
            ? this.paymentSync.requisite?.manager_data[item]
            : this.paymentSync.requisite?.partner_data[item];
    }

    addRequisite(item: string, value: any): void {
        const fieldsType = this.isManagerFieldsRequired ? 'manager_data' : 'partner_data';
        if (item === 'contract') {
            this.requisitesForCreate[fieldsType].legal_id = value.guid;
            this.requisitesForCreate[fieldsType].legal_name = value.name;
        } else {
            this.requisitesForCreate[fieldsType][item] = value;
        }
        this.$set(this.paymentSync, 'requisite', this.requisitesForCreate);
        this.isRequisiteFullySync = !!value;
    }

    onDayClick(): void {
        if (this.$refs.datepicker) {
            // @ts-ignore
            this.$refs.datepicker.submitDate();
        }
    }

    @Watch('paymentSync.affiliate_id')
    async changeNearestPayouts(): Promise<void> {
        if (this.paymentSync.affiliate_id !== this.currentAffiliateId) {
            await this.getUnpaidInvoice();
            this.currentAffiliateId = this.paymentSync.affiliate_id!;
        }
    }

    @Watch('isInvoice')
    async updateInvoices(): Promise<void> {
        await this.getUnpaidInvoice();
    }

    @Watch('paidSumValue')
    updatePaidSum(): void {
        this.input('paid_sum', this.paidSumValue);
    }

    hintRequisite(): string {
        switch (this.paymentSync.payment_method) {
        case 'yoomoney_v1':
            return '15-16 символов без пробелов.\n'
                    + 'Пример: 4400167891234567';
        case 'webmoney_usd_v1':
        case 'webmoney_v1':
            return '13 символов без пробелов.\n'
                    + 'Пример: Z123456789123';
        case 'qiwi_rub_v1':
        case 'qiwi_v1':
            return 'Номер телефона без “+”, только цифры.\n'
                    + 'Пример: 79501234567';
        case 'bank_card_ru_ua_v1':
            return '13-19 символов без пробелов.\n'
                    + 'Пример: 1234123412341234';
        default:
            return '';
        }
    }
}

